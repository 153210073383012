import Vue from 'vue';
import {
  GET_VOTING_POLLS,
  SET_VOTING_POLLS,
  SUBMIT_POLL_ANSWER,
  GET_POLL_ANSWERS,
  CHECK_HAVE_VOTED,
  DELETE_USER_VOTE,
} from './types.js';

export default {
  state: () => ({
    votingPolls: null,
  }),
  mutations: {
    [SET_VOTING_POLLS](state, value) {
      state.votingPolls = value;
    },
  },
  actions: {
    [GET_VOTING_POLLS]({ commit, dispatch }, { context }) {
      return Vue.axios.get(`${process.env.VUE_APP_FORD_CENTER_URL}/api/voting-polls`).then((data) => {
        let votingPolls = data && data.data;
        if (votingPolls) {
          votingPolls = votingPolls.map((votingPoll) => {
            const answers = votingPoll.answers
              ? JSON.parse(votingPoll.answers)
              : null;
            const actionFn = () => {
              context.currentPoll = {
                id: votingPoll.id,
                title: votingPoll.title,
                image: votingPoll.image,
                subtitle: votingPoll.question,
                answers: answers,
              };
              dispatch(CHECK_HAVE_VOTED, {
                pollId: context.currentPoll.id,
              }).then((haveVoted) => {
                if (haveVoted) {
                  dispatch(GET_POLL_ANSWERS, {
                    pollId: context.currentPoll.id,
                    pollAnswers: context.currentPoll.answers,
                  }).then((stats) => {
                    context.currentPoll.stats = stats;
                    context.isCurrentPollStatsVisible = true;
                    context.dialogOpen = 'polls-view';
                  });
                } else {
                  context.isCurrentPollStatsVisible = false;
                  context.currentPollAnswerHighlighted = null;
                  context.dialogOpen = 'polls-view';
                }
              });
            };
            return {
              id: votingPoll.id,
              title: votingPoll.title,
              image: votingPoll.image,
              subtitle: votingPoll.question,
              answers: answers,
              actionFn: actionFn,
            };
          });
          commit(SET_VOTING_POLLS, votingPolls);
          return votingPolls;
        } else {
          commit(SET_VOTING_POLLS, null);
          return null;
        }
      });
    },
    [SUBMIT_POLL_ANSWER]({ commit }, { userId, pollId, answers, sessionId }) {
      return Vue.axios
        .post(`/api/voting-polls/vote`, {
          userId,
          pollId,
          answers: JSON.stringify(answers),
          sessionId,
          pollLaunchTimestamp: 1,
        });
    },
    [GET_POLL_ANSWERS]({ rootState, commit }, { pollId, pollAnswers }) {
      return Vue.axios
        .get(`/api/voting-polls/vote?pollId=${pollId}`)
        .then((data) => {
          const votes = data.data;
          if (votes) {
            const totalVotes = votes.length;
            const haveAlreadyVoted = Boolean(
              votes.find((item) => item.userId === rootState.user.userData.id)
            );

            const answersAmounts = [];

            pollAnswers.forEach((answer) => {
              const amountValue = votes.filter((vote) => {
                try {
                  let currVoteAnswer = JSON.parse(vote.answers);
                  currVoteAnswer = currVoteAnswer[0];
                  return currVoteAnswer === answer;
                } catch (err) {
                  return console.error('Error getting the vote answers');
                }
              });
              var percentage =
                totalVotes === 0 ? 0 : (amountValue.length / totalVotes) * 100;
              percentage = Math.round(percentage * 100) / 100;
              answersAmounts.push({
                answerTitle: answer,
                votesAmount: amountValue.length,
                votesAmountPercent: percentage,
              });
            });
            return {
              answersAmounts,
              haveAlreadyVoted,
              totalVotes,
            };
          }
        });
    },
    [CHECK_HAVE_VOTED](data, { pollId }) {
      return Vue.axios
        .get(`/api/voting-polls/have-voted/${pollId}`)
        .then((data) => {
          const haveVoted = data.data && data.data.voted;
          return haveVoted;
        });
    },
    [DELETE_USER_VOTE](data, { pollId }) {
      return Vue.axios
        .get(`/api/voting-polls/vote/delete/${pollId}`)
        .then((data) => {
          if (data && data.data && data.data.response) {
            return true;
          } else {
            return false;
          }
        });
    },
  },
};
