import AFRAME from 'aframe';

const gltfLoader = new AFRAME.THREE.GLTFLoader();
let dracoLoader = null;
let LOADING_MODELS = {};
let MODELS = {};

const schema = {
  buffer: {default: true},
  part: {type: 'string'},
  src: {type: 'asset'},
  dracoDecoderPath: { type: 'string', default: '' }
};

const init = function() {
  this.loader = gltfLoader;
  const sceneDecoderPath = this.el.sceneEl.getAttribute('draco-decoder-path');
  const paramDecoderPath = this.data.dracoDecoderPath;
  const decoderPathToUse = sceneDecoderPath ? sceneDecoderPath : paramDecoderPath;
  if (decoderPathToUse && dracoLoader === null) {
    dracoLoader = new AFRAME.THREE.DRACOLoader();
    dracoLoader.setDecoderPath(decoderPathToUse);
    gltfLoader.setDRACOLoader(dracoLoader);
  }
};

const update = function() {
  const el = this.el;
  if (!this.data.part && this.data.src) { return; }
  this.getModel((modelPart) => {
    if (!modelPart) { return; }
    el.setObject3D('mesh', modelPart);
    el.emit('gltf-part-loaded', { mesh: modelPart }, false);
  });
};

/**
 * Fetch, cache, and select from GLTF.
 *
 * @returns {object} Selected subset of model.
 */
const getModel = function(cb) {
  const self = this;

  // Already parsed, grab it.
  if (MODELS[this.data.src]) {
    cb(this.selectFromModel(MODELS[this.data.src]));
    return;
  }

  // Currently loading, wait for it.
  if (LOADING_MODELS[this.data.src]) {
    return LOADING_MODELS[this.data.src].then(function (model) {
      cb(self.selectFromModel(model));
    });
  }

  // Not yet fetching, fetch it.
  LOADING_MODELS[this.data.src] = new Promise(function (resolve) {
    self.loader.load(self.data.src, function (gltfModel) {
      let model = gltfModel.scene || gltfModel.scenes[0];
      MODELS[self.data.src] = model;
      delete LOADING_MODELS[self.data.src];
      cb(self.selectFromModel(model));
      resolve(model);
    }, function () { }, console.error);
  });
};

/**
  * Search for the part name and look for a mesh.
  */
const selectFromModel = function(model) {
  let mesh;
  let part;

  part = model.getObjectByName(this.data.part);
  if (!part) {
    console.error('A-FRAME:gltf-part', this.data.part + ' not found in model.');
    return;
  }

  mesh = part.getObjectByProperty('type', 'Mesh').clone(true);

  if (this.data.buffer) {
    mesh.geometry = mesh.geometry.toNonIndexed();
    return mesh;
  }
  mesh.geometry = new AFRAME.THREE.Geometry().fromBufferGeometry(mesh.geometry);
  return mesh;
};

export default { schema, init, update, getModel, selectFromModel };
