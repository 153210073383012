import Vue from 'vue';
import {
  GET_BOOTH_MODELS,
  GET_BOOTH_MODELS_BY_ID,
  SET_BOOTH_MODELS,
  SET_BOOTH_MODELS_BY_ID,
} from './types.js';

export default {
  state: () => ({
    boothModels: [],
    boothModel: null
  }),
  mutations: {
    [SET_BOOTH_MODELS](state, value) {
      state.boothModels = value;
    },
    [SET_BOOTH_MODELS_BY_ID](state, value) {
      state.boothModel = value;
    },
  },
  actions: {
    [GET_BOOTH_MODELS]({commit}) {
      return Vue.axios.get(`/api/booth_models`).then((response) => {
        if (!response || !response.data) {
          commit(SET_BOOTH_MODELS, null);
          return null;
        } else {
          commit(SET_BOOTH_MODELS, response.data);
          return response.data;
        }
      });
    },

    [GET_BOOTH_MODELS_BY_ID]({commit}, id ) {
      return Vue.axios.get(`/api/booth_models/${id}`).then((response) => {
        if (!response || !response.data) {
          commit(SET_BOOTH_MODELS_BY_ID, null);
          return null;
        } else {
          commit(SET_BOOTH_MODELS_BY_ID, response.data);
          return response.data;
        }
      });
    },
  },
};
