import AFRAME from 'aframe';

const tick = function () {
  const tmpQ = new AFRAME.THREE.Quaternion();
  const tmpE = new AFRAME.THREE.Euler();

  if (!this.el.sceneEl.camera) { return; }
  const camera = this.el.sceneEl.camera.el;
  camera.object3D.getWorldQuaternion(tmpQ);
  tmpE.setFromQuaternion(tmpQ, 'YXZ');
  this.el.setAttribute('rotation', {x: 0, y: tmpE.y * 180 / Math.PI, z: 0 });
};

export default { tick };
