import Vue from 'vue';
import hark from 'hark';
import { TOGGLE_GAME_SETTING_IS_USER_SPEAKING } from '@/store/game-settings/types';

const store = (type) => Vue.prototype.$store[type];

let localAdapter = null;
let localClientId = null;
let speechEvents = null;

export const NETWORK_CONNECTION_STATUS = {
  DISCONNECTED: 0,
  CONNECTED: 1,
  CONNECTING: 2
};

const DEFAULT_AUDIO_MEDIA_TRACK_SETTINGS = {
  echoCancellation: true,
  noiseSuppression: true
};

const DEFAULT_VIDEO_MEDIA_TRACK_SETTINGS = true;

let activeVideoMediaTrackSettings = null;
let activeAudioMediaTrackSettings = null;
let activeScreenShareVideoTrack = null;

export const generateClientId = () => {
  let num = '';
  for (let i = 0; i < 16; i++) {
    num += Math.floor(Math.random() * 10).toString();
  }
  return num;
};

export const connectLocalMediaStream = async (audioTrackSettings, videoTrackSettings) => {
  try {
    let mediaStreamTrackSettings = {};
    if (audioTrackSettings) { mediaStreamTrackSettings['audio'] = audioTrackSettings; }
    if (videoTrackSettings && !activeScreenShareVideoTrack) { mediaStreamTrackSettings['video'] = videoTrackSettings; }
    const stream = await navigator.mediaDevices.getUserMedia(mediaStreamTrackSettings);
    if (activeScreenShareVideoTrack) { stream.addTrack(activeScreenShareVideoTrack); }
    await localAdapter.setLocalMediaStream(stream);
    return localAdapter.localMediaStream;
  } catch (e) {
    console.warn('networking::connectLocalMediaStream e', e);
    throw e;
  }
};

export const cleanupLocalMedia = () => {
  if (localAdapter && localAdapter.localMediaStream) {
    localAdapter.localMediaStream.getTracks().forEach(track => track.stop());
  }
  attachSpeechDetection(false);
  activeAudioMediaTrackSettings = null;
  activeVideoMediaTrackSettings = null;
  localAdapter = null;
  localClientId = null;
};

export const onAdapterReady = ({ detail: adapter }) => {
  localAdapter = adapter;
  localClientId = generateClientId();
  localAdapter.setClientId(localClientId);
  localAdapter.requestedOccupants = localAdapter.availableOccupants;
  // don't connect any media streams yet, everything should be inactive until the user decides otherwise
  // TODO: handle "play() failed because the user didn't interact with the document first"
  // TODO: disable mic/camera buttons while transitioning state
};

export const attachSpeechDetection = (shouldAttach) => {
  if (shouldAttach) {
    speechEvents = hark(localAdapter.localMediaStream, { threshold: -70 });
    speechEvents.on('speaking', onSpeechStart);
    speechEvents.on('stopped_speaking', onSpeechEnd);
  } else if (speechEvents !== null) {
    speechEvents.stop();
    speechEvents = null;
  }
};

export const onSpeechStart = () => { 
  store('commit')(TOGGLE_GAME_SETTING_IS_USER_SPEAKING, true);
};
export const onSpeechEnd = () => { 
  store('commit')(TOGGLE_GAME_SETTING_IS_USER_SPEAKING, false);
};

export const muteMicrophone = async (isMuted) => {
  if (isMuted) {
    localAdapter.enableMicrophone(false);
    const audioTracks = localAdapter.localMediaStream.getAudioTracks();
    audioTracks.forEach(t => { t.enabled = false; t.stop(); });
    activeAudioMediaTrackSettings = null;
  } else {
    await connectLocalMediaStream(DEFAULT_AUDIO_MEDIA_TRACK_SETTINGS, activeVideoMediaTrackSettings);
    activeAudioMediaTrackSettings = DEFAULT_AUDIO_MEDIA_TRACK_SETTINGS;
    localAdapter.enableMicrophone(true);
  }
  attachSpeechDetection(!isMuted);
};

export const muteVideo = async (isMuted) => {
  if (isMuted) {
    const videoTracks = localAdapter.localMediaStream.getVideoTracks();
    videoTracks.forEach(t => { t.enabled = false; t.stop(); });
    activeVideoMediaTrackSettings = null;
  } else {
    await connectLocalMediaStream(activeAudioMediaTrackSettings, DEFAULT_VIDEO_MEDIA_TRACK_SETTINGS);
    activeVideoMediaTrackSettings = DEFAULT_VIDEO_MEDIA_TRACK_SETTINGS;
    return localAdapter.localMediaStream;
  }
};

export const muteScreenShare = async (isMuted) => {
  if (isMuted) {
    const videoTracks = localAdapter.localMediaStream.getVideoTracks();
    videoTracks.forEach(t => { t.enabled = false; t.stop(); });
    activeScreenShareVideoTrack = null;
  } else {
    const mergedStream = new MediaStream();
    // if audio was active, fetch an audio track to add to this new stream
    if (activeAudioMediaTrackSettings !== null) {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: activeAudioMediaTrackSettings });
      const [audioTrack] = audioStream.getAudioTracks();
      mergedStream.addTrack(audioTrack);
    }
    // use getDisplayMedia to get the screenshare video track and add to this new stream
    const displayStream = await navigator.mediaDevices.getDisplayMedia();
    const [videoTrack] = displayStream.getVideoTracks();
    mergedStream.addTrack(videoTrack);
    mergedStream.getVideoTracks().forEach(t => t.onended = () => activeScreenShareVideoTrack = null);
    await localAdapter.setLocalMediaStream(mergedStream);
    activeScreenShareVideoTrack = videoTrack;
    return mergedStream;
  }
};

