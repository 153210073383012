export const GET_ALL_FLOORMAPS = 'GET_ALL_FLOORMAPS';
export const GET_FLOORMAP_BY_ID = 'GET_FLOORMAP_BY_ID';
export const GET_FLOORMAP_LAYOUTS = 'GET_FLOORMAP_LAYOUTS';
export const GET_FLOORMAP_LAYOUT_BY_ID = 'GET_FLOORMAP_LAYOUT_BY_ID';
export const CREATE_FLOORMAP_LAYOUT = 'CREATE_FLOORMAP_LAYOUT';
export const UPDATE_FLOORMAP_LAYOUT = 'UPDATE_FLOORMAP_LAYOUT';
export const DELETE_FLOORMAP_LAYOUT = 'DELETE_FLOORMAP_LAYOUT';
export const GET_FLOORMAP_LAYOUT_GRID_BY_ID = 'GET_FLOORMAP_LAYOUT_GRID_BY_ID';
export const GET_FLOORMAP_LAYOUT_GRID_CELL_BY_ID = 'GET_FLOORMAP_LAYOUT_GRID_CELL_BY_ID';
export const CREATE_FLOORMAP_LAYOUT_GRID_CELL = 'CREATE_FLOORMAP_LAYOUT_GRID_CELL';
export const UPDATE_FLOORMAP_LAYOUT_GRID_CELL_ROTATION = 'UPDATE_FLOORMAP_LAYOUT_GRID_CELL_ROTATION';
export const UPDATE_FLOORMAP_LAYOUT_GRID_CELL_BOOTH = 'UPDATE_FLOORMAP_LAYOUT_GRID_CELL_BOOTH';
export const DELETE_FLOORMAP_LAYOUT_GRID_CELL = 'DELETE_FLOORMAP_LAYOUT_GRID_CELL';
export const UPDATE_FLOORMAP_LAYOUT_GRID_CELL = 'UPDATE_FLOORMAP_LAYOUT_GRID_CELL';
export const SET_FLOORMAPS = 'SET_FLOORMAPS ';
export const SET_FLOORMAP_BY_ID = 'SET_FLOORMAP_BY_ID';
export const SET_FLOORMAP_LAYOUTS = 'SET_FLOORMAP_LAYOUTS ';
export const SET_FLOORMAP_LAYOUT_BY_ID = 'SET_FLOORMAP_LAYOUT_BY_ID';
export const GET_DYNAMIC_MESHES_OUTPUT = 'GET_DYNAMIC_MESHES_OUTPUT';