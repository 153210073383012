export default [
  {
    formFields: [
      {
        title: 'Show welcome message',
        type: 'checkbox',
        property: 'showWelcomeMessage',
        value: true,
      },
      {
        title: 'Show welcome message',
        type: 'radio',
        property: 'alwaysOn',
        value: 0,
        range: [
          { label: 'Show only on first login', value: 0 },
          { label: 'Show at every login and include a \'Do not show again\' option', value: 1 }
        ]
      },
      {
        title: 'Welcome message',
        type: 'html',
        property: 'welcomeMessage',
        value: '',
        counter: 255,
        rules: [(v) => !!v || 'Welcome message is required', (v) => v.length <= 255 || 'Welcome Message must be 255 characters or less'],
        required: true
      },
    ]
  },
];