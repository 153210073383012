import Vue from 'vue';
import {
  GET_ALL_FLOORMAPS,
  GET_FLOORMAP_BY_ID,
  GET_FLOORMAP_LAYOUTS,
  GET_FLOORMAP_LAYOUT_BY_ID,
  CREATE_FLOORMAP_LAYOUT,
  UPDATE_FLOORMAP_LAYOUT,
  DELETE_FLOORMAP_LAYOUT,
  CREATE_FLOORMAP_LAYOUT_GRID_CELL,
  SET_FLOORMAPS,
  SET_FLOORMAP_BY_ID,
  SET_FLOORMAP_LAYOUTS,
  SET_FLOORMAP_LAYOUT_BY_ID,
  GET_FLOORMAP_LAYOUT_GRID_CELL_BY_ID,
  DELETE_FLOORMAP_LAYOUT_GRID_CELL,
  UPDATE_FLOORMAP_LAYOUT_GRID_CELL_ROTATION,
  UPDATE_FLOORMAP_LAYOUT_GRID_CELL_BOOTH,
  UPDATE_FLOORMAP_LAYOUT_GRID_CELL
} from './types';

const formatFloormapItem = (item) => {
  const newItem = {
    ...item,
    size: `${item.width}x${item.height}`
  };
  return newItem;
};
const formatFloormapLayoutItem = (item) => {
  const newItem = {
    ...item,
    placeholderPathPoster: '/img/floor-config-placeholders/Smart_content_portrait.jpg',
    placeholderPathBanner: '/img/floor-config-placeholders/logo.png',
  };
  return newItem;
};

export default {
  state: () => ({
    floorMaps: [],
    floorMapLayouts: [],
    floormapLayoutCells: []
  }),
  mutations: {
    [SET_FLOORMAPS](state, value) {
      state.floorMaps = [...value].map(item => {return {
        ...item,
        name: item.name,
        width: item.width,
        height: item.width,
      };});
    },

    [SET_FLOORMAP_BY_ID](state, value) {
      state.floorMap = value;
    },

    [SET_FLOORMAP_LAYOUTS](state, value) {
      state.floorMaps = value;
    },

    [SET_FLOORMAP_LAYOUT_BY_ID](state, value) {
      state.floorMaps = value;
    }
  },
  actions: {
    [GET_ALL_FLOORMAPS]({commit}, includeMetadata = false) {
      return Vue.axios.get(`/api/floormap${includeMetadata ? '?include=metadata' : ''}`).then((data) => {
        if (data && data.data) {
          const formattedFloormaps = data.data.map( floormap => formatFloormapItem(floormap) );
          commit(SET_FLOORMAPS, formattedFloormaps);
          return formattedFloormaps;
        } else {
          commit(SET_FLOORMAPS, []);
          return [];
        }
      });
    },
    
    [GET_FLOORMAP_BY_ID]({commit}, { id, includeMetadata = false } ) {
      return Vue.axios.get(`/api/floormap/${id}${includeMetadata ? '?include=metadata' : ''}`).then((data) => {
        if (data && data.data) {
          commit(SET_FLOORMAP_BY_ID, formatFloormapItem(data.data));
          return formatFloormapItem(data.data);
        } else {
          commit(SET_FLOORMAP_BY_ID, null);
          return null;
        }
      });
    },

    [GET_FLOORMAP_LAYOUTS]({commit}, { eventSlug, includeMetadata } ) {
      return Vue.axios.get(`/api/${eventSlug}/floormap_layout${includeMetadata ? '?include=metadata' : ''}`).then((data) => {
        if (data && data.data) {
          const formattedData = data.data.map( floormap =>  formatFloormapLayoutItem(floormap));
          commit(SET_FLOORMAP_LAYOUTS, formattedData);
          return formattedData;
        } else {
          commit(SET_FLOORMAP_LAYOUTS, null);
          return null;
        }
      });
    },

    [GET_FLOORMAP_LAYOUT_BY_ID]({commit}, { eventSlug, id, includeMetadata = true } ) {
      return Vue.axios.get(`/api/${eventSlug}/floormap_layout/${id}${includeMetadata ? '?include=metadata' : ''}`).then((data) => {
        if (data && data.data) {
          const formattedData = formatFloormapLayoutItem(data.data);
          commit(SET_FLOORMAP_LAYOUT_BY_ID, formattedData);
          return formattedData;
        } else {
          commit(SET_FLOORMAP_LAYOUT_BY_ID, null);
          return null;
        }
      });
    },

    [CREATE_FLOORMAP_LAYOUT]({commit}, { eventSlug, floormapLayout } ) {
      return Vue.axios.post(`/api/${eventSlug}/floormap_layout`, floormapLayout).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },

    [UPDATE_FLOORMAP_LAYOUT]({commit}, { eventSlug, id, floormapLayoutUpdates } ) {
      return Vue.axios.put(`/api/${eventSlug}/floormap_layout/${id}`, floormapLayoutUpdates).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },

    [DELETE_FLOORMAP_LAYOUT]({commit}, { eventSlug, id } ) {
      return Vue.axios.delete(`/api/${eventSlug}/floormap_layout/${id}`).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },

    [GET_FLOORMAP_LAYOUT_GRID_CELL_BY_ID]({commit},{ eventSlug, cellId }) { 
      return Vue.axios.get(`/api/${eventSlug}/floormap_layout/${cellId}/grid`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },

    [CREATE_FLOORMAP_LAYOUT_GRID_CELL](_context, {eventSlug, locationLayoutId, floormapLayoutCells }) {
      return Vue.axios.post(`/api/${eventSlug}/floormap_layout/${locationLayoutId}/grid`, floormapLayoutCells).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },

    [UPDATE_FLOORMAP_LAYOUT_GRID_CELL_ROTATION](_context, { eventSlug, floormapLayoutCellId, rotation }) {
      return Vue.axios.put(`/api/${eventSlug}/floormap_layout/${floormapLayoutCellId}/rotation`, {rotation}).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },

    [UPDATE_FLOORMAP_LAYOUT_GRID_CELL_BOOTH]({ eventSlug, floormapLayoutCellId, cells }) {
      return Vue.axios.put(`/api/${eventSlug}/floormap_layout/${floormapLayoutCellId}/booth`, {boothId}).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    
    [UPDATE_FLOORMAP_LAYOUT_GRID_CELL]({commit}, { eventSlug, floormapLayoutCellId, cells }) {
      return Vue.axios.put(`/api/${eventSlug}/floormap_layout/${floormapLayoutCellId}/grid`, {cells}).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    
    [DELETE_FLOORMAP_LAYOUT_GRID_CELL](_context, { eventSlug, cellId }) {
      return Vue.axios.delete(`/api/${eventSlug}/floormap_layout/${cellId}/grid`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    }
  },
};
