import AFRAME from 'aframe';
import TransformControls from '../threejs/TransformControls';

const schema = {
  targetEl: { type: 'selector' },
  mode: { type: 'string', default: 'translate' },
  isSnapEnabled: { type: 'boolean', default: true },
  enabled: { type: 'boolean', default: true }
};

function setControlMode(controls, mode) {
  switch (mode) {
  case 'rotate':
    controls.setMode('rotate');
    controls.showY = true;
    controls.showX = false;
    controls.showZ = false;
    break;
  case 'translate':
  default:
    controls.setMode('translate');
    controls.showY = false;
    controls.showX = true;
    controls.showZ = true;
    break;
  }
}

function setControlSnap(controls, isSnapEnabled) {
  if (isSnapEnabled) {
    controls.setTranslationSnap(5);
    controls.setRotationSnap(AFRAME.THREE.MathUtils.degToRad(15));
    controls.setScaleSnap(0.25);
  } else {
    controls.setTranslationSnap(null);
    controls.setRotationSnap(null);
    controls.setScaleSnap(null);
  }
}

function setControlEnabled(controls, enabled) {
  controls.enabled = enabled;
  controls.setSize(enabled ? 1 : 0);
}

const init = function () {
  const targetEl = this.data.targetEl;
  if (!targetEl) { return console.warn('A-FRAME:sr-transform-controls', 'Failed to attach controls'); }

  targetEl.addEventListener('model-loaded', () => {
    this.controls = new TransformControls(this.el.sceneEl.camera, this.el.sceneEl.renderer.domElement);
    
    setControlEnabled(this.controls, this.data.enabled);
    setControlSnap(this.controls, this.data.isSnapEnabled);
    setControlMode(this.controls, this.data.mode);

    this.controls.addEventListener('change', (e) => { this.el.emit('transform-change', e); });
    this.controls.addEventListener('mouseDown', (e) => { this.el.emit('transform-started', e); });
    this.controls.addEventListener('mouseUp', (e) => { this.el.emit('transform-confirmed', e); });

    this.controls.attach(targetEl.object3D);
    this.el.setObject3D('mesh', this.controls);
  }, { once: true });
};

const update = function () {
  if (!this.controls) { return; }

  setControlEnabled(this.controls, this.data.enabled);
  setControlSnap(this.controls, this.data.isSnapEnabled);
  setControlMode(this.controls, this.data.mode);
};

export default { schema, init, update };