import Vue from 'vue';
import { getInternalAssetUrl } from '@/utils/files';
import { MixpanelCreateDataObject, MixpanelEvents } from '../../utils/mixpanel';
import { GET_VIDEOS, GET_VIDEOS_INTERNAL, SET_VIDEOS, SET_VIDEOS_INTERNAL, GET_VIDEO_INTERNAL, CREATE_VIDEO_INTERNAL, UPDATE_VIDEO_INTERNAL, DELETE_VIDEO_INTERNAL } from './types.js';
import { ADD_SWAGBAG_ITEM } from '../swagbag/types.js';

export default {
  state: () => ({
    videos: null,
    videosInternal: [],
  }),
  mutations: {
    [SET_VIDEOS](state, value) {
      state.videos = value;
    },
    [SET_VIDEOS_INTERNAL](state, value) {
      state.videosInternal = value;
    },
  },
  actions: {
    [GET_VIDEOS_INTERNAL]({ commit, dispatch }, { eventSlug }) {
      return Vue.axios
        .get(`/api/${eventSlug}/videos`).then((data) => { 
          let videos = data && data.data;
          commit(SET_VIDEOS_INTERNAL, videos);
          return videos;
        });
    },
    [GET_VIDEO_INTERNAL](_context, { eventSlug, id }) {
      return Vue.axios.get(`/api/${eventSlug}/videos/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      });
    },
    [CREATE_VIDEO_INTERNAL](_context, { eventSlug, data }) {
      return Vue.axios.post(`/api/${eventSlug}/videos`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      });
    },
    [UPDATE_VIDEO_INTERNAL](_context, { eventSlug, data, id }) {
      return Vue.axios.put(`/api/${eventSlug}/videos/${id}`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      });
    },
    [DELETE_VIDEO_INTERNAL](_context, { eventSlug, data, videoId }) {
      return Vue.axios.delete(`/api/${eventSlug}/videos/${videoId}`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      });
    },
    [GET_VIDEOS]({ commit, dispatch }, { context }) {
      return Vue.axios.get(`${process.env.VUE_APP_FORD_CENTER_URL}/api/videos`).then((data) => {
        let videos = data && data.data;
        if (videos) {
          videos = videos.map((video, index) => {
            const videoUrl = video.sharePointLink
              ? `${process.env.VUE_APP_FORD_CENTER_URL}/api/share-point/video/${video.id}`
              : video.url;
            const actionFn = () => {
              try {
                let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
                if (!MixpanelEvents.VIDEO_VIEWED || !mixPanelDataObject) { console.log('mixpanel event error'); }
                else {
                  mixPanelDataObject = {
                    ...mixPanelDataObject,
                    [MixpanelEvents.BOOTH_NAME]: context.booth.title,
                    [MixpanelEvents.FILE_URL]: videoUrl,
                    [MixpanelEvents.VIDEO_NAME]: video.name,
                  };
                  Vue.prototype._mixpanel.track(MixpanelEvents.VIDEO_VIEWED, mixPanelDataObject);
                }
              } catch (error) {
                console.log('mixpanel event err', error);
              }
              context.currentVideo = {
                title: video.name,
                subtitle: video.description,
                url: videoUrl,
                image: video.thumbnailPath,
              };
              context.dialogOpen = 'videos-video';
            };
  
            const swagObject = {
              ...video,
              url: videoUrl,
              image: video.thumbnailPath,
              userId : context.userData.id,
              fileCategory:  'Interactive Viveo',
              fileType : context.getFileType(videoUrl),
              boothName: context.booth.title,
              boothId: context.booth.id,
              fileTitle: video.name,
              path: video.url,
              id: `${ index + 1 }-${ context.booth.id }-${ context.getFileType(videoUrl) }`
            };
            return {
              id: video.id,
              title: video.name,
              subtitle: video.description,
              url: videoUrl,
              image: getInternalAssetUrl('/img/file-types/video.png'),
              actionFn: actionFn,
              items: [
                {
                  title: 'View',
                  actionFn: actionFn,
                },
                {
                  title: 'Add to swagbag',
                  actionFn: () => {
                    if (context.templateContent) {
                      const templateContent =  context.templateContent.find((item) => item.id === video.id);
                      if (templateContent) {
                        swagObject.fileType = templateContent.contentType;
                        dispatch(ADD_SWAGBAG_ITEM, { data: swagObject, eventSlug: context.selectedEvent.slug});
                        Vue.axios.post(`/api/${context.selectedEvent.slug}/trackable_events_log`, { type: 'COLLECT_SWAG', entityIds: [context.booth.id] });
                        context.$root.Toast.show({ text: `Swag added to your bag`, timeout: 5000 });
                        try {
                          let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
                          if (!MixpanelEvents.ADDED_TO_SWAGBAG || !mixPanelDataObject) { console.log('mixpanel event error'); }
                          else {
                            mixPanelDataObject = {
                              ...mixPanelDataObject,
                              [MixpanelEvents.BOOTH_NAME]: context.booth.title,
                              [MixpanelEvents.FILE_URL]: videoUrl,
                              [MixpanelEvents.VIDEO_NAME]: video.name,
                            };
                            Vue.prototype._mixpanel.track(MixpanelEvents.ADDED_TO_SWAGBAG, mixPanelDataObject);
                          }
                        } catch (error) {
                          console.log('mixpanel event err', error);
                        }
                      } else {
                        context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
                        console.log('fail adding to swagbag', swagObject);
                      }
                    }        
                  }
                },
                {
                  title: 'Download',
                  actionFn: () => {
                    try {
                      let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
                      if (!MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH || !mixPanelDataObject) { console.log('mixpanel event error'); }
                      else {
                        mixPanelDataObject = {
                          ...mixPanelDataObject,
                          [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
                          [MixpanelEvents.FILE_URL]: videoUrl,
                          [MixpanelEvents.VIDEO_NAME]: video.name,
                        };
                        Vue.prototype._mixpanel.track(MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH, mixPanelDataObject);
                      }
                    } catch (error) {
                      console.log('mixpanel event err', error);
                    }
                    window.open(videoUrl);
                  }
                },
              ]
            };
          });
          commit(SET_VIDEOS, videos);
          return videos;
        } else {
          commit(SET_VIDEOS, null);
          return null;
        }
      });
    },
  },
};