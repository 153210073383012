import AFRAME from 'aframe';

const schema = {
  src: { type: 'string', default: '' },
  id: { type: 'string', default: '' },
};

const init = function () {
  const data = this.data;
  if (!data.src || data.src.length === 0) { return; }
  this.el.addEventListener('gltf-part-loaded', (e) => {
    if (!e.detail || !e.detail.mesh) { return console.warn('A-FRAME:video-texture-mesh', 'Failed to load mesh'); }
    const mesh = e.detail.mesh;
    applyVideoTextureToMesh(data.src, mesh, this.data.id, (e) => {
      if (!e) { this.el.emit('video-texture-loaded', { src: data.src }); }
    });
  }, { once: true });
};

const update = function (oldData) {
 
  if (!oldData || Object.keys(oldData).length === 0) { return; }
  if (!this.data.src || this.data.src.length === 0) { return; }
  if (!this.el.object3D.children || this.el.object3D.children.length === 0) { return; }

  const mesh = this.el.object3D.children[0];
  applyVideoTextureToMesh(this.data.src, mesh, this.data.id);
};

const applyVideoTextureToMesh = function (src, mesh, id) {
  var video = document.createElement('video');
  video.src = src;
  video.id = id;
  video.crossOrigin = 'anonymous';
  video.muted = true;
  video.autoplay = true;
  const textureVid = new AFRAME.THREE.VideoTexture( video );
  textureVid.flipY = false;
  const material = new AFRAME.THREE.MeshBasicMaterial({
    map: textureVid
  });
  mesh.material = material;
};

export default { schema, init, update };
