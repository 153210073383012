export const GET_ALL_CALENDAR_EVENTS = 'GET_ALL_CALENDAR_EVENTS';
export const GET_UPCOMING_CALENDAR_EVENTS = 'GET_UPCOMING_CALENDAR_EVENTS';
export const GET_CALENDER_EVENT = 'GET_CALENDAR_EVENT';
export const CREATE_CALENDAR_EVENT = 'CREATE_CALENDAR_EVENT';
export const UPDATE_CALENDAR_EVENT = 'UPDATE_CALENDAR_EVENT';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const DELETE_CALENDAR_EVENT = 'DELETE_CALENDAR_EVENT';
export const SET_SPEAKERS= 'SET_SPEAKERS';
export const GET_SPEAKERS= 'GET_SPEAKERS';
export const ASSIGN_SPEAKER= 'ASSIGN_SPEAKER';
export const UNASSIGN_SPEAKER= 'UNASSIGN_SPEAKER';
export const GET_FILTERED_ELEMENT = 'GET_FILTERED_ELEMENT';
export const SET_FILTERED_ELEMENT = 'SET_FILTERED_ELEMENT';
