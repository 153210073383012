import Vue from 'vue';
import { getInternalAssetUrl } from '@/utils/files';
import { MixpanelEvents, MixpanelCreateDataObject } from '../../utils/mixpanel';
import { GET_PDFS, SET_PDFS } from './types.js';
import { ADD_SWAGBAG_ITEM } from '../swagbag/types.js';

export default {
  state: () => ({
    pdfs: null,
  }),
  mutations: {
    [SET_PDFS](state, value) {
      state.pdfs = value;
    },
  },
  actions: {
    [GET_PDFS]({ commit, dispatch }, { context }) {
      return Vue.axios.get(`${process.env.VUE_APP_FORD_CENTER_URL}/api/pdf`).then((data) => {
        let pdfs = data && data.data;
        if (pdfs) {
          pdfs = pdfs.map((pdf, index) => {
            const pdfUrl = pdf.sharePointLink
              ? `${process.env.VUE_APP_FORD_CENTER_URL}/api/share-point/pdf/${pdf.id}`
              : pdf.url;
            const actionFn = () => {
              context.currentPdf = {
                title: pdf.title,
                url: pdfUrl,
                image: pdf.thumbnailPath,
              };
              context.dialogOpen = 'takeaways-view';
            };
            const swagObject = {
              ...pdf,
              url: pdfUrl,
              image: pdf.thumbnailPath,
              userId : context.userData.id,
              fileCategory:  'Interactive Viveo',
              fileType : context.getFileType(pdfUrl),
              boothName: context.booth.title,
              boothId: context.booth.id,
              fileTitle: pdf.title,
              path: pdf.url,
              id: `${ index + 1 }-${ context.booth.id }-${ context.getFileType(pdfUrl) }`
            };
            return {
              id: pdf.id,
              title: pdf.title,
              url: pdfUrl,
              image: getInternalAssetUrl('/img/file-types/pdf.png'),
              actionFn: actionFn,
              items: [
                {
                  title: 'View',
                  actionFn: actionFn,
                },
                {
                  title: 'Add to swagbag',
                  actionFn: () => {
                    if (context.templateContent) {
                      const templateContent =  context.templateContent.find((item) => item.id === pdf.id);
                      if (templateContent) {
                        swagObject.fileType = templateContent.contentType;
                        dispatch(ADD_SWAGBAG_ITEM, { data: swagObject, eventSlug: context.selectedEvent.slug});
                        Vue.axios.post(`/api/${context.selectedEvent.slug}/trackable_events_log`, { type: 'COLLECT_SWAG', entityIds: [context.boothId] });
                        context.$root.Toast.show({ text: `Swag added to your bag`, timeout: 5000 });
                        try {
                          let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
                          if (!MixpanelEvents.ADDED_TO_SWAGBAG || !mixPanelDataObject) { console.log('mixpanel event error'); }
                          else {
                            mixPanelDataObject = {
                              ...mixPanelDataObject,
                              [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
                              [MixpanelEvents.FILE_URL]: pdfUrl,
                              [MixpanelEvents.FILE_NAME]: pdf.title,
                            };
                            Vue.prototype._mixpanel.track(MixpanelEvents.ADDED_TO_SWAGBAG, mixPanelDataObject);
                          }
                        } catch (error) {
                          console.log('mixpanel event err', error);
                        }
                      } else {
                        context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
                        console.log('fail adding to swagbag', swagObject);
                      }
                    }            
                  },
                },
                {
                  title: 'Download',
                  actionFn: () => {
                    try {
                      let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
                      if (!MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH || !mixPanelDataObject) { console.log('mixpanel event error'); }
                      else {
                        mixPanelDataObject = {
                          ...mixPanelDataObject,
                          [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
                          [MixpanelEvents.FILE_URL]: pdfUrl,
                          [MixpanelEvents.FILE_NAME]: pdf.title,
                        };
                        Vue.prototype._mixpanel.track(MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH, mixPanelDataObject);
                      }
                    } catch (error) {
                      console.log('mixpanel event err', error);
                    }
                    window.open(pdfUrl);
                  },
                },
              ],
            };
          });
          commit(SET_PDFS, pdfs);
          return pdfs;
        } else {
          commit(SET_PDFS, null);
          return null;
        }
      });
    },
  },
};
