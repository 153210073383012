

const schema =  {
  radius: { type: 'number', default: 1 }
};

const init = function() {
  const el = this.el;
  const center = el.getAttribute('position');

  const angleRad = getRandomAngleInRadians();
  const circlePoint = randomPointOnCircle(this.data.radius, angleRad);
  const worldPoint = { x: circlePoint.x + center.x, y: center.y, z: circlePoint.y + center.z };
  el.setAttribute('position', worldPoint);

  const angleDeg = angleRad * 180 / Math.PI;
  const angleToCenter = -1 * angleDeg + 90;
  const rotationStr = '0 ' + angleToCenter + ' 0';
  el.setAttribute('rotation', rotationStr);
};

const randomPointOnCircle = function (radius, angleRad) {
  const x = Math.cos(angleRad) * radius;
  const y = Math.sin(angleRad) * radius;
  return { x, y };
};

const getRandomAngleInRadians = function() {
  return Math.random() * Math.PI * 2;
};

export default { schema, init };
