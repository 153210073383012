
export default [
  {
    formFields: [
      {
        title: 'Thumbnail Image',
        type: 'image',
        property: 'thumbnailFileId',
        value: null,
        caption: '.png or.jpg, 500px X 200px',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Thumbnail Image',
            aspectRatio: 1/1,
          }
        }
      },
      {
        title: 'Video name',
        type: 'text',
        property: 'title',
        value: '',
        counter: 150,
        rules: [(v) => !!v || 'PDF name is required', (v) => v.length <= 150 || 'PDF name must be 150 characters or less'],
        required: true
      },
      {
        title: 'Description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      },
      {
        title: 'Video file',
        type: 'file',
        property: 'fileId',
        value: null,
        options: {
          acceptedMimes: ['video/mp4', 'video/x-matroska', 'video/webm']
        },
        rules: [
          (v) => !!v || 'File is required',
          (value) => {
            if (!value) { return true; }
            if (value.type && ['video/mp4', 'video/x-matroska', 'video/webm'].indexOf(value.type) === -1) {
              return 'This file type is not valid';
            }
            return true;
          }
        ],
      }
    ]
  }
];
