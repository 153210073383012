export default [
  {
    formFields: [
      {
        title: 'Name',
        type: 'text',
        property: 'name',
        value: '',
        counter: 50,
        rules: [(v) => !!v || 'Lobby name is required', (v) => v.length <= 50 || 'Max 50 characters'],
        required: true,
        autofocus: false
      },
      {
        title: 'Short description',
        type: 'textarea',
        property: 'description',
        noResize: true,
        rows: 3,
        required: false,
        value: '',
        counter: 120,
        rules: [(v) => !!v && v.length <= 120 || 'Max 120 characters']
      },
      {
        title: 'Theme',
        type: 'select',
        property: 'variant',
        items: [],
        value: [],
        rules: []
      },
    ]
  }
];
