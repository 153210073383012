export const schema = {
  src: { type: 'string' }
};

export const init = function () {
  if (!this.data.src) { return; }
  const modelEl = this.el.querySelector('.player-model');
  if (modelEl) { modelEl.setAttribute('gltf-model', this.data.src); }
};

export default { schema, init };