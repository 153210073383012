import achievements from './achievements';
import branding from './branding';
import calendarEvents from './calendar-events';
import gameSettings from './game-settings';
import trackableEvents from './trackable_events';
import user from './user';
import videos from './videos';
import pdfs from './pdfs';
import votingPolls from './voting-polls';
import networking from './networking';
import pages from './pages';
import people from './people';
import sessions from './sessions';
import sceneManager from './scene-manager';
import smartContent from './smart-content';
import templates from './templates';
import swagbag from './swagbag';
// import socket from './socket';
import nav from './navigation';
import notifications from './notifications';
import events from './events';
import files from './files';
import booths from './booths';
import floormaps from './floormaps';
import resources from './resources';
import welcomeMessage from './welcome-message';
import boothModels from './booth-models';
import locations from './locations';
import loading from './loading';

export default {
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    achievements,
    branding,
    calendarEvents,
    gameSettings,
    trackableEvents,
    user,
    videos,
    pdfs,
    votingPolls,
    networking,
    pages,
    people,
    sessions,
    sceneManager,
    smartContent,
    templates,
    swagbag,
    // socket,
    nav,
    notifications,
    events,
    files,
    booths,
    floormaps,
    resources,
    welcomeMessage,
    boothModels,
    locations,
    loading
  },
};
