const init = function () {
  const interactableEls = this.el.getElementsByClassName('raycastable');
  interactableEls.forEach((interactableEl) => {
    const existingMesh = interactableEl.getObject3D('mesh');
    if (!existingMesh) {
      interactableEl.addEventListener('gltf-part-loaded', (e) => {
        if (!e.detail || !e.detail.mesh) { return console.warn('A-FRAME:texture-mesh', 'Failed to load mesh'); }
        this.setMeshOutline(e.detail.mesh, true);
      }, { once: true });
    } else {
      this.setMeshOutline(existingMesh, true);
    }
  });
};

const remove = function () {
  if (!window.outlinePass) { return; }
  const interactableEls = this.el.getElementsByClassName('raycastable');
  interactableEls.forEach((interactableEl) => {
    const existingMesh = interactableEl.getObject3D('mesh');
    if (existingMesh) { this.setMeshOutline(existingMesh, false); }
  });
};

const setMeshOutline = function (mesh, isOutlined) {
  if (!window.outlinePass) { return; }
  const existingOutlinedMeshIndex = window.outlinePass.selectedObjects.findIndex(m => m.id === mesh.id);
  if (isOutlined && existingOutlinedMeshIndex === -1) { window.outlinePass.selectedObjects.push(mesh); }
  else if (!isOutlined && existingOutlinedMeshIndex > -1) { window.outlinePass.selectedObjects.splice(existingOutlinedMeshIndex, 1); }
};

export default { init, remove, setMeshOutline };
