import Vue from 'vue';
import { GET_EVENT_BRANDING, GET_SITE_BRANDING, UPDATE_SITE_BRANDING, SET_EVENT_BRANDING, SET_SITE_BRANDING, UPDATE_EVENT_BRANDING } from './types';
import merge from 'deepmerge';

let defaultEventBranding = {
  navbar: {
    logoImageFileId: '',
    color: '#000000'
  },
  landing: {
    bannerImageFileId: ''
  },
  home: {
    bannerContent: `
    <div class="white--text text-h6">Title</div>
    <div class="white--text text-subtitle-1 mb-4">November 9th and 10th - 2021</div>
    <div class="white--text text-body-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    `,
    bannerImageFileId: '',
    tiles: {
      auditorium: {
        imageFileId: '',
        title: 'Sessions',
        subtitle: 'View the schedule and attend live sessions'
      },
      conferenceFloor: {
        imageFileId: '',
        title: 'Exhibits',
        subtitle: 'View content, speak with exhibit teams and collect swag'
      },
      resources: {
        imageFileId: '',
        title: 'Resources',
        subtitle: ''
      },
      swagbag: {
        imageFileId: '',
        title: 'Swag bag',
        subtitle: 'Check out what you have picked up along the way'
      },
      leaderboard: {
        imageFileId: '',
        title: 'Leaderboard',
        subtitle: 'See which attendees are high achievers'
      },
      gettingStarted: {
        imageFileId: '',
        title: 'Getting Started',
        subtitle: 'Track your achievements and get on the leaderboard'
      },
      myProgress: {
        imageFileId: '',
        title: 'My Progress',
        subtitle: 'Track your achievements and get on the leaderboard'
      }
    }
  }
};

const defaultSiteBranding = {
  // Used in login page, choose event page
  appName: '',
  // Used in document title
  tabTitle: 'Davra SR',
  // Used in choose event, site admin navbars
  navbarColor: '#000000',
  // Used in choose event, site admin navbar
  logoImageLightFileId: '',
  // Used in choose event header
  logoImageDarkFileId: '',
  // Used in login page
  logoThumbImageFileId: '',
  faviconImageFileId: '',
  loginBackgroundImageFileId: ''
};

export default {
  state: {
    eventBranding: defaultEventBranding,
    siteBranding: defaultSiteBranding
  },
  mutations: {
    // TODO: need to store the raw branding from DB, so that updating the branding only updates the raw value instead of inserting the merged value
    [SET_SITE_BRANDING](state, value) {
      state.siteBranding = value;
    },
    [SET_EVENT_BRANDING](state, value) {
      state.eventBranding = value;
    }
  },
  actions: {
    [GET_SITE_BRANDING]({ commit }) {
      return Vue.axios.get(`/api/branding`).then((response) => {
        if (!response || !response.data) {
          commit(SET_SITE_BRANDING, defaultSiteBranding);
          return [];
        } else {
          const mergedBranding = merge(defaultSiteBranding, response.data);
          commit(SET_SITE_BRANDING, mergedBranding);
          return mergedBranding;
        }
      }).catch((_err) => {
        commit(SET_SITE_BRANDING, defaultSiteBranding);
        return [];
      });
    },
    [UPDATE_SITE_BRANDING]({ commit }, { siteBranding }) {
      return Vue.axios.put(`/api/branding`, siteBranding).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          commit(SET_SITE_BRANDING, response.data);
          return response.data;
        }
      }).catch((_err) => {
        return [];
      });
    },
    [GET_EVENT_BRANDING]({ commit }, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/branding`).then((response) => {
        if (!response || !response.data) {
          commit(SET_EVENT_BRANDING, defaultEventBranding);
          return [];
        } else {
          const mergedBranding = merge(defaultEventBranding, response.data);
          commit(SET_EVENT_BRANDING, mergedBranding);
          return mergedBranding;
        }
      }).catch((_err) => {
        commit(SET_EVENT_BRANDING, defaultEventBranding);
        return [];
      });
    },
    [UPDATE_EVENT_BRANDING]({ commit, state }, { eventSlug, eventBranding }) {
      const mergedBranding = merge(state.eventBranding, eventBranding);
      return Vue.axios.put(`/api/${eventSlug}/branding`, mergedBranding).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          commit(SET_EVENT_BRANDING, response.data);
          return response.data;
        }
      }).catch((_err) => {
        return [];
      });
    },
  },
};
