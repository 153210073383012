import AFRAME from 'aframe';

(function () {

  class Pass {

    constructor() {

      // if set to true, the pass is processed by the composer
      this.enabled = true; // if set to true, the pass indicates to swap read and write buffer after rendering

      this.needsSwap = true; // if set to true, the pass clears its buffer before rendering

      this.clear = false; // if set to true, the result of the pass is rendered to screen. This is set automatically by EffectComposer.

      this.renderToScreen = false;

    }

    setSize() { }

    render() {

      console.error('AFRAME.THREE.Pass: .render() must be implemented in derived pass.');

    }

  } // Helper for passes that need to fill the viewport with a single quad.


  const _camera = new AFRAME.THREE.OrthographicCamera(- 1, 1, 1, - 1, 0, 1); // https://github.com/mrdoob/three.js/pull/21358


  const _geometry = new AFRAME.THREE.BufferGeometry();

  _geometry.setAttribute('position', new AFRAME.THREE.Float32BufferAttribute([- 1, 3, 0, - 1, - 1, 0, 3, - 1, 0], 3));

  _geometry.setAttribute('uv', new AFRAME.THREE.Float32BufferAttribute([0, 2, 0, 0, 2, 0], 2));

  class FullScreenQuad {

    constructor(material) {

      this._mesh = new AFRAME.THREE.Mesh(_geometry, material);

    }

    dispose() {

      this._mesh.geometry.dispose();

    }

    render(renderer) {

      renderer.render(this._mesh, _camera);

    }

    get material() {

      return this._mesh.material;

    }

    set material(value) {

      this._mesh.material = value;

    }

  }

  AFRAME.THREE.FullScreenQuad = FullScreenQuad;
  AFRAME.THREE.Pass = Pass;

})();