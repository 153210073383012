import { isURL } from 'validator';

export default [
  {
    formFields: [
      {
        title: 'Resource Name',
        type: 'text',
        property: 'name',
        value: '',
        rules: [(v) => !!v || 'Enter a resource name'],
        required: true
      },
      {
        title: 'Resource Description',
        type: 'textarea',
        property: 'description',
        counter: 200,
        rules: [(v) => !!v || 'Please enter some description', (v) => v.length <= 200 || 'Max 200 characters'],
        value: '',
        required: true
      },
      {
        title: 'Link',
        type: 'text',
        property: 'destinationUrl',
        value: '',
        rules: [(v) => v.length === 0 || isURL(v) || 'Please enter a valid url' ],
        required: false,
      },
    ]
  },
];