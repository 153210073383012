<template>
  <div 
    class="text-center"
  >
    <v-snackbar
      v-model="showSnackbar"
      :timeout="timeout"
      top
    >
      {{ text }}
      <template #action="{ attrs }">
        <v-btn
          :color="btnTextColour"
          text
          v-bind="attrs"
          @click="closeSnackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      showSnackbar: false,
      text: '',
      color: 'success',
      btnTextColour: 'success',
      timeout: 3000,
    };
  },
  methods:{
    show(data) {
      this.showSnackbar = true;
      this.text = data.text || 'missing "message".';
      this.color = data.color || 'success';
      this.timeout = data.timeout || 3000;
      this.btnTextColour = data.btnTextColour || 'success';
    },
    closeSnackbar() {
      this.showSnackbar = false;
    },
  }
};
</script>