import { GET_ALL_RESOURCES, GET_RESOURCE_BY_ID, SET_RESOURCES, SET_RESOURCE_BY_ID, UPDATE_RESOURCE, CREATE_RESOURCE, DELETE_RESOURCE } from './types.js';
import Vue from 'vue';

export default {
  state: {
    resources: [],
  },
  mutations: {
    [SET_RESOURCES](state, value) {
      state.resources = value;
    },
    [SET_RESOURCE_BY_ID](state, value) {
      state.resource = value;
    }
  },
  actions: {
    [GET_ALL_RESOURCES]({ commit }, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/resources`).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        commit(SET_RESOURCES, response.data);
        return response.data;
      }); 
    },
    [UPDATE_RESOURCE]({ commit }, { eventSlug, id, data }) {
      return Vue.axios.put(`/api/${eventSlug}/resources/${id}`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        commit(SET_RESOURCE_BY_ID, response.data);
        return response.data;
      }); 
    },
    [CREATE_RESOURCE]({ commit }, { eventSlug, data }) {
      return Vue.axios.post(`/api/${eventSlug}/resources`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      }); 
    },
    [GET_RESOURCE_BY_ID]({ commit }, { eventSlug, id }) {
      return Vue.axios.get(`/api/${eventSlug}/resources/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        commit(SET_RESOURCES, response.data);
        return response.data;
      }); 
    },
    [DELETE_RESOURCE]({ commit }, { eventSlug, id }) {
      return Vue.axios.delete(`/api/${eventSlug}/resources/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      }); 
    },
  },
};
