export const GET_LOCATION_TYPE_DATA = 'GET_LOCATION_TYPE_DATA';
export const GET_LOCATION_MESH_BY_KIND = 'GET_LOCATION_MESH_BY_KIND';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATION_TYPES = 'GET_LOCATION_TYPES';
export const GET_LOCATION_BY_ID = 'GET_LOCATION_BY_ID';
export const SET_LOCATION_TYPES = 'SET_LOCATION_TYPES';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const GET_LOCATION_LAYOUTS = 'GET_LOCATION_LAYOUTS';
export const GET_DEFAULT_LOCATION_LAYOUT = 'GET_DEFAULT_LOCATION_LAYOUT';
export const SET_LOCATION_LAYOUTS = 'SET_LOCATION_LAYOUTS';
export const CREATE_LOCATION_LAYOUT = 'CREATE_LOCATION_LAYOUT';
export const CREATE_LOCATION_LAYOUT_FROM_LOCATION_ID = 'CREATE_LOCATION_LAYOUT_FROM_LOCATION_ID';
export const GET_LOCATION_LAYOUT_BY_ID = 'GET_LOCATION_LAYOUT_BY_ID';
export const SET_LOCATION_LAYOUT = 'SET_LOCATION_LAYOUT';
export const UPDATE_LOCATION_LAYOUT = 'UPDATE_LOCATION_LAYOUT';
export const DELETE_LOCATION_LAYOUT = 'DELETE_LOCATION_LAYOUT';