import AFRAME from 'aframe';
import { getUploadcareStaticAssetUrl } from '@/utils/files';
import { attachSmartContentProximityEvents, drawSmartContent, getContentPlaneDimensionsFromFixedMesh } from '@/utils/3d';

const schema = {
  smartContent: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  meta: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  target: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    },
    default: false
  }
};

const init = async function () {
  if (!this.data.target) {
    // TODO: without target + with coords, place into scene at specified position/rotation
    // TODO: without target + without coords, place into scene at 0 0 0 and apply auto-scaling
    return;
  }

  // get the video + its resolution
  const fixedMesh = this.data.target;
  const assetId = `smart-content-video-${this.data.smartContent.content.file_id}`;
  const videoFileSrc = getUploadcareStaticAssetUrl(this.data.smartContent.content.file_id);
  const videoDimensions = await getVideoDimensions(videoFileSrc);

  // create a bounding box from the fixed mesh, used to determine its dimensions + world position
  const meshBoundingBox = new AFRAME.THREE.Box3().setFromObject(fixedMesh);
  const smartContentDimensions = getContentPlaneDimensionsFromFixedMesh(meshBoundingBox, videoDimensions);

  // get the center point of the fixed mesh, so that the plane can be repositioned to it
  const meshCenter = new AFRAME.THREE.Vector3();
  meshBoundingBox.getCenter(meshCenter);

  // build the smart content panels and remove the original mesh
  const scContainerEl = drawSmartContent(meshCenter, this.data.meta, this.data.smartContent, smartContentDimensions, { id: assetId });
  fixedMesh.removeFromParent();
  
  this.el.append(scContainerEl);
  this.smartContentPlaneEl = scContainerEl;

  // attach any proximity events once the element has been added to the DOM
  attachSmartContentProximityEvents(scContainerEl);
};

const remove = function () {
  if (this.smartContentPlaneEl) { this.smartContentPlaneEl.remove(); }
};

function getVideoDimensions(videoSrc) {
  return new Promise((resolve) => {
    const videoEl = document.createElement('video');
    videoEl.addEventListener('loadedmetadata', function () {
      const height = this.videoHeight;
      const width = this.videoWidth;
      videoEl.remove();
      return resolve({ height, width });
    }, false);
    videoEl.src = videoSrc;
  });
}

export default { schema, init, remove };