// import AFRAME from 'aframe';
// import { convertPdfToPdfjsDoc, getPageOfPdfjsDoc, createCanvasContext } from '@/utils/pdf.js';

const schema = {
  pdfPath: { type: 'string', default: '' },
  pageNum: { type: 'number', default: 1 },
  numPages: { type: 'number', default: 1 }
};

const init = function () {
  const data = this.data;
  if (!data.pdfPath || data.pdfPath.length === 0) { return; }
  this.el.addEventListener('gltf-part-loaded', (e) => {
    if (!e.detail || !e.detail.mesh) { return console.warn('A-FRAME:canvas-texture-mesh', 'Failed to load mesh'); }
    // const mesh = e.detail.mesh;
    // applyCanvasTextureToMesh(mesh, this.data.pdfPath, this.data.pageNum,(numPages) => {
    //   data.numPages = numPages;
    //   this.el.emit('canvas-texture-loaded', { numPages });
    // });
  }, { once: true });
};

const update = function (oldData) {

  if (!oldData || Object.keys(oldData).length === 0) { return; }
  if (!this.data.pdfPath || this.data.pdfPath.length === 0) { return; }
  if (!this.el.object3D.children || this.el.object3D.children.length === 0) { return; }

  // const mesh = this.el.object3D.children[0];
  // applyCanvasTextureToMesh(mesh, this.data.pdfPath, this.data.pageNum);
};

// const applyCanvasTextureToMesh = async function (mesh, pdfPath, pageNum, cb) {
//   const pdfjsDoc = await convertPdfToPdfjsDoc(pdfPath);
//   const page = await getPageOfPdfjsDoc(pdfjsDoc, pageNum);

//   const canvas = await createCanvasContext(page, 1.0);
//   const textureCanvas = new AFRAME.THREE.CanvasTexture(canvas);
//   textureCanvas.flipY = false;

//   const material = new AFRAME.THREE.MeshBasicMaterial({
//     map: textureCanvas
//   });
//   mesh.material = material;
//   if (cb){ cb(pdfjsDoc.numPages); }
// };

export default { schema, init, update };