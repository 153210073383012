export const TOGGLE_GAME_SETTING_FLY = 'TOGGLE_GAME_SETTING_FLY';
export const TOGGLE_GAME_SETTING_HELP = 'TOGGLE_GAME_SETTING_HELP';
export const TOGGLE_GAME_SETTING_CAMERA = 'TOGGLE_GAME_SETTING_CAMERA';
export const TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE = 'TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE';
export const TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE = 'TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE';
export const TOGGLE_GAME_SETTING_IS_USER_SPEAKING = 'TOGGLE_GAME_SETTING_IS_USER_SPEAKING';
export const TOGGLE_GAME_SETTING_DARK_MODE = 'TOGGLE_GAME_SETTING_DARK_MODE';
export const RESET_GAME_SETTING_STATE = 'RESET_GAME_SETTING_STATE';
export const TRIGGER_GAME_SETTING_EVENT = 'TRIGGER_GAME_SETTING_EVENT';
export const ADD_GAME_SETTING_EVENT_LISTENER = 'ADD_GAME_SETTING_EVENT_LISTENER';
