import Vue from 'vue';
import { TRIGGER_TRACKABLE_EVENT } from './types.js';

export default {
  actions: {
    [TRIGGER_TRACKABLE_EVENT](_, { eventSlug, trackableEvent }) {
      return Vue.axios.post(`/api/${eventSlug}/trackable_events_log`, trackableEvent);
    }
  }
};
