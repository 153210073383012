import DragControls from '../threejs/DragControls';

// source: https://github.com/SeregPie/aframe-drag-controls/blob/main/src/index.js
// changes: optimisations + support for group transformations

const schema = {
  enabled: { default: true },
  objects: { default: '*' }
};

const init = function () {
  const controls = new DragControls([], this.el.sceneEl.camera, this.el.sceneEl.renderer.domElement);
  controls.transformGroup = true;
  controls.addEventListener('dragstart', () => {
    this.el.emit(`drag-controls:changed`, { active: true }, false);
  });
  controls.addEventListener('dragend', () => {
    this.el.emit(`drag-controls:changed`, { active: false }, false);
  });
  let mapObjectToEl = new Map();
  ['dragstart', 'drag', 'dragend', 'hoveron', 'hoveroff'].forEach(type => {
    controls.addEventListener(type, ({ object }) => {
      const el = mapObjectToEl.get(object);
      if (el) { el.emit(type, { object3D: object }, false); }
    });
  });
  if (this.data.enabled) {
    // TODO: wait for the intersection objects to exist in the DOM
    setTimeout(() => {
      mapObjectToEl = initControlsIntersectObjects(controls, Array.from(this.el.sceneEl.querySelectorAll(this.data.objects)));
    }, 5000);
  }
  this.controls = controls;
  this.mapObjectToEl = mapObjectToEl;
};

const update = function () {
  this.mapObjectToEl = initControlsIntersectObjects(this.controls, Array.from(this.el.sceneEl.querySelectorAll(this.data.objects)));
  Object.assign(this.controls, { enabled: this.data.enabled });
};

const remove = function () {
  this.controls.dispose();
};

export const initControlsIntersectObjects = (controls, elements) => {
  const objElMap = new Map();
  let objects = [];
  elements.forEach((el) => {
    if (el.isEntity && el.object3D) {
      Object.keys(el.object3DMap).forEach(key => {
        let object = el.getObject3D(key);
        objects.push(object);
        objElMap.set(object, el);
      });
    }
  });
  controls.getObjects().splice(0, undefined, ...objects);
  return objElMap;
};

/*const tock = function () {
  let {
    controls,
    data,
    el,
    mapObjectToEl,
  } = this;
  let {
    enabled,
    objects: selector,
  } = data;
  if (enabled) {
    let els = Array.from(el.sceneEl.querySelectorAll(selector));
    let objects = [];
    mapObjectToEl.clear();
    els.forEach(el => {
      if (el.isEntity && el.object3D) {
        Object.keys(el.object3DMap).forEach(key => {
          let object = el.getObject3D(key);
          objects.push(object);
          mapObjectToEl.set(object, el);
        });
      }
    });
    controls.getObjects().splice(0, undefined, ...objects);
  }
};*/

export default { schema, init, update, remove, initControlsIntersectObjects, /*tock*/ };
