import AFRAME from 'aframe';
import * as sdpTransform from 'sdp-transform';

const schema = {
  streamName: { default: 'audio' },
  positional: { default: true },
  distanceModel: {
    default: 'inverse',
    oneOf: ['linear', 'inverse', 'exponential']
  },
  maxDistance: { default: 10000 },
  refDistance: { default: 1 },
  rolloffFactor: { default: 1 },
  createSoundOnInit: { default: true }
};

const init = function () {
  this.stream = null;
  this.sound = null;
  this.audioEl = null;
  this._setupSound = this._setupSound.bind(this);
  if (this.data.createSoundOnInit) {
    this.createSound();
  }
};

const remove = function () {
  this.destroySound();
};

const update = function (oldData) {
  if (!this.sound) {
    return;
  }
  if (oldData.positional !== this.data.positional) {
    this.destroySound();
    this._setupSound(this.stream);
  } else if (this.data.positional) {
    this._setPannerProperties();
  }
};


const _setPannerProperties = function () {
  this.sound.setDistanceModel(this.data.distanceModel);
  this.sound.setMaxDistance(this.data.maxDistance);
  this.sound.setRefDistance(this.data.refDistance);
  this.sound.setRolloffFactor(this.data.rolloffFactor);
};

const destroySound = function () {
  if (this.sound) {
    this.el.emit('sound-source-removed', { soundSource: this.soundSource });
    this.sound.disconnect();
    this.el.removeObject3D(this.attrName);
    this.sound = null;
  }

  if (this.audioEl) {
    this.audioEl.pause();
    this.audioEl.srcObject = null;
    this.audioEl.load();
    this.audioEl = null;
  }
};

const createSound = function () {
  NAF.utils.getNetworkedEntity(this.el).then((networkedEl) => {
    const ownerId = networkedEl.components.networked.data.owner;

    if (ownerId) {
      NAF.connection.adapter.getMediaStream(ownerId, this.data.streamName)
        .then(this._setupSound)
        .catch((e) => console.log.error(`Error getting media stream for ${ownerId}`, e));
    } else {
      // Correctly configured local entity, perhaps do something here for enabling debug audio loopback
    }
  });
};

const _setupSound = function (newStream) {
  if (!newStream) { return; }
  const isRemoved = !this.el.parentNode;
  if (isRemoved) { return; }
  const el = this.el;
  const sceneEl = el.sceneEl;

  if (!sceneEl.audioListener) {
    sceneEl.audioListener = new AFRAME.THREE.AudioListener();
    sceneEl.camera && sceneEl.camera.add(sceneEl.audioListener);
    sceneEl.addEventListener('camera-set-active', function (evt) {
      evt.detail.cameraEl.getObject3D('camera').add(sceneEl.audioListener);
    });
  }

  this.sound = this.data.positional
    ? new AFRAME.THREE.PositionalAudio(sceneEl.audioListener)
    : new AFRAME.THREE.Audio(sceneEl.audioListener);
  el.setObject3D(this.attrName, this.sound);
  if (this.data.positional) {
    this._setPannerProperties();
  }

  // Chrome seems to require a MediaStream be attached to an AudioElement before AudioNodes work correctly
  // We don't want to do this in other browsers, particularly in Safari, which actually plays the audio despite
  // setting the volume to 0.
  if (/chrome/i.test(navigator.userAgent)) {
    this.audioEl = new Audio();
    this.audioEl.setAttribute('autoplay', 'autoplay');
    this.audioEl.setAttribute('playsinline', 'playsinline');
    this.audioEl.srcObject = newStream;
    this.audioEl.volume = 0; // we don't actually want to hear audio from this element
  }

  this.soundSource = this.sound.context.createMediaStreamSource(newStream);
  this.sound.setNodeSource(this.soundSource);
  this.el.emit('sound-source-set', { soundSource: this.soundSource });
  this.stream = newStream;

  if (!AFRAME.utils.device.isMobile() && /chrome/i.test(navigator.userAgent)) {
    enableChromeAEC(sceneEl.audioListener.gain);
  }
};

let delayedReconnectTimeout = null;
function performDelayedReconnect(gainNode) {
  if (delayedReconnectTimeout) {
    clearTimeout(delayedReconnectTimeout);
  }

  delayedReconnectTimeout = setTimeout(() => {
    delayedReconnectTimeout = null;
    console.warn('enableChromeAEC: recreate RTCPeerConnection loopback because the local connection was disconnected for 10s');
    // eslint-disable-next-line no-use-before-define
    enableChromeAEC(gainNode);
  }, 10000);
}

const enableChromeAEC = async (gainNode) => {
  console.log('enableChromeAEC', gainNode);
  /**
   *  workaround for: https://bugs.chromium.org/p/chromium/issues/detail?id=687574
   *  1. grab the GainNode from the scene's THREE.AudioListener
   *  2. disconnect the GainNode from the AudioDestinationNode (basically the audio out), this prevents hearing the audio twice.
   *  3. create a local webrtc connection between two RTCPeerConnections (see this example: https://webrtc.github.io/samples/src/content/peerconnection/pc1/)
   *  4. create a new MediaStreamDestination from the scene's THREE.AudioContext and connect the GainNode to it.
   *  5. add the MediaStreamDestination's track  to one of those RTCPeerConnections
   *  6. connect the other RTCPeerConnection's stream to a new audio element.
   *  All audio is now routed through Chrome's audio mixer, thus enabling AEC, while preserving all the audio processing that was performed via the WebAudio API.
   */

  const audioEl = new Audio();
  audioEl.setAttribute('autoplay', 'autoplay');
  audioEl.setAttribute('playsinline', 'playsinline');

  const context = AFRAME.THREE.AudioContext.getContext();
  const loopbackDestination = context.createMediaStreamDestination();
  const outboundPeerConnection = new RTCPeerConnection();
  const inboundPeerConnection = new RTCPeerConnection();

  const onError = e => {
    console.error('enableChromeAEC: RTCPeerConnection loopback initialization error', e);
  };

  outboundPeerConnection.addEventListener('icecandidate', e => {
    inboundPeerConnection.addIceCandidate(e.candidate).catch(onError);
  });

  outboundPeerConnection.addEventListener('iceconnectionstatechange', () => {
    console.warn('enableChromeAEC: outboundPeerConnection state changed to ' + outboundPeerConnection.iceConnectionState);
    if (outboundPeerConnection.iceConnectionState === 'disconnected') {
      performDelayedReconnect(gainNode);
    }
    if (outboundPeerConnection.iceConnectionState === 'connected') {
      if (delayedReconnectTimeout) { clearTimeout(delayedReconnectTimeout); }
    }
  });

  inboundPeerConnection.addEventListener('icecandidate', e => {
    outboundPeerConnection.addIceCandidate(e.candidate).catch(onError);
  });

  inboundPeerConnection.addEventListener('iceconnectionstatechange', () => {
    console.warn('enableChromeAEC: inboundPeerConnection state changed to ' + inboundPeerConnection.iceConnectionState);
    if (inboundPeerConnection.iceConnectionState === 'disconnected') {
      performDelayedReconnect(gainNode);
    }
    if (inboundPeerConnection.iceConnectionState === 'connected') {
      if (delayedReconnectTimeout) { clearTimeout(delayedReconnectTimeout); }
    }
  });

  inboundPeerConnection.addEventListener('track', e => {
    audioEl.srcObject = e.streams[0];
  });

  try {
    //The following should never fail, but just in case, we won't disconnect/reconnect the gainNode unless all of this succeeds
    loopbackDestination.stream.getTracks().forEach(track => {
      outboundPeerConnection.addTrack(track, loopbackDestination.stream);
    });

    const offer = await outboundPeerConnection.createOffer();
    outboundPeerConnection.setLocalDescription(offer);
    await inboundPeerConnection.setRemoteDescription(offer);

    const answer = await inboundPeerConnection.createAnswer();

    // Rewrite SDP to be stereo and (variable) max bitrate
    const parsedSdp = sdpTransform.parse(answer.sdp);
    for (let i = 0; i < parsedSdp.media.length; i++) {
      for (let j = 0; j < parsedSdp.media[i].fmtp.length; j++) {
        parsedSdp.media[i].fmtp[j].config += `;stereo=1;cbr=0;maxaveragebitrate=510000;`;
      }
    }
    answer.sdp = sdpTransform.write(parsedSdp);

    inboundPeerConnection.setLocalDescription(answer);
    outboundPeerConnection.setRemoteDescription(answer);

    gainNode.disconnect();
    gainNode.connect(loopbackDestination);
  } catch (e) {
    onError(e);
  }
};

export default { schema, init, update, remove, _setPannerProperties, destroySound, createSound, _setupSound, enableChromeAEC };
