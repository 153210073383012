import { isURL } from 'validator';

export default [
  {
    formFields: [
      {
        title: 'Session name',
        type: 'text',
        property: 'name',
        value: '',
        counter: 90,
        rules: [(v) => !!v || 'Name is required', (v) => v.length <= 90 || 'Name must be 90 characters or less'],
        required: true
      },
      {
        title: 'Description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      },
      {
        title: 'Tags',
        type: 'combo',
        property: 'tags',
        value: []
      },
      {
        title: 'Enter event link',
        type: 'text',
        property: 'destinationUrl',
        value: '',
        rules: [(v) => isURL(v) || 'Event link is required'],
        required: true
      },
    ]
  },
  {
    classes: ['flex-row', 'align-end'],
    formFields: [
      {
        title: 'Start date',
        type: 'datetime',
        property: 'startDate',
        value: new Date()
      },
      {
        title: 'End date',
        type: 'datetime',
        property: 'endDate',
        value: new Date(),
        classes: ['ml-6']
      },
    ]
  }
];