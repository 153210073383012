import Vue from 'vue';
import {
  GET_WELCOME_MESSAGE,
  GET_WELCOME_MESSAGE_BY_ID,
  SET_WELCOME_MESSAGE,
  SET_WELCOME_MESSAGE_BY_ID,
  CREATE_WELCOME_MESSAGE,
  UPDATE_WELCOME_MESSAGE,
  DELETE_WELCOME_MESSAGE,
} from './types.js';

const backwardsCompatibilityMap = (message) => {
  return {
    welcomeMessage: message.welcomeMessage,
    showWelcomeMessage: message.showWelcomeMessage,
    alwaysOn: message.alwaysOn,
    eventSlug: message.eventSlug,
  };
};

export default {
  state: () => ({
    welcomeMessage: null,
  }),
  mutations: {
    [SET_WELCOME_MESSAGE](state, value) {
      state.welcomeMessage = backwardsCompatibilityMap(value);
    },
    [SET_WELCOME_MESSAGE_BY_ID](state, value) {
      state.welcomeMessage = backwardsCompatibilityMap(value);
    },
  },
  actions: {
    [GET_WELCOME_MESSAGE]({commit}, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/welcome`).then((response) => {
        if (!response && !response.data) {
          commit(SET_WELCOME_MESSAGE, null);
          return null;
        } else {
          commit(SET_WELCOME_MESSAGE, response.data);
          return response.data;
        }
      });
    },

    [GET_WELCOME_MESSAGE_BY_ID]({commit}, id ) {
      return Vue.axios.get(`/api/welcome/${id}`).then((data) => {
        if (data && data.data) {
          commit(SET_WELCOME_MESSAGE_BY_ID, data.data);
          return data.data;
        } else {
          commit(SET_WELCOME_MESSAGE_BY_ID, null);
          return null;
        }
      });
    },

    [CREATE_WELCOME_MESSAGE](_context, { eventSlug, welcomeMessage }) {
      return Vue.axios.post(`/api/${eventSlug}/welcome`, welcomeMessage).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },

    [UPDATE_WELCOME_MESSAGE](_context, { eventSlug, id, welcomeMessage }) {
      return Vue.axios.put(`/api/${eventSlug}/welcome/${id}`, welcomeMessage).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },

    [DELETE_WELCOME_MESSAGE](_context, { eventSlug, id }) {
      return Vue.axios.delete(`/api/${eventSlug}/welcome/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
  },
};
