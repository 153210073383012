import { isFile } from '@/utils/files';

export default [
  {
    formFields: [
      {
        title: 'Upload thumbnail (.png or .jpeg, 1920px x 1080px)',
        caption: 'Max file size: 100kb',
        options: {
          aspectRatio: 1/1,
          width: 88,
          placeholderColor: '#e5e5e5',
          placeholderText: 'placeholderText',
          cropperSettings: {
            title: 'Crop your thumbnail',
            aspectRatio: 16/9,
          }
        },
        type: 'image',
        property: 'thumbnailFileId',
        value: null
      },
      {
        title: 'Video Name',
        type: 'text',
        property: 'name',
        value: '',
        rules: [(v) => !!v || 'Enter a video name'],
        required: true
      },
      {
        title: 'Video Description',
        type: 'textarea',
        property: 'description',
        counter: 200,
        rules: [(v) => v.length <= 200 || 'Max 200 characters'],
        value: '',
      },
      {
        title: 'Upload Video',
        options: {
          acceptedMimes: ['video/mp4', 'video/x-matroska', 'video/webm']
        },
        type: 'file',
        property: 'videoFileId',
        value: null,
        rules: [
          value => !!value || 'Please upload a video file',
          value => !!value || isFile(value) || 'Please upload a video file',
        ],
      },
    ]
  },
  {
    classes: ['flex-row', 'align-end'],
    formFields: [
      {
        title: 'Available from',
        type: 'datetime',
        property: 'activeFromDate',
        value: new Date()
      },
    ]
  }
];