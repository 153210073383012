export default [
  {
    formFields: [
      {
        title: '',
        type: 'avatar',
        property: 'avatarFileId',
        caption: '400px x 400px',
        value: null,
        options: {
          cropperSettings: {
            title: 'Crop your thumbnail',
            aspectRatio: 1/1,
          }
        },
      },
      {
        title: 'Name',
        type: 'text',
        property: 'name',
        value: '',
        rules: [(v) => !!v || 'Enter a name'],
        required: true
      },
      {
        title: 'Company',
        type: 'text',
        property: 'company',
        counter: 60,
        rules: [(v) => v.length <= 60 || 'Max 60 characters'],
        value: ''
      },
      {
        title: 'Company role/title',
        type: 'text',
        property: 'headline',
        counter: 60,
        rules: [(v) => v.length <= 60 || 'Max 60 characters'],
        value: ''
      },
      {
        title: 'Email Address',
        type: 'text',
        property: 'emailAddress',
        value: '',
        rules: [
          (v) => !!v || 'Enter an email address',
          (v) => /.+@.+\..+/.test(v) || 'Enter a valid email address'
        ],
        required: true
      },
      {
        title: 'Bio',
        type: 'textarea',
        property: 'bio',
        value: '',
        counter: 1000,
        rules: [(v) => v.length <= 1000 || 'Max 1000 characters']
      }
    ]
  }
];