import AFRAME from 'aframe';

const textureLoader =  new AFRAME.THREE.TextureLoader();

const schema = {
  src: { type: 'string', default: '' }
};

const init = function () {
  const data = this.data;
  if (!data.src || data.src.length === 0) { return; }
  this.el.addEventListener('gltf-part-loaded', (e) => {
    if (!e.detail || !e.detail.mesh) { return console.warn('A-FRAME:texture-mesh', 'Failed to load mesh'); }
    const mesh = e.detail.mesh;
    applyTextureToMesh(data.src, mesh, (e) => {
      if (!e) { this.el.emit('texture-loaded', { src: data.src }); }
    });
  }, { once: true });
};

const update = function (oldData) {
  if (!this.data.src || this.data.src.length === 0) { return; }
  if (!this.el.object3D.children || this.el.object3D.children.length === 0) { return; }

  const mesh = this.el.object3D.children[0];
  applyTextureToMesh(this.data.src, mesh);
};

const applyTextureToMesh = function (src, mesh, done) {
  textureLoader.load(src, (texture) => {
    texture.flipY = false;
    texture.encoding = AFRAME.THREE.sRGBEncoding;
    const material = new AFRAME.THREE.MeshBasicMaterial({
      map: texture
    });
    mesh.material = material;
    texture.dispose();
    material.dispose();
    if (done) { return done(); }
  }, null, (e) => {
    console.warn('A-FRAME:texture-mesh', 'Failed to load texture', e);
    if (done) { return done(e); }
  });
};

export default { schema, init, update };
