import AFRAME from 'aframe';
import { addDebouncedEventListener } from '@/utils/utils';

const init = function () {
  this.el.sceneEl.renderer.outputEncoding = AFRAME.THREE.sRGBEncoding;
  this.el.sceneEl.renderer.setClearColor(0xcccccc);
  this.el.sceneEl.renderer.setPixelRatio(window.devicePixelRatio);
  this.el.sceneEl.renderer.sortObjects = true;
  // this is a required system and can only be added programatically via component
  this.el.sceneEl.setAttribute('gltf-model', `dracoDecoderPath: https://www.gstatic.com/draco/v1/decoders/`);

  if (this.rendererResizeListener) { window.removeEventListener('resize', this.resizeAwareHeightListener); }
  this.rendererResizeListener = addDebouncedEventListener('resize', onResize, 50);

  // Some settings to consider for better performance and worse quality
  // this.el.sceneEl.renderer.antialias = false;
  // this.el.sceneEl.renderer.setPixelRatio(window.devicePixelRatio * 0.75);
  // TODO: investigate renderer.capabilities
};

const onResize = function () {
  const sceneEl = document.querySelector('a-scene');
  if (!sceneEl) { return; }

  sceneEl.camera.aspect = sceneEl.clientWidth / sceneEl.clientHeight;
  sceneEl.camera.updateProjectionMatrix();
  sceneEl.renderer.setSize(sceneEl.clientWidth, sceneEl.clientHeight);
};

export default { init, onResize };
