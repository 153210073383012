import Vue from 'vue';
import {
  CREATE_FILES_SINGLE,
  CREATE_FILES_MANY,
  UC_UPLOAD_FILE,
  UC_COPY_FILE
} from './types.js';

export default {
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    [CREATE_FILES_SINGLE](_context, {eventSlug, file}) {
      if (!file) { return; }
      const formData = new FormData();
      formData.append('files', file);
      return Vue.axios.post(
        `/api/${eventSlug}/files/upload`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [CREATE_FILES_MANY](_context, { eventSlug, files }) {
      if (!eventSlug || files.length === 0) { return; }
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));
      return Vue.axios.post(
        `/api/${eventSlug}/files/upload`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    async [UC_UPLOAD_FILE](_context, { eventSlug, ucFileInfo }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/files/upload/uc`, ucFileInfo);
        if (!response || !response.data) { return null; }
        return response.data;
      } catch (e) {
        return null;
      }
    },
    async [UC_COPY_FILE](_context, { eventSlug, source }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/files/copy/uc`, { source });
        if (!response || !response.data) { return null; }
        return response.data;
      } catch (e) {
        return null;
      }
    }
  },
};
