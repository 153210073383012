export const GET_ALL_ACHIEVEMENTS = 'GET_ALL_ACHIEVEMENTS';
export const SET_ALL_ACHIEVEMENTS = 'SET_ALL_ACHIEVEMENTS';
export const GET_USER_ACHIEVEMENT_PROGRESS = 'GET_USER_ACHIEVEMENT_PROGRESS';
export const SET_USER_ACHIEVEMENT_PROGRESS = 'SET_USER_ACHIEVEMENT_PROGRESS';
export const GET_USER_ACHIEVEMENT_TASK_PROGRESS = 'GET_USER_ACHIEVEMENT_TASK_PROGRESS';
export const SET_USER_ACHIEVEMENT_TASK_PROGRESS = 'SET_USER_ACHIEVEMENT_TASK_PROGRESS';
export const GET_ACHIEVEMENT_GROUPS = 'GET_ACHIEVEMENT_GROUPS';
export const SET_ACHIEVEMENT_GROUPS = 'SET_ACHIEVEMENT_GROUPS';
export const GET_ACHIEVEMENT_LEADERBOARD_POSITION = 'GET_ACHIEVEMENT_LEADERBOARD_POSITION';
export const SET_ACHIEVEMENT_LEADERBOARD_POSITION = 'SET_ACHIEVEMENT_LEADERBOARD_POSITION';
export const SET_ACHIEVEMENT_LEADERBOARD_POSITION_LAST_UPDATE_TIME = 'SET_ACHIEVEMENT_LEADERBOARD_POSITION_LAST_UPDATE_TIME';
export const GET_ACHIEVEMENT_LEADERBOARD = 'GET_ACHIEVEMENT_LEADERBOARD';
export const SET_ACHIEVEMENT_LEADERBOARD = 'SET_ACHIEVEMENT_LEADERBOARD';
export const SET_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME = 'SET_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME';
export const GET_MINI_ACHIEVEMENT_LEADERBOARD = 'GET_MINI_ACHIEVEMENT_LEADERBOARD';
export const SET_MINI_ACHIEVEMENT_LEADERBOARD = 'SET_MINI_ACHIEVEMENT_LEADERBOARD';
export const SET_MINI_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME = 'SET_MINI_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME';
export const REFRESH_ACHIEVEMENT_LEADERBOARD = 'REFRESH_ACHIEVEMENT_LEADERBOARD';
