import { getStaticAssetUrl } from '@/utils/files';

export const posterNames = ['landscape1image', 'portrait1image', 'portrait2image', 'landscape2image', 'landscape3image', 'landscape4image','landscape5image'];
export const placeholderRoot = `${process.env.VUE_APP_API_URL}/internal/img/booth-config-placeholders`;
export const posters = [
  { imageName: 'landscape1image', htmlElementReference: 'fileLandscape1Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/green-landscape.png`, },
  { imageName: 'landscape2image', htmlElementReference: 'landscape2Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/blue-landscape.png`, },
  { imageName: 'portrait1image', htmlElementReference: 'portrait1Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/purple-portrait.png`, },
  { imageName:  'landscape3image', htmlElementReference: 'landscape3Image', orientation: 'landscape', placeholderSrc : `${placeholderRoot}/orange-landscape.png` },
  { imageName:  'portrait2image',  htmlElementReference: 'portrait2Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/red-portrait.png`, },
  { imageName:   'landscape4image', htmlElementReference: 'landscape4Image', orientation: 'landscape', placeholderSrc : `${placeholderRoot}/darkblue-landscape.png` },
  { imageName:  'landscape5image', htmlElementReference: 'landscape5Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/turquise-landscape.png` },
];
export const postersSmall = [
  { imageName: 'landscape1image', htmlElementReference: 'fileLandscape1Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/green-landscape.png`, },
  { imageName: 'portrait1image', htmlElementReference: 'portrait1Image', orientation: 'portrait',  placeholderSrc: `${placeholderRoot}/blue-portrait.png`,  },
];
export const postersMedium = [
  { imageName: 'portrait1image', htmlElementReference: 'portrait1Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/green-landscape.png`, },
  { imageName: 'landscape1image', htmlElementReference: 'fileLandscape1Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/blue-landscape.png`, },
  { imageName:  'portrait2image',  htmlElementReference: 'portrait2Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/purple-portrait.png`, }
];
export const postersLarge = [
  { imageName: 'landscape1image', htmlElementReference: 'fileLandscape1Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/green-landscape.png`, },
  { imageName: 'landscape2image', htmlElementReference: 'landscape2Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/blue-landscape.png`, },
  { imageName: 'portrait1image', htmlElementReference: 'portrait1Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/purple-portrait.png`, },
  { imageName:  'landscape3image', htmlElementReference: 'landscape3Image', orientation: 'landscape', placeholderSrc : `${placeholderRoot}/orange-landscape.png` },
  { imageName:  'portrait2image',  htmlElementReference: 'portrait2Image', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/red-portrait.png`, },
  { imageName:   'landscape4image', htmlElementReference: 'landscape4Image', orientation: 'landscape', placeholderSrc : `${placeholderRoot}/darkblue-landscape.png` },
  { imageName:  'landscape5image', htmlElementReference: 'landscape5Image', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/turquise-landscape.png` },
];
export const otherBoothImages = [
  { imageName: 'logo_navbar', htmlElementReference: 'fileLogoNavbar', orientation: 'landscape', placeholderSrc: `${placeholderRoot}/logo_navbar.png` },
  { imageName: 'logo_booth', htmlElementReference: 'fileLogoBooth', orientation: 'square', placeholderSrc: `${placeholderRoot}/logo_booth.png` },
  { imageName:  'booth_preview',  htmlElementReference: 'fileBoothPreview', orientation: 'portrait', placeholderSrc: `${placeholderRoot}/booth_preview.jpg` },
];
const posterLinks = ['landscape1imageLink', 'portrait1imageLink', 'portrait2imageLink', 'landscape2imageLink', 'landscape3imageLink', 'landscape4imageLink','landscape5imageLink'];
const socials = ['youtube' ,'linkedin', 'twitter', 'facebook', 'website'];
const otherKeysToDelete = ['id', 'booth_preview', 'logo_navbar', 'logo_booth', 'navbar_colour', 'posterLinks', 'posterNames', 'opening_hours'];

export const mediaSettings = {
  landscape: {
    aspectRatio: 14/10,
    minWidth: 1400,
    minHeight: 1000
  },
  portrait: {
    aspectRatio: 10/14,
    minWidth: 1000,
    minHeight: 1400,
  },
  boothLogo: {
    aspectRatio: 4/1,
    minWidth: 600,
    minHeight: 150,
  },
  navbarLogo: {
    aspectRatio: 200/34,
    minWidth: 200,
    minHeight: 34,
  },
  boothPreview: {
    aspectRatio: 4/3,
    minWidth: 400,
    minHeight: 300,
  }
};


export function createLinksArray(object) {
  const keys = Object.keys(object);
  return keys.map((key) => {
    return {
      key: `mdi-${key}`,
      url: object[key]
    };
  }).filter((link) => link.url);
}

export function addBoothData(booths) {
  return booths.map((booth, i) => {
    var originalBooth = booth;
    booth = spreadJson(booth);
    return {
      ...booth,
      id: originalBooth.id,
      team: [],
      links: createLinksArray({ youtube: booth.youtube, linkedin: booth.linkedin,  twitter: booth.twitter, facebook: booth.facebook, webex: booth.webex, yammer: booth.yammer, web: booth.website, other: booth.other }),
      screenshotSrc:  booth.screenshot ? `${process.env.VUE_APP_API_URL}/api/booth_images/${booth.screenshot}` : 'https://via.placeholder.com/240x110',
      boothStatus: formatBoothStatus(booth)
    };
  });
}

export function spreadJson(booth) {
  const externalLinkData = booth.externalLinkData;
  const contentData = booth.contentData;
  return {...booth, ...externalLinkData, ...contentData} ;
}
export function addDataToBoothObject(booth) {
  const formattedBooth = addBoothData([booth])[0];
  return {...formattedBooth, posterNames, posterLinks};
}
export function formatTeam(person) {
  return {
    ...person,
    alt: person.name,
    id: person.id,
    presence: '',
    role: person.headline,
    src: getStaticAssetUrl(person.avatarFileId) ? getStaticAssetUrl(person.avatarFileId) : ''
  };
}

export function formatBoothStatus(booth) {
  if (booth.boothStatus) {
    if (booth.boothStatus.status) {
      return 'open';
    }
    else {
      return 'closed';
    }
  }
  else {
    return 'closed';
  }
}
export function formatPosters(booth, newFormatting = false) {
  if (newFormatting) {
    let result = [];
    for (const prop in booth.contentData){
      if (booth.contentData[prop]){
        if (/logo_booth/i.test(prop)) {
          for (let idx = 1; idx < 10; idx++) {
            result.push({
              name: `logo_${idx}`,
              type: `logo`,
              src: booth.contentData[prop]
            }); 
          }
        } else if (/lands[0-9a-zA-Z]*image$/i.test(prop)) {
          result.push({
            name: `L_poster_${prop.match(/\d/g).join('')}`,
            type: 'poster',
            src: booth.contentData[prop]
          });
        } else if (prop.includes('media_')){
          result.push({
            name: prop,
            type: 'poster',
            src: booth.contentData[prop]
          });
        } else if (/portrait[0-9a-zA-Z]*image$/i.test(prop)) {
          result.push({
            name: `V_poster_${prop.match(/\d/g).join('')}`,
            type: 'poster',
            src: booth.contentData[prop]
          });
        } else if (prop === 'primary_colour') {
          result.push({
            name: 'primary_color',
            type: 'primary',
            color: booth.contentData[prop] ? booth.contentData[prop].replace('#', '') : '',
            src: booth.contentData[prop]
          });
        } else if (prop === 'secondary_colour') {
          result.push({
            name: 'secondary_color',
            type: 'secondary',
            color: booth.contentData[prop] ? booth.contentData[prop].replace('#', '') : '',
            src: booth.contentData[prop]
          });
        }
      }
      
    }
   
    return result;
  }
  const posterList = [];
  let landscapeIndex = 1;
  let portraitIndex = 1;

  if (booth.metadata && booth.metadata.interactableMeshes) {
    booth.metadata.interactableMeshes.filter((mesh) => {
      return mesh.type === 'poster';
    }).forEach((mesh) =>  {
      if (mesh.width === 1400) {
        const landscapeImageProp = 'landscape' + landscapeIndex + 'image';
        const landscapeLinkProp = 'landscape' + landscapeIndex + 'imageLink';
        const meshSrc = booth.contentData[landscapeImageProp];
        const meshTarget = booth.contentData[landscapeLinkProp];
        if (meshSrc) { posterList.push({ name: mesh.name, src: meshSrc, target: meshTarget, type: 'poster'}); }
        landscapeIndex++;
      }
      if (mesh.width === 1000) {
        const portraitImageProp = 'portrait' + portraitIndex + 'image';
        const portraitLinkProp = 'portrait' + portraitIndex + 'imageLink';
        const meshSrc = booth.contentData[portraitImageProp];
        const meshTarget = booth.contentData[portraitLinkProp];
        if (meshSrc) { posterList.push({ name: mesh.name, src: meshSrc, target: meshTarget, type: 'poster'}); }
        portraitIndex++;
      }
    });
  }

  if (booth.logo_booth && booth.logo_booth.length > 0 && booth.metadata && booth.metadata.interactableMeshes) {
    booth.metadata.interactableMeshes.filter((mesh) => {
      return mesh.type === 'banner' || mesh.type === 'logo';
    }).forEach((mesh) =>  {
      posterList.push({ name: mesh.name, src: booth.logo_booth, type: 'logo'});
    });
  }
  
  return posterList;
}

export function unSpreadJson(booth) {
  let unspreadBooth = booth;
  unspreadBooth.externalLinkData = {
    youtube: booth.youtube ? booth.youtube : '',
    linkedin: booth.linkedin ? booth.linkedin : '',
    twitter: booth.twitter ? booth.twitter : '',
    facebook: booth.facebook ? booth.facebook : '',
    website: booth.website ? booth.website : '',
  };
  // TODO: this is not flexible enough to handle dynamic poster count, for now it is filled with 10 of each portrait/landscape
  unspreadBooth.contentData = {
    logo_navbar: booth.logo_navbar ? booth.logo_navbar : '',
    logo_booth: booth.logo_booth ? booth.logo_booth : '',
    navbar_colour: booth.navbar_colour ? booth.navbar_colour : '',
    primary_colour: booth.primary_colour ? booth.primary_colour : '',
    secondary_colour: booth.secondary_colour ? booth.secondary_colour : '',
    landscape1image: booth.landscape1image ? booth.landscape1image : ``,
    landscape2image: booth.landscape2image ? booth.landscape2image : ``,
    landscape3image: booth.landscape3image ? booth.landscape3image : ``,
    landscape4image: booth.landscape4image ? booth.landscape4image : ``,
    landscape5image: booth.landscape5image ? booth.landscape5image : ``,
    landscape6image: booth.landscape6image ? booth.landscape6image : ``,
    landscape7image: booth.landscape7image ? booth.landscape7image : ``,
    landscape8image: booth.landscape8image ? booth.landscape8image : ``,
    landscape9image: booth.landscape9image ? booth.landscape9image : ``,
    landscape10image: booth.landscape10image ? booth.landscape10image : ``,
    portrait1image: booth.portrait1image ? booth.portrait1image : ``,
    portrait2image: booth.portrait2image ? booth.portrait2image : ``,
    portrait3image: booth.portrait3image ? booth.portrait3image : ``,
    portrait4image: booth.portrait4image ? booth.portrait4image : ``,
    portrait5image: booth.portrait5image ? booth.portrait5image : ``,
    portrait6image: booth.portrait6image ? booth.portrait6image : ``,
    portrait7image: booth.portrait7image ? booth.portrait7image : ``,
    portrait8image: booth.portrait8image ? booth.portrait8image : ``,
    portrait9image: booth.portrait9image ? booth.portrait9image : ``,
    portrait10image: booth.portrait10image ? booth.portrait10image : ``,
    landscape1imageLink: booth.landscape1imageLink ? booth.landscape1imageLink :'',
    landscape2imageLink: booth.landscape2imageLink ? booth.landscape2imageLink : '',
    landscape3imageLink: booth.landscape3imageLink ? booth.landscape3imageLink : '',
    landscape4imageLink: booth.landscape4imageLink ? booth.landscape4imageLink : '',
    landscape5imageLink: booth.landscape5imageLink ? booth.landscape5imageLink : '',
    landscape6imageLink: booth.landscape6imageLink ? booth.landscape6imageLink : '',
    landscape7imageLink: booth.landscape7imageLink ? booth.landscape7imageLink : '',
    landscape8imageLink: booth.landscape8imageLink ? booth.landscape8imageLink : '',
    landscape9imageLink: booth.landscape9imageLink ? booth.landscape9imageLink : '',
    landscape10imageLink: booth.landscape10imageLink ? booth.landscape10imageLink : '',
    portrait1imageLink: booth.portrait1imageLink ? booth.portrait1imageLink : '',
    portrait2imageLink: booth.portrait2imageLink ? booth.portrait2imageLink : '',
    portrait3imageLink: booth.portrait3imageLink ? booth.portrait3imageLink : '',
    portrait4imageLink: booth.portrait4imageLink ? booth.portrait4imageLink : '',
    portrait5imageLink: booth.portrait5imageLink ? booth.portrait5imageLink : '',
    portrait6imageLink: booth.portrait6imageLink ? booth.portrait6imageLink : '',
    portrait7imageLink: booth.portrait7imageLink ? booth.portrait7imageLink : '',
    portrait8imageLink: booth.portrait8imageLink ? booth.portrait8imageLink : '',
    portrait9imageLink: booth.portrait9imageLink ? booth.portrait9imageLink : '',
    portrait10imageLink: booth.portrait10imageLink ? booth.portrait10imageLink : '',
    booth_preview: booth.booth_preview ? booth.booth_preview : ``,
  };
  return unspreadBooth;
}

export function cleanJson(booth) {
  let cleanedBooth = booth;
  cleanedBooth = {...cleanedBooth, title: cleanedBooth.title.trim()};
  let itemsToDelete = [...posterNames, ...posterLinks, ...socials, ...otherKeysToDelete];

  for (let idx = 0; idx < itemsToDelete.length; idx++) {
    const item = itemsToDelete[idx];
    delete cleanedBooth[item];
  }
  return cleanedBooth;
}

export const backwardsCompatibilityMap = (booth) => {
  const newBooth = {
    ...booth,
  };
  // TODO: remove this mapping once this is done correctly, there should be multiple separate color values
  const stripHexcodeHash = (hexcodeString) => { return hexcodeString.replace('#', ''); };
  // ideally, the booth would already have data structured this way but it uses contentData for colours instead for now
  newBooth.paintableMeshValues = [];
  if (newBooth.contentData.primary_colour) { newBooth.paintableMeshValues.push({ name: 'primary_color', type: 'primary', color: stripHexcodeHash(newBooth.contentData.primary_colour)}); }
  if (newBooth.contentData.secondary_colour) { newBooth.paintableMeshValues.push({ name: 'secondary_color', type: 'secondary', color: stripHexcodeHash(newBooth.contentData.secondary_colour)}); }
  if (newBooth.contentData.brand_colour && newBooth.paintableMeshValues.length === 0) {
    // brand_colour is deprecated, but this is needed for older booths that had this value set
    newBooth.paintableMeshValues = [
      { name: 'primary_color', type: 'primary', color: stripHexcodeHash(newBooth.contentData.brand_colour) },
      { name: 'secondary_color', type: 'secondary', color: stripHexcodeHash(newBooth.contentData.brand_colour) },
    ];
  }
  if (newBooth.contentData) {
    newBooth.dynamicMeshValues = formatPosters(booth, true);
  }
  return newBooth;
};

export const getBoothPaintableMeshValue = (booth, meshName) => {
  const matchingMeshValue = booth.paintableMeshValues.find(m => m.name === meshName);
  if (matchingMeshValue) { return matchingMeshValue.color; }
  return 'FFFFFF';
};

export const blankBooth = {
  'id':'',
  'modelReferenceId':2,
  'title':'',
  'description':'',
  'openingHours':'',
  'contentData':{'logo_booth':'',
    'logo_navbar':'',
    'booth_preview':'',
    'navbar_colour':'#000000',
    'primary_colour':'#FFFFFF',
    'secondary_colour':'#000000',
    'opening_hours':'',
    'portrait1image':'',
    'portrait2image':'',
    'landscape1image':'',
    'landscape2image':'',
    'landscape3image':'',
    'landscape4image':'',
    'landscape5image':'',
    'portrait1imageLink':'',
    'portrait2imageLink':'',
    'landscape1imageLink':'',
    'landscape2imageLink':'',
    'landscape3imageLink':'',
    'landscape4imageLink':'',
    'landscape5imageLink':''},
  'externalLinkData':{'twitter':'','website':'','youtube':'','facebook':'','linkedin':''},'twitter':'','website':'','youtube':'','facebook':'','linkedin':'','logo_booth':'','logo_navbar':'','booth_preview':'','navbar_colour':'#000000','opening_hours':'','portrait1image':'','portrait2image':'','landscape1image':'','landscape2image':'','landscape3image':'','landscape4image':'','landscape5image':'','portrait1imageLink':'','portrait2imageLink':'','landscape1imageLink':'','landscape2imageLink':'','landscape3imageLink':'','landscape4imageLink':'','landscape5imageLink':'','posterNames':['landscape1image','portrait1image','portrait2image','landscape2image','landscape3image','landscape4image','landscape5image'],
  'posterLinks':['landscape1imageLink','portrait1imageLink','portrait2imageLink','landscape2imageLink','landscape3imageLink','landscape4imageLink','landscape5imageLink']
};