import { INIT_SCENE_LOADER, SET_IS_ROUTE_LOADING, SET_THREE_D_PAGE_LOADING, TOGGLE_THREE_D_PAGE_LOADING, SET_SCENE_LOADING_TITLE, SET_SCENE_LOADING_SUBTITLE, SET_SCENE_LOADING_PROGRESS, INCREMENT_SCENE_LOADING_PROGRESS } from './types.js';

const threeDLoaderData = {
  frequencyX3: {
    type: 'User Engagment',
    items: [
      {
        description: 'Short term goal',
        icon: 'mdi-arm-flex-outline',
        text: 'Get involved, speak with people, visit booths and attend sessions',
        exclude: []
      },
      {
        description: 'Meduim term goal',
        icon: 'mdi-chart-box-outline',
        text: 'Complete personal goals and event achievements',
        exclude: []
      },
      {
        description: 'Long term goal',
        icon: 'mdi-trophy-outline',
        text: 'There will be prizes for high achievers',
        exclude: []
      },
    ],
  },
  frequencyX2: {
    type: 'User Engagment',
    items: [
      {
        description: 'Move foward',
        icon: 'mdi-gesture-tap',
        text: 'Press and hold to move foward',
        exclude: ['2D', 'VR', 'desktop']
      },
      {
        description: 'Turn left or right',
        icon: 'mdi-gesture-swipe-horizontal',
        text: 'Use the arrow keys to move foward, backwards, and rotate',
        exclude: ['2D', 'VR', 'desktop']
      },
      {
        description: 'Movement controls',
        svg: 'moveLight',
        text: 'Use the arrow keys to move foward, backwards, and rotate',
        exclude: ['2D', 'VR', 'mobile']
      },
      {
        description: 'Strafe Movement',
        svg: 'strafeLight',
        text: 'A and D will move you left and right',
        exclude: ['2D', 'VR', 'mobile']
      },
      {
        description: 'Mouse-look around',
        text: 'Click and drag mouse to look around',
        icon: 'mdi-mouse',
        exclude: ['2D', 'VR', 'mobile']
      },
      {
        description: 'Select objects',
        text: 'Select posters to view details',
        icon: 'mdi-cursor-default-click-outline',
        exclude: ['2D']
      },
      {
        description: 'View booth',
        text: 'Select booth popup to view details & options',
        icon: 'mdi-store-outline',
        exclude: ['2D']
      },
      {
        description: 'Mic and speaker',
        text: 'Use the audio settings to mute/unmute and manage volume levels',
        icon: 'mdi-microphone-outline',
        exclude: ['2D']
      },
      {
        description: 'Mic and speaker',
        text: 'Use the audio settings to mute/unmute and manage volume levels',
        icon: 'mdi-volume-high',
        exclude: ['2D']
      },
      //  uncomment when feature is implemented
      // {
      //   description: 'Select people',
      //   text: 'Select people to view their details',
      //   icon: 'mdiHumanMale',
      //   exclude: ['2D']
      // },
    ],
  },
  frequencyX1: {
    type: 'Feature promotion',
    items: [
      {
        description: 'Booths',
        icon: 'mdi-store-outline',
        text: 'Visit booths and speak to booth reps',
        exclude: []
      },
      {
        description: 'Chat',
        icon: 'mdi-commenttext-outline',
        text: 'Send messages to attendees with the chat feature',
        exclude: ['2D', '3D']
      },
      {
        description: 'Swagbag',
        icon: 'mdi-shopping-outline',
        text: 'Collect items by adding them to your swagbag',
        exclude: []
      },
      //  uncomment when feature is implemented
      // {
      //   description: 'Auditorium',
      //   icon: 'mdiPresentation',
      //   text: 'View sessions in the Auditorium',
      //   exclude: []
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'People',
      //   icon: 'mdiHumanMaleFemale',
      //   text: 'Interact with others by skeaking, messaging, exchanging bussness cards and compare progress stats',
      //   exclude: ['2D', '3D']
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Networking',
      //   icon: 'mdiAccountTieVoiceOutline',
      //   text: 'Mingle with others in the networking area',
      //   exclude: ['2D']
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Selfie',
      //   icon: 'mdiAccountBoxOutline',
      //   text: 'Snap a selfie and post on Linkedin or Twitter',
      //   exclude: ['2D']
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Map',
      //   icon: 'mdiMapOutline',
      //   text: 'Find people, and places of interest on the event map',
      //   exclude: ['2D']
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Notifications',
      //   icon: 'mdiBellOutline',
      //   text: 'Receive event messages, session reminders and general updates with notifications',
      //   exclude: []
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Search all',
      //   icon: 'mdiMagnify',
      //   text: 'Use the search in the top bar to search all event content',
      //   exclude: []
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Audio zones',
      //   icon: 'mdiForumOutline',
      //   text: 'Only people inside an audio zone can hear you speak. For private conversations make sure to lock the zone',
      //   exclude: ['2D']
      // },
      //  uncomment when feature is implemented
      // {
      //   description: 'Reminders',
      //   icon: 'mdiAlarmPlus',
      //   text: 'Set reminders for sessions of intrest, prompts to meet leads and other personal goals',
      //   exclude: []
      // },
    ]
  }
};
function generateThreeDLoaderItem () {
  const randomNumber = Math.ceil((Math.random() * 12));
  let items;
  let item;
  let filteredItems;
  if (randomNumber < 3) {
    items = threeDLoaderData.frequencyX1.items;
  }
  else if (randomNumber >= 3 && randomNumber < 7) {
    items = threeDLoaderData.frequencyX2.items;
  } else {
    items = threeDLoaderData.frequencyX3.items;
  }
  items = filterThreeDLoaderItems(items);
  filteredItems = filterThreeDLoaderItems(items);
  if (items.length === 0) {
    item = threeDLoaderData.frequencyX1.items[Math.ceil(Math.random() * threeDLoaderData.frequencyX1.items.length - 1)];
  }
  item = filteredItems[Math.ceil(Math.random() * items.length - 1)];
  return item;
}

function filterThreeDLoaderItems (items) {
  const isMobile = window.innerWidth < 600;
  if (!isMobile) {
    items = items.filter( item => item.exclude.length === 0 || !item.exclude.includes('desktop') );
  } else {
    items = items.filter( item => item.exclude.length === 0 || !item.exclude.includes('mobile') );
  }
  return items;
}

const defautThreeDLoadingState = {
  isLoading: false,
  description: '',
  icon: '',
  text: '',
  exclude: []
};

export default {
  state: () => ({
    threeDLoader: defautThreeDLoadingState,
    isRouteLoading: true,
    isSceneLoading: true,
    sceneLoadingTitle: '',
    sceneLoadingSubtitle: '',
    sceneLoadingProgress: 0
  }),
  mutations: {
    [SET_IS_ROUTE_LOADING](state, value) {
      state.isRouteLoading = value;
    },
    [SET_THREE_D_PAGE_LOADING](state, value) {
      state.threeDLoader = value;
    },
    [SET_SCENE_LOADING_TITLE](state, value) {
      state.sceneLoadingTitle = value;
    },
    [SET_SCENE_LOADING_SUBTITLE](state, value) {
      state.sceneLoadingSubtitle = value;
    },
    [SET_SCENE_LOADING_PROGRESS](state, value) {
      state.sceneLoadingProgress = value;
      // use a timeout so that the UI shows 100% for .5s before disappearing
      if (state.sceneLoadingProgress >= 100) {
        setTimeout(() => {
          state.isSceneLoading = false;
        }, 500);
      } else {
        state.isSceneLoading = true;
      }
    },
    [INCREMENT_SCENE_LOADING_PROGRESS](state, value) {
      if (typeof value === 'undefined') { value = 1; }
      state.sceneLoadingProgress += value;
      // use a timeout so that the UI shows 100% for .5s before disappearing
      if (state.sceneLoadingProgress >= 100) {
        setTimeout(() => {
          state.isSceneLoading = false;
        }, 500);
      } else {
        state.isSceneLoading = true;
      }
    }
  },
  actions: {
    [TOGGLE_THREE_D_PAGE_LOADING]({ commit, state, getters }, { isLoading }) {
      if (isLoading === state.threeDLoader.isLoading) { return; }
      if (!isLoading) {
        return commit(SET_THREE_D_PAGE_LOADING, defautThreeDLoadingState);
      }
      commit(SET_THREE_D_PAGE_LOADING, {
        isLoading: true,
        ...generateThreeDLoaderItem()
      });
    },
    [INIT_SCENE_LOADER]({ commit }, { title, subtitle }) {
      commit(SET_SCENE_LOADING_PROGRESS, 0);
      commit(SET_SCENE_LOADING_TITLE, title);
      commit(SET_SCENE_LOADING_SUBTITLE, subtitle);
    }
  }
};