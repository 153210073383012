import Vue from 'vue';
import VueRouter from 'vue-router';
import { MixpanelEvents, MixpanelCreateDataObject } from '@/utils/mixpanel';
import { hasRouteAccess, hasEventAccess, isEventAdmin, isSiteAdmin } from '@/utils/constants';
import { GET_USER } from '@/store/user/types';
import { GET_EVENT_BY_SLUG } from '@/store/events/types';
import { GET_EVENT_BRANDING, GET_SITE_BRANDING } from '@/store/branding/types';
import { GET_PAGES } from '@/store/pages/types';
import { TRIGGER_TRACKABLE_EVENT } from '@/store/trackable_events/types';
import { SET_IS_ROUTE_LOADING } from '@/store/loading/types';

Vue.use(VueRouter);

const store = (type) => Vue.prototype.$store[type];

const loadBranding = async (to, from, next) => {
  await store('dispatch')(GET_SITE_BRANDING);
  return next();
};

const enforceAdminPermissions = async (to, from, next) => {
  // redirect unauthorised users to login page
  const user = await store('dispatch')(GET_USER);
  await store('dispatch')(GET_SITE_BRANDING);
  if (!user) { return next(window.location.pathname !== '/' ? `/login?redirect=${window.location.pathname}`: '/login'); }
  // when navigating to an event admin page, redirect to site-admin if it does not exist
  if (to.params.event) {
    if (!isEventAdmin(user, to.params.event) && !hasRouteAccess(user, to)) { return next('/login'); }
    try {
      await store('dispatch')(GET_EVENT_BY_SLUG, to.params.event);
      await store('dispatch')(GET_EVENT_BRANDING, { eventSlug: to.params.event });
    } catch (e) {
      return next('/admin');
    }
  } else {
    if (!isSiteAdmin(user)) { return next('/login'); }
  }
  return next();
};

const enforceLoggedIn = async (to, from, next) => {
  // redirect unauthorised users to login page
  const user = await store('dispatch')(GET_USER);
  await store('dispatch')(GET_SITE_BRANDING);
  if (!user) { return next(window.location.pathname !== '/' ? `/login?redirect=${window.location.pathname}`: '/login'); }
  // when navigating to an event page, redirect to choose-event if it does not exist
  if (to.params.event) {
    try {
      await store('dispatch')(GET_EVENT_BY_SLUG, to.params.event);
      await store('dispatch')(GET_EVENT_BRANDING, { eventSlug: to.params.event });
    } catch (e) {
      return next('/choose-event#');
    }
  }
  return next();
};

const enforceEventPermissions = async (to, from, next) => {
  // redirect unauthorised users to login page
  const user = await store('dispatch')(GET_USER);
  await store('dispatch')(GET_SITE_BRANDING);
  if (!user) { return next(window.location.pathname !== '/' ? `/login?redirect=${window.location.pathname}`: '/login'); }
  // when navigating to an event page, redirect to choose-event if it does not exist
  if (to.params.event) {
    try {
      await store('dispatch')(GET_PAGES, { eventSlug: to.params.event });
      await store('dispatch')(GET_EVENT_BY_SLUG, to.params.event);
      await store('dispatch')(GET_EVENT_BRANDING, { eventSlug: to.params.event });
      const event = Vue.prototype.$store.state.events.activeEvent;
      const now = new Date();
      if (!isEventAdmin(user, to.params.event) && (now < event.startDate || now > event.endDate)) {
        // prevent access to events that are inactive, unless user is an admin of the event
        return next('/choose-event');
      } else if (!isEventAdmin(user, to.params.event) && !hasEventAccess(user, to.params.event) && !hasRouteAccess(user, to)) {
        // prevent access to events that have a domain whitelist, unless user is an admin of the event
        return next('/choose-event');
      }
    } catch (e) {
      return next('/choose-event');
    }
  }
  return next();
};

const enforceEventMode = (to, _from, next) => {
  store('dispatch')('navigationModeEnforcedRedirect', { to, next });
};

const routes = [
  {
    path: '/login',
    name: 'site-login',
    beforeEnter: loadBranding,
    component: () => import(/* webpackChunkName: "site-login" */ '../views/Login.vue'),
  },
  { path: '/login/ciscolive', beforeEnter: loadBranding, name: 'site-ciscolive-login', component: () => import(/* webpackChunkName: "site-login" */ '../views/LoginCiscoLive.vue') },
  {
    path: '/choose-event',
    name: 'site-choose-event',
    meta: { breadcrumb: 'Event center home' },
    beforeEnter: enforceLoggedIn,
    component: () => import(/* webpackChunkName: "site-main" */ '../views/landing/ChooseEvent.vue'),
  },
  {
    path: '/choose-event/:event',
    name: 'site-choose-event-details',
    beforeEnter: enforceLoggedIn,
    component: () => import(/* webpackChunkName: "site-main" */ '../views/landing/ChooseEventDetails.vue'),
  },
  {
    path: '/admin',
    name: 'site-admin',
    beforeEnter: enforceAdminPermissions,
    component: () => import(/* webpackChunkName: "site-admin" */ '../views/site-admin/SiteAdminShell.vue'),
    redirect: '/admin/events',
    children: [
      { path: 'events', name: 'site-admin-events', component: () => import(/* webpackChunkName: "site-admin-events" */ '../views/site-admin/events/AdminListEvent.vue') },
      { path: 'events/new', name: 'site-admin-events-item-new', component: () => import(/* webpackChunkName: "site-admin-events" */ '../views/site-admin/events/AdminConfigureEvent.vue') },
      { path: 'events/:id', name: 'site-admin-events-item-edit', component: () => import(/* webpackChunkName: "site-admin-events" */ '../views/site-admin/events/AdminConfigureEvent.vue'), props: (route) => ({ entityId: route.params.id })},
      { path: 'users', name: 'site-admin-users', component: () => import(/* webpackChunkName: "site-admin-users" */ '../views/site-admin/users/AdminListUsers.vue') },
      { path: 'permissions', name: 'site-admin-permissions', component: () => import(/* webpackChunkName: "site-admin-permissions" */ '../views/site-admin/permissions/AdminListPermissions.vue') },
      { path: 'theme', name: 'site-admin-theme', component: () => import(/* webpackChunkName: "site-admin-theme" */ '../views/site-admin/theme/AdminConfigureTheme.vue') },
      { path: 'insights', name: 'site-admin-insights', component: () => import(/* webpackChunkName: "site-admin-insights" */ '../views/site-admin/insights/AdminListInsights.vue') },
      { path: 'settings', name: 'site-admin-settings', component: () => import(/* webpackChunkName: "site-admin-settings" */ '../views/site-admin/settings/AdminConfigureSettings.vue') },
      // TEST ROUTES -- TO BE REMOVED
      { path: 'theatre', name: 'site-admin-theatre', component: () => import(/* webpackChunkName: "site-admin-theatre" */ '../views/site-admin/Theatre.vue'), meta: { breadcrumb: 'theatre' }, },
      { path: 'video-aframe', name: 'site-admin-video-aframe', component: () => import(/* webpackChunkName: "site-admin-video-aframe" */ '../views/site-admin/VideoAframe.vue'), meta: { breadcrumb: 'VideoAframe' }, },
      { path: 'pdf-aframe', name: 'pdf', component: () => import(/* webpackChunkName: "pdf" */ '../views/in-event/PdfAframe.vue') },
      { path: 'pdf-booth', name: 'pdf-booth', component: () => import(/* webpackChunkName: "pdf" */ '../views/in-event/PdfAframeBooth.vue') }
    ]
  },
  {
    path: '/admin/:event',
    name: 'event-admin',
    beforeEnter: enforceAdminPermissions,
    meta: { requiresRouteAccessCheck: true },
    component: () => import(/* webpackChunkName: "event-admin" */ '../views/event-admin/EventAdminShell.vue'),
    redirect: '/admin/:event/details',
    children: [
      { path: 'details', name: 'event-admin-details', component: () => import(/* webpackChunkName: "event-admin-details" */ '../views/site-admin/events/AdminConfigureEvent.vue'), props: true },
      { path: 'theme', name: 'event-admin-theme', component: () => import(/* webpackChunkName: "event-admin-theme" */ '../views/event-admin/theme/EventAdminConfigureTheme.vue') },
      { path: 'sessions', name: 'event-admin-calendar-events', component: () => import(/* webpackChunkName: "event-admin-calendar-events" */ '../views/event-admin/calendar-events/AdminListCalendarEvent.vue') },
      { path: 'sessions/new', name: 'event-admin-calendar-events-item-new', component: () => import(/* webpackChunkName: "event-admin-calendar-events" */ '../views/event-admin/calendar-events/AdminConfigureCalendarEvent.vue'), meta: { breadcrumb: 'New session' } },
      { path: 'sessions/:id', name: 'event-admin-calendar-events-item-edit', component: () => import(/* webpackChunkName: "event-admin-calendar-events" */ '../views/event-admin/calendar-events/AdminConfigureCalendarEvent.vue'), meta: { breadcrumb: 'Edit session' }, props: (route) => ({ entityId: route.params.id }) },
      { path: 'locations', name: 'event-admin-locations-list', component: () => import(/* webpackChunkName: "event-admin-locations-list" */ '../views/event-admin/locations/EventAdminListLocationLayouts.vue'), meta: { breadcrumb: 'Locations' } },
      { path: 'locations/new/:type', name: 'event-admin-locations-new',  component: () => import(/* webpackChunkName: "event-admin-locations-new" */ '../views/event-admin/locations/EventAdminNewLocationLayout.vue'), meta: { breadcrumb: 'New layout' }, props: (route) => ({ locationType: route.params.type }) },
      { path: 'locations/edit/:type/:id', name: 'event-admin-locations-model-edit',  component: () => import(/* webpackChunkName: "event-admin-locations-model-edit" */ '../views/event-admin/locations/EventAdminNewLocationLayout.vue'), meta: { breadcrumb: 'Edit layout Model' }, props: (route) => ({ locationType: route.params.type, locationLayoutId: route.params.id }) },
      { path: 'locations/:id', name: 'event-admin-locations-edit',  component: () => import(/* webpackChunkName: "event-admin-locations-edit" */ '../views/event-admin/locations/EventAdminEditLocationLayout.vue'), meta: { breadcrumb: 'Edit layout' }, props: (route) => ({ layoutId: route.params.id }) },
      { path: 'booths', name: 'event-admin-booths', component: () => import(/* webpackChunkName: "event-admin-booths" */ '../views/event-admin/booths/EventAdminListBooths.vue'), meta: { breadcrumb: 'Exhibit configurator' } },
      { path: 'booths/new', name: 'event-admin-booths-new', component: () => import(/* webpackChunkName: "event-admin-booths" */ '../views/event-admin/booths/EventAdminListBoothModels.vue'), meta: { breadcrumb: 'New Booth' } },
      { path: 'booths/:id', name: 'event-admin-booths-item',  component: () => import(/* webpackChunkName: "event-admin-booths-item" */ '../views/event-admin/booths/EventAdminListBoothModels.vue'), meta: { breadcrumb: 'Edit Exhibit Model' }, props: (route) => ({ entityId: route.params.id }) },
      { path: 'booth/:id', name: 'event-admin-booth-item', component: () => import(/* webpackChunkName: "event-admin-booths" */ '../views/event-admin/booths/EventAdminConfigureBooth.vue'), meta: { breadcrumb: 'Edit Exhibit' }, props: (route) => ({ entityId: route.params.id, isNewBooth: route.params.isNewBooth  }) },
      { path: 'achievements', name: 'event-admin-achievements', component: () => import(/* webpackChunkName: "site-admin-achievements" */ '../views/event-admin/achievements/EventAdminListAchievements.vue') },
      { path: 'videos', name: 'event-admin-videos', component: () => import(/* webpackChunkName: "site-admin-videos" */ '../views/event-admin/videos/EventAdminListVideos.vue') },
      { path: 'videos/new', name: 'event-admin-video-item-new', component: () => import(/* webpackChunkName: "site-admin-videos" */ '../views/event-admin/videos/EventAdminConfigureVideo.vue'), meta: { breadcrumb: 'New Video' }, },
      { path: 'videos/:id', name: 'event-admin-video-item-edit', component: () => import(/* webpackChunkName: "site-admin-videos" */ '../views/event-admin/videos/EventAdminConfigureVideo.vue'), meta: { breadcrumb: 'Edit Video' }, props: (route) => ({ entityId: route.params.id }) },
      { path: 'resources', name: 'event-admin-resources', component: () => import(/* webpackChunkName: "site-admin-resources" */ '../views/event-admin/resources/EventAdminListResources.vue'), meta: { breadcrumb: 'Resources' }, },
      { path: 'resources/new', name: 'event-admin-resources-new', component: () => import(/* webpackChunkName: "site-admin-resources" */ '../views/event-admin/resources/EventAdminConfigureResources.vue'), meta: { breadcrumb: 'New Resource' }, },
      { path: 'resources/:id', name: 'event-admin-resources-edit', component: () => import(/* webpackChunkName: "site-admin-resources" */ '../views/event-admin/resources/EventAdminConfigureResources.vue'), meta: { breadcrumb: 'Edit Resource' }, props: (route) => ({ entityId: route.params.id }) },
      { path: 'team', name: 'event-admin-team', component: () => import(/* webpackChunkName: "event-admin-team" */ '../views/event-admin/people/AdminListPeople.vue'), meta: { breadcrumb: 'All users' } },
      { path: 'team/new', name: 'event-admin-team-item-new', component: () => import(/* webpackChunkName: "event-admin-team" */ '../views/event-admin/people/AdminCreatePeople.vue'), meta: { breadcrumb: 'New Person' } },
      { path: 'team/:id', name: 'event-admin-team-item-edit', component: () => import(/* webpackChunkName: "event-admin-team" */ '../views/event-admin/people/AdminEditPeople.vue'), meta: { breadcrumb: 'Edit Person' } },
      { path: 'insights', name: 'event-admin-insights', component: () => import(/* webpackChunkName: "event-admin-insights" */ '../views/event-admin/insights/EventAdminListInsights.vue') },
      { path: 'pages', name: 'event-admin-pages', component: () => import(/* webpackChunkName: "event-admin-pages" */ '../views/event-admin/pages/EventAdminListPages.vue') },
      { path: 'pages/new', name: 'event-admin-page-item-new', component: () => import(/* webpackChunkName: "event-admin-pages" */ '../views/event-admin/pages/EventAdminConfigurePage.vue'), meta: { breadcrumb: 'New Page' }, },
      { path: 'pages/:id', name: 'event-admin-page-item-edit', component: () => import(/* webpackChunkName: "event-admin-pages" */ '../views/event-admin/pages/EventAdminConfigurePage.vue'), meta: { breadcrumb: 'Edit Page' }, props: (route) => ({ entityId: route.params.id }) },
      { path: 'welcomemessage', name: 'event-admin-welcome-message', component: () => import(/* webpackChunkName: "event-admin-insights" */ '../views/event-admin/welcome-message/AdminConfigureWelcomeMessage.vue'), meta: { breadcrumb: 'Welcome Message' } },
      { path: 'smart-content-config', name: 'event-admin-smart-content-config', component: () => import(/* webpackChunkName: "event-admin-smart-content" */ '../components/configurators/SmartContentConfigurator.vue') },
      { path: 'smart-content', name: 'event-admin-smart-content', component: () => import(/* webpackChunkName: "event-admin-smart-content" */ '../views/event-admin/content/EventAdminListContent.vue') },
      { path: 'smart-content/new', name: 'event-admin-smart-content-new', component: () => import(/* webpackChunkName: "event-admin-smart-content" */ '../views/event-admin/content/EventAdminConfigureSmartContent.vue') },
      { path: 'smart-content/:id', name: 'event-admin-smart-content-edit', component: () => import(/* webpackChunkName: "event-admin-smart-content" */ '../views/event-admin/content/EventAdminEditSmartContent.vue'), meta: { breadcrumb: 'Edit Content' }, props: (route) => ({ entityId: route.params.id }) }
    ]
  },
  {
    path: '/:event',
    name: 'event-home',
    beforeEnter: enforceEventPermissions,
    meta: { requiresRouteAccessCheck: true },
    component: () => import(/* webpackChunkName: "in-event" */ '../views/in-event/InEventShell.vue'),
    redirect: '/:event/home',
    children: [
      { path: 'home', beforeEnter: enforceEventMode, name: 'event-landing', component: () => import(/* webpackChunkName: "in-event-auditorium" */ '../views/in-event/Home.vue'), },
      { path: 'auditorium', beforeEnter: enforceEventMode, name: 'event-auditorium', component: () => import(/* webpackChunkName: "in-event-auditorium" */ '../views/in-event/auditorium/Auditorium.vue'), meta: { breadcrumb: 'Sessions' }, },
      { path: 'auditorium/event/:item', beforeEnter: enforceEventMode, name: 'event-auditorium-item', component: () => import(/* webpackChunkName: "in-event-auditorium" */ '../views/in-event/EventsDetails.vue') },
      { path: 'auditorium/event/:item/playback', beforeEnter: enforceEventMode, name: 'event-auditorium-item-playback', component: () => import(/* webpackChunkName: "in-event-auditorium" */ '../views/in-event/EventVideoPlayback.vue') },
      { path: 'hub', beforeEnter: enforceEventMode, name: 'event-hub', component: () => import(/* webpackChunkName: "in-event-hub" */ '../views/in-event/SceneLocation.vue'), meta: { breadcrumb: 'Hub' }, props: (route) => ({ locationLayoutId: route.params.id }) },
      { path: 'hub/:id', beforeEnter: enforceEventMode, name: 'event-hub-item', component: () => import(/* webpackChunkName: "in-event-hub" */ '../views/in-event/SceneLocation.vue'), meta: { breadcrumb: 'Hub' }, props: (route) => ({ locationLayoutId: route.params.id }) },
      { path: 'conferencefloor/list', beforeEnter: enforceEventMode, name: 'event-conferencefloor-list', component: () => import('../views/in-event/ConferenceFloorList.vue'), meta: { breadcrumb: 'Exhibits' }, props: (route) => ({ floormapLayoutId: route.params.id }) },
      { path: 'conferencefloor/list/:boothId', beforeEnter: enforceEventMode, name: 'event-conferencefloor-list-booth', component: () => import('../views/in-event/ConferenceFloorListItem.vue'), meta: { breadcrumb: 'Exhibit' }, props: (route) => ({ boothId: route.params.boothId }) },
      { path: 'conferencefloor/:id/list', beforeEnter: enforceEventMode, name: 'event-conferencefloor-item-list', component: () => import('../views/in-event/ConferenceFloorList.vue'), meta: { breadcrumb: 'Exhibits' }, props: (route) => ({ floormapLayoutId: route.params.id }) },
      { path: 'conferencefloor/:id/list/:boothId', beforeEnter: enforceEventMode, name: 'event-conferencefloor-item-list-booth', component: () => import('../views/in-event/ConferenceFloorListItem.vue'), meta: { breadcrumb: 'Booth' }, props: (route) => ({ boothId: route.params.boothId }) },
      { path: 'conferencefloor/meet/:boothId', beforeEnter: enforceEventMode, name: 'event-conferencefloor-booth-meeting', component: () => import(/* webpackChunkName: "in-event-conferencefloor" */ '../views/in-event/BoothMeetingIframe.vue'), props: (route) => ({ boothId: route.params.boothId }), meta: { breadcrumb: 'Booth Meeting' }  },
      { path: 'swagbag', beforeEnter: enforceEventMode, name: 'event-swagbag', component: () => import(/* webpackChunkName: "in-event-swagbag" */ '../views/in-event/Swagbag.vue') },
      { path: 'leaderboard', beforeEnter: enforceEventMode, name: 'event-leaderboard', component: () => import(/* webpackChunkName: "in-event-leaderboard" */ '../views/in-event/Leaderboard.vue') },
      { path: 'leaderboard/prizes', beforeEnter: enforceEventMode, name: 'event-leaderboard-prizes', component: () => import(/* webpackChunkName: "in-event-leaderboard" */ '../views/in-event/Prizes.vue') },
      { path: 'myprogress', beforeEnter: enforceEventMode, name: 'event-myprogress', component: () => import(/* webpackChunkName: "in-event-myprogress" */ '../views/in-event/MyProgress.vue'), meta: { breadcrumb: 'My Progress' } },
      { path: 'gettingstarted', beforeEnter: enforceEventMode, name: 'event-gettingstarted', component: () => import(/* webpackChunkName: "in-event-gettingstarted" */ '../views/in-event/GettingStarted.vue'), meta: { breadcrumb: 'Getting Started' } },
      { path: 'videos', beforeEnter: enforceEventMode, name: 'event-videos', component: () => import(/* webpackChunkName: "in-event-videos" */ '../views/in-event/Videos.vue') },
      { path: 'resources', beforeEnter: enforceEventMode, name: 'event-resources', component: () => import(/* webpackChunkName: "in-event-resources" */ '../views/in-event/Resources.vue') },
      { path: 'activities', beforeEnter: enforceEventMode, name: 'event-activities', component: () => import(/* webpackChunkName: "in-event-activities" */ '../views/in-event/Activities.vue') },
      { path: 'page/:pageId', beforeEnter: enforceEventMode, name: 'dynamic-page', component: () => import(/* webpackChunkName: "in-event-activities" */ '../views/in-event/DynamicPage.vue'), meta: { breadcrumb: 'Dynamic Page' } }
    ]
  },
  { path: '*', redirect: '/choose-event', }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, _from, next) => {
  next();
  if (to.params && to.params.event && to.name) {
    const eventType = window && window.innerWidth < 1264 ? 'PAGEVIEW_MOBILE' : 'PAGEVIEW';
    const formattedPageViewDate = new Date().toISOString().split('T')[0];
    store('dispatch')(TRIGGER_TRACKABLE_EVENT, { eventSlug: to.params.event, trackableEvent: { type: eventType, entityIds: [formattedPageViewDate, to.name] }});
    store('commit')(SET_IS_ROUTE_LOADING, true);
  }
});

router.afterEach(() => {
  // need a timeout or mutation doesnt always work
  setTimeout(() => store('commit')(SET_IS_ROUTE_LOADING, false), 250);
});

router.beforeResolve((to, _from, next) => {
  if (to.matched.some(m => m.meta.requiresRouteAccessCheck)) {
    if (!store('state') || !store('state').user || !store('state').user.userData) { return false; }
    if (!isEventAdmin(store('state').user.userData, to.params.event)
      && !hasEventAccess(store('state').user.userData, to.params.event)
      && !hasRouteAccess(store('state').user.userData, to)
    ) { return false; }
  }

  // TODO: refactor mixpanel completely
  if (store('state') && store('state').user && store('state').user.userData) {
    if (!MixpanelEvents.PAGE) { console.warn('Mixpanel::dev_error'); }
    else {
      try {
        const eventName = to.params && to.params.event ? to.params && to.params.event : 'none';
        const mixPanelDataObject = { ...MixpanelCreateDataObject(eventName, store('state').user.userData), [MixpanelEvents.PAGE]: to.fullPath };
        if (!mixPanelDataObject) { console.warn('Mixpanel::dev_error', mixPanelDataObject); }
        else { Vue.prototype._mixpanel.track(MixpanelEvents.PAGE, mixPanelDataObject); }
      } catch (e) { console.warn('Mixpanel::general_error', e); }
    }
  }

  return next();
});

export default router;
