import { isURL } from 'validator';

export default [
  {
    formFields: [
      // {
      //   title: 'Thumbnail Image',
      //   type: 'image',
      //   property: 'thumbnailFileId',
      //   value: null,
      //   caption: '.png or.jpg, 500px X 200px',
      //   options: {
      //     aspectRatio: 1/1,
      //     width: 100,
      //     placeholderColor: '#e5e5e5',
      //     placeholderText: '',
      //     cropperSettings: {
      //       title: 'Thumbnail Image',
      //       aspectRatio: 1/1,
      //     }
      //   }
      // },
      {
        title: 'URL name',
        type: 'text',
        property: 'title',
        value: '',
        counter: 150,
        rules: [(v) => !!v || 'URL name is required', (v) => v.length <= 150 || 'URL name must be 150 characters or less'],
        required: true
      },
      {
        title: 'Description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      },
      {
        title: 'URL',
        type: 'text',
        property: 'url',
        value: '',
        rules: [(v) => isURL(v) || 'URL is required'],
        required: true
      },
    ]
  }
];
