import Vue from 'vue';
import { dateUtils, getCalendarEventState } from '@/utils/date';
import { getStaticAssetUrl } from '@/utils/files';
import {
  GET_ALL_CALENDAR_EVENTS,
  GET_UPCOMING_CALENDAR_EVENTS,
  GET_CALENDER_EVENT,
  CREATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT,
  SET_CALENDAR_EVENTS,
  DELETE_CALENDAR_EVENT,
  SET_SPEAKERS,
  GET_SPEAKERS,
  ASSIGN_SPEAKER,
  UNASSIGN_SPEAKER,
  SET_FILTERED_ELEMENT,
  GET_FILTERED_ELEMENT,
} from './types';
import { DELETE_PERSON } from '../people/types';

const csamPastSessionLinks = {
  '2ff34353-2b05-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/e722bc2c-b4a4-420a-93e0-114963e11d17',
  'da7eeedb-2b05-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/9b277dad-3435-4659-86cc-dcde3cc7d6aa',
  'd6265bf5-2b06-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/47baa37a-e24f-4dfb-ac5c-d7ed6fcab0da',
  '2fbd14ac-2b07-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/0d668113-333a-4cc2-a5db-61cd5693617a',
  'e5c3f29a-2b07-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/d0adf94c-c0d0-418f-9fbb-6158ac9ab787',
  '2abd5fe7-2b0a-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/1548261d-a63c-4921-8589-cf71fd21b9a1',
  'd8035835-2b0a-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/bfde770d-a1aa-4a4d-a874-31937a892e78',
  '7d73c939-2b0b-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/f477d5a4-a646-4922-a98f-1e2aa0c25846',
  'd19c1e00-2b0c-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/fdd26344-38cc-4122-a584-b3eb95862f09',
  '0db2db2c-2b0c-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/03ac1dfe-d862-417a-a303-30f722acd4e1',
  '09f805ad-2b0e-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/5718e602-74d4-4acf-85b5-d10f934035d1',
  'a8def15f-2d6e-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/c310de7e-cc5f-4a98-b447-fbbaf92f8c63',
  'ea503826-2d7f-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/3ed00aec-c73d-4a9c-8787-9ded1608348e',
  'dd5e7cd1-2d6e-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/3ccd1f74-a6e6-4c8b-adf6-549d88a1c1a7',
  'b18af32f-2d6f-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/70783f07-a9d6-41d6-a5eb-e6bc1f35f717',
  '960221e2-2d70-11ed-a39d-2309c4bb5c9c': 'https://videosat.ford.com/#/videos/118fc20f-1f90-4f37-855d-9b92ef7f2d40',
  '37e18b4e-4bb7-11ed-a520-1f78fe90d57d': 'https://videosat.ford.com/#/videos/84ef3436-a094-4e1c-9508-8c5b4ee22b3e',
};

const getPlaybackUrl = (id) => {
  return csamPastSessionLinks[id];
};

const backwardsCompatibilityMap = (calendarEvent) => {
  const newEvent = {
    ...calendarEvent,
    eventDescription: calendarEvent.description,
    startDate: new Date(calendarEvent.startDate),
    endDate: new Date(calendarEvent.endDate),
    start: new Date(calendarEvent.startDate),
    end: new Date(calendarEvent.endDate),
    time: `${dateUtils.getTime(calendarEvent.startDate)} - ${dateUtils.getTime(calendarEvent.endDate)} ${dateUtils.getTimeZone(calendarEvent.startDate)}`,
    date: dateUtils.getDate(calendarEvent.endDate),
    type: getCalendarEventState(calendarEvent),
    speakers: [],
    isGlobal: false,
  };
  newEvent.eventSlug == 'csam22' ? newEvent.playbackUrl = getPlaybackUrl(newEvent.id) : null; 
  newEvent.type = getCalendarEventState(newEvent);
  newEvent.timed = true;
  return newEvent;
};

const backwardsCompatibilityMapSpeaker = (speaker, calEvent) => {
  const newSpeaker = {
    ...speaker,
    alt: speaker.name, 
    src: speaker.avatarFileId ? getStaticAssetUrl(speaker.avatarFileId) : ``,
    role: speaker.headline,
    start: new Date(calEvent.startDate)
  };
  return newSpeaker;
};

export default {
  state: () => ({
    calendarEvents: [],
    speakers: [],
    filteredElement: {
      value: ''
    },
  }),
  mutations: {
    [SET_CALENDAR_EVENTS](state, value) {
      state.calendarEvents = value;
    },
    [SET_SPEAKERS](state, value) {
      state.speakers = value;
    },
    [SET_FILTERED_ELEMENT](state, value) {
      state.filteredElement = {
        value: value
      };
    },
  },
  actions: {
    [GET_ALL_CALENDAR_EVENTS]({ commit }, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/calendar_events`).then((response) => {
        if (!response || !response.data || !Array.isArray(response.data)) {
          commit(SET_CALENDAR_EVENTS, []);
          return [];
        } else {
          /* 
          TEMPORARY IMPLEMENTATION 
          TO DO: (FORD-1031)
          The call will in the future, return the speakers along with each calendar event (FORD-1031) but
          for now we will have to make a separate api call for each calendar event
          */
          let formattedCalendarEvents = response.data.map(backwardsCompatibilityMap);
          formattedCalendarEvents = formattedCalendarEvents.filter((ce) => !ce.isDeleted);
          return Promise.all(formattedCalendarEvents.map((calEvent)=> {
            return Vue.axios.get(`/api/${eventSlug}/calendar_events/${calEvent.id}/speakers`).then((response) => {
              return response;
            });
          }))
            .then(function(result) {
              const formattedCalendarEventsAddSpeakers = formattedCalendarEvents.map((calEvent, i) => {
                const formattedSpeakers = result[i].data.map( speaker => backwardsCompatibilityMapSpeaker(speaker, calEvent) );
                return {
                  ...calEvent,
                  speakers: formattedSpeakers
                };
              });                  
              commit(SET_CALENDAR_EVENTS, formattedCalendarEventsAddSpeakers);
              return formattedCalendarEventsAddSpeakers;
            })
            .catch( (err) => console.log(err));
        }
      });
    },
    [GET_UPCOMING_CALENDAR_EVENTS]({ commit }, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/calendar_events/upcoming`).then((response) => {
        if (!response || !response.data || !Array.isArray(response.data)) {
          return [];
        } else {
          /* 
          TEMPORARY IMPLEMENTATION 
          TO DO: (FORD-1031)
          The call will in the future, return the speakers along with each calendar event (FORD-1031) but
          for now we will have to make a separate api call for each calendar event
          */
          const formattedCalendarEvents = response.data.map(backwardsCompatibilityMap);
          return Promise.all(formattedCalendarEvents.map((calEvent)=> {
            return Vue.axios.get(`/api/${eventSlug}/calendar_events/${calEvent.id}/speakers`).then((response) => {
              return response;
            });
          }))
            .then(function(result) {
              const formattedCalendarEventsAddSpeakers = formattedCalendarEvents.map((calEvent, i) => {
                const formattedSpeakers = result[i].data.map( speaker => backwardsCompatibilityMapSpeaker(speaker, calEvent) );
                return {
                  ...calEvent,
                  speakers: formattedSpeakers
                };
              });                  
              return formattedCalendarEventsAddSpeakers;
            })
            .catch( (err) => console.log(err));
        }
      });
    },
    [GET_CALENDER_EVENT](_context, { eventSlug, id }) {
      return Vue.axios.get(`/api/${eventSlug}/calendar_events/${id}`).then((response1) => {
        if (!response1 || !response1.data) {
          return null;
        }
        const formattedCalendarEvent = backwardsCompatibilityMap(response1.data);
        return Vue.axios.get(`/api/${eventSlug}/calendar_events/${id}/speakers`).then((response2) => {
          const formattedSpeakers = response2.data.map( speaker => backwardsCompatibilityMapSpeaker(speaker, formattedCalendarEvent) );
          const formattedCalendarEventAddSpeakers = {
            ...formattedCalendarEvent,
            speakers: formattedSpeakers
          };
          return formattedCalendarEventAddSpeakers;
        });
      }); 
    },
    [CREATE_CALENDAR_EVENT](_context, { eventSlug, calendarEvent }) {
      return Vue.axios.post(`/api/${eventSlug}/calendar_events`, calendarEvent).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [DELETE_CALENDAR_EVENT](_context, { eventSlug, calendarEventId }) {
      return Vue.axios.delete(`/api/${eventSlug}/calendar_events/${calendarEventId}`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [UPDATE_CALENDAR_EVENT](_context, { eventSlug, id, calendarEvent }) {
      return Vue.axios.put(`/api/${eventSlug}/calendar_events/${id}`, calendarEvent).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [GET_SPEAKERS]({ commit }, { eventSlug, calendarEventId }) {
      return Vue.axios.get(`/api/${eventSlug}/calendar_events/${calendarEventId}/speakers`).then((response) => {       
        if (!response || !response.data) {
          commit(SET_SPEAKERS, null);
          return null;
        } else {
          commit(SET_SPEAKERS, response.data );
          return response.data;
        }
      });
    },
    [ASSIGN_SPEAKER]({ commit }, { eventSlug, calendarEventId, personId }) {
      if (!eventSlug || !personId || !calendarEventId) { return console.log('missing data in assign'); }
      return Vue.axios.put(`/api/${eventSlug}/calendar_events/${calendarEventId}/speakers/${personId}`).then((response) => {    
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [UNASSIGN_SPEAKER]({ commit, dispatch }, { eventSlug, calendarEventId, personId }) {
      if (!eventSlug || !calendarEventId || !personId) { return console.log('missing data in unassign'); }
      return Vue.axios.delete(`/api/${eventSlug}/calendar_events/${calendarEventId}/speakers/${personId}`).then((response) => {    
        if (!response || !response.data) {
          return null;
        } else {
          if (response.data.personId){
            dispatch(DELETE_PERSON, { eventSlug: eventSlug, id: response.data.personId})
              .then((response2) => response2)
              .catch((e) => console.log(e));
          }
          return response.data;
        }
      });
    },
    [GET_FILTERED_ELEMENT]({commit}, filteredElement ) {
      if (filteredElement) {
        commit(SET_FILTERED_ELEMENT, filteredElement);
        return filteredElement;
      } else {
        commit(SET_FILTERED_ELEMENT, '');
        return '';
      }
    }
    
  },
};
