import AFRAME from 'aframe';
import { getUploadcareStaticAssetUrl } from '@/utils/files';
import { attachSmartContentProximityEvents, drawSmartContent, getContentPlaneDimensionsFromFixedMesh } from '@/utils/3d';

const textureLoader = new AFRAME.THREE.TextureLoader();

const schema = {
  smartContent: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  meta: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  target: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    },
    default: false
  }
};

const init = async function () {
  if (!this.data.target) {
    // TODO: without target + with coords, place into scene at specified position/rotation
    // TODO: without target + without coords, place into scene at 0 0 0 and apply auto-scaling
    return;
  }

  const fixedMesh = this.data.target;
  const texture = await textureLoader.loadAsync(getUploadcareStaticAssetUrl(this.data.smartContent.content.file_id, '-/preview/800x800/-/format/auto/-/quality/smart/'));
  texture.encoding = AFRAME.THREE.sRGBEncoding;
  
  // create a bounding box from the fixed mesh, used to determine its dimensions + world position
  const meshBoundingBox = new AFRAME.THREE.Box3().setFromObject(fixedMesh);
  const smartContentDimensions = getContentPlaneDimensionsFromFixedMesh(meshBoundingBox, { width: texture.image.width, height: texture.image.height });

  // get the center point of the fixed mesh, so that the plane can be repositioned to it
  const meshCenter = new AFRAME.THREE.Vector3();
  meshBoundingBox.getCenter(meshCenter);

  // build the smart content panels and remove the original mesh
  const scContainerEl = drawSmartContent(meshCenter, this.data.meta, this.data.smartContent, smartContentDimensions, { texture });
  fixedMesh.removeFromParent();

  this.el.append(scContainerEl);
  this.smartContentPlaneEl = scContainerEl;

  // attach any proximity events once the element has been added to the DOM
  attachSmartContentProximityEvents(scContainerEl);
};

const remove = function () {
  if (this.smartContentPlaneEl) { this.el.remove(this.smartContentPlaneEl); }
};

// TODO: move all below to a threejs utils file

/**
 * Given a mesh with fixed dimensions and a texture (image) with a fixed aspect ratio, creates a plane scaled
 * to fit the mesh with the specified texture.
 * 
 * Scales the image as large as possible within its container without cropping or stretching the image.
 * 
 * Works similarly to CSS background-position: contain
 */
function createTexturedPlaneForFixedMesh(fixedMeshBoundingBox, texture) {
  // depending on rotation, the mesh's width will be defined by its x or z length (whichever is larger)
  const meshWidth = Math.max(fixedMeshBoundingBox.max.x - fixedMeshBoundingBox.min.x, fixedMeshBoundingBox.max.z - fixedMeshBoundingBox.min.z);
  const meshHeight = fixedMeshBoundingBox.max.y - fixedMeshBoundingBox.min.y;

  // determine the scale factor required for the texture to fit the fixed mesh dimensions
  const scale = Math.min(meshWidth/texture.image.width, meshHeight/texture.image.height);

  // create the plane and its components
  const geometry = new AFRAME.THREE.PlaneGeometry(texture.image.width * scale, texture.image.height * scale);
  const material = new AFRAME.THREE.MeshBasicMaterial({ map: texture });
  const mesh = new AFRAME.THREE.Mesh(geometry, material);
  material.side = AFRAME.THREE.DoubleSide;
  return mesh;
}

/**
 * Given a mesh with fixed dimensions and a texture (image) with a fixed aspect ratio, creates a plane scaled
 * to fit the mesh with the specified texture.
 * 
 * Scales the texture (while preserving its ratio) to the smallest possible size to fill the container (that is:
 * both its height and width completely cover the container), leaving no empty space.
 * 
 * Works similarly to CSS background-position: cover
 */
function createUnboundTexuredPlaneForFixedMesh(fixedMeshBoundingBox, texture) {
  // depending on rotation, the mesh's width will be defined by its x or z length (whichever is larger)
  const meshWidth = Math.max(fixedMeshBoundingBox.max.x - fixedMeshBoundingBox.min.x, fixedMeshBoundingBox.max.z - fixedMeshBoundingBox.min.z);
  const meshHeight = fixedMeshBoundingBox.max.y - fixedMeshBoundingBox.min.y;
  
  let repeatX, repeatY;
  texture.wrapS = AFRAME.THREE.RepeatWrapping;
  texture.wrapT = AFRAME.THREE.RepeatWrapping;
  if (texture.image.height < texture.image.width) {
    repeatX = meshWidth * texture.image.height / (meshWidth * texture.image.width);
    repeatY = 1;
    texture.repeat.set(repeatX, repeatY);
    texture.offset.x = (repeatX - 1) / 2 * -1;
  } else {
    repeatX = 1;
    repeatY = meshWidth * texture.image.width / (meshWidth * texture.image.height);
    texture.repeat.set(repeatX, repeatY);
    texture.offset.y = (repeatY - 1) / 2 * -1;
  }
}

/**
 * Given a texture with a fixed aspect ratio, creates a plane to fit it without cropping or distorting it
 */
function createTexturedPlaneAtPosition(texture, planeScale, planePosition, planeRotation) {
  // TODO: implement for freeform smart content

  // TODO: use scale factor method from createTexturedPlaneForFixedMesh instead
  // const localEstimateScale = 20;
  // const getPlaneScale = (textureDimension) => {
  //   if (textureDimension <= localEstimateScale) { return textureDimension; }
  //   return getPlaneScale(textureDimension * 0.75);
  // };

  // const planeHeight = getPlaneScale(texture.image.height);
  // const planeWidth = texture.image.width / (texture.image.height / planeHeight);
  // const planeAspect = planeWidth / planeHeight;
  // const imageAspect = texture.image.width / texture.image.height;
  // const aspect = imageAspect / planeAspect;
  // texture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
  // texture.repeat.y = aspect > 1 ? 1 : aspect;
  // texture.encoding = AFRAME.THREE.sRGBEncoding;
}

export default { schema, init, remove };