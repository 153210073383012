export const CalendarEventStates = {
  INACTIVE_PAST: 'INACTIVE_PAST',
  INACTIVE_FUTURE: 'INACTIVE_FUTURE',
  ACTIVE: 'ACTIVE',
  DEFAULT: 'DEFAULT',
};

export const dateUtils = {
  formatDateRangeAsString(startDate, endDate) {
    if (!startDate || !endDate) { return ''; }
    const localeOptions = { month: 'long', day: 'numeric' };
    const formatTime = (date) => {
      return date.toLocaleString(['en-US'], {
        hour: 'numeric',
        hour12: true,
        minute: '2-digit',
        // ...dateUtils.getMinutesOptional(date)
      });
    };
    if (
      startDate.getDate() == endDate.getDate() &&
      startDate.getMonth() == endDate.getMonth() &&
      startDate.getFullYear() == endDate.getFullYear()
    ) {
      return `${startDate.toLocaleString([], localeOptions)}, ${formatTime(startDate)} - ${formatTime(endDate)}`;
    } else {
      return `${startDate.toLocaleString([], localeOptions)}, ${formatTime(startDate)} - ${endDate.toLocaleString([], localeOptions)}, ${formatTime(endDate)}`;
    }
  },
  getTime(date) {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date)
      .toLocaleTimeString([], options)
      .replace(' AM', 'am')
      .replace(' PM', 'pm');
  },
  getTimeZone(date, timeZoneName = 'short') {
    const options = { day: '2-digit', timeZoneName: timeZoneName };
    return new Date(date).toLocaleDateString([], options).substring(4);
  },
  getDate(date) {
    const options = { month: 'long', day: 'numeric' , weekday: 'long', };
    return new Date(date).toLocaleDateString('en-US', options);
  },
  getDateShort(date) {
    const options = { month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString([], options);
  },
  getMinutesOptional(date) {
    if (date.getMinutes() === 0) {
      return;
    } else {
      return {
        minute: 'numeric',
      };
    }
  },
  getUTCFormat(date){
    return new Date(date).toISOString();
  },
  compareTime(firstDate, secondDate) {
    return firstDate.getTime() > secondDate.getTime();
  },
  getMidday(date) {
    switch (date) {
    case '0 pm':
      return '12 pm';
    case '0:30 pm':
      return '12:30 pm';
    default:
      return date;
    }
  },
};

export function getCalendarEventState(calendarEvent) {
  if (!calendarEvent || !calendarEvent.start || !calendarEvent.end) {
    return CalendarEventStates.DEFAULT;
  }

  const activeEventBufferMs = 15 * 60000;
  //  TODO: For test different types
  // const currentTime = new Date("2021-10-06T12:45:00.000Z");
  const currentTime = new Date();
  const calendarEventStartTime = new Date(calendarEvent.start).getTime();
  const calendarEventEndTime = new Date(calendarEvent.end).getTime();

  // calendar events are treated as "active" both during it and for the 15 mins leading up to it
  if (currentTime >= calendarEventStartTime - activeEventBufferMs && currentTime < calendarEventEndTime) {
    return CalendarEventStates.ACTIVE;
  }
  if (currentTime < calendarEventStartTime) {
    return CalendarEventStates.INACTIVE_FUTURE;
  }
  if (currentTime > calendarEventEndTime) {
    return CalendarEventStates.INACTIVE_PAST;
  }
  return CalendarEventStates.DEFAULT;
}
