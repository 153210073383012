const schema = {
  targets: { type: 'array', default: [] }
};

const init = function () {
  const data = this.data;
  this.el.addEventListener('model-loaded', (e) => {
    this.model = e.detail.model;
    data.targets.forEach((target) => {
      const subset = this.model.getObjectByName(target);
      if (subset) {
        subset.removeFromParent();
      }
    });
  }, { once: true });
};

const update = function () {
  if (!this.model) { return; }
  const data = this.data;
  data.targets.forEach((target) => {
    const subset = this.model.getObjectByName(target);
    if (subset) {
      subset.removeFromParent();
    }
  });
};

export default { schema, init, update };
