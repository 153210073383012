import Vue from 'vue';
import {
  GET_ALL_PEOPLE,
  GET_PERSON,
  CREATE_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON
} from './types.js';

export default {
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    [GET_ALL_PEOPLE](_context, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/people`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [GET_PERSON](_context, { eventSlug, id }) {
      return Vue.axios.get(`/api/${eventSlug}/people/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [CREATE_PERSON](_context, { eventSlug, person }) {
      return Vue.axios.post(`/api/${eventSlug}/people`, person).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [UPDATE_PERSON](_context, { eventSlug, id, person }) {
      return Vue.axios.put(`/api/${eventSlug}/people/${id}`, person).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [DELETE_PERSON](_context, { eventSlug, id }) {
      return Vue.axios.delete(`/api/${eventSlug}/people/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
  },
};
