import '@/plugins/threejs/CopyShader';
import '@/plugins/threejs/Pass';
import '@/plugins/threejs/ShaderPass';
import '@/plugins/threejs/EffectComposer';
import '@/plugins/threejs/RenderPass';
import '@/plugins/threejs/OutlinePass';
import '@/plugins/threejs/GammaCorrectionShader';
import AFRAME from 'aframe';

export const init = function () {
  const scene = this.el.sceneEl.object3D;
  const camera = this.el.sceneEl.camera;
  const renderer = this.el.sceneEl.renderer;
  const render = renderer.render;
  const composer = new AFRAME.THREE.EffectComposer(renderer);
  
  const renderPass = new AFRAME.THREE.RenderPass(scene, camera);
  composer.addPass(renderPass);

  const outlinePass = new AFRAME.THREE.OutlinePass(new AFRAME.THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera);
  outlinePass.edgeStrength = 20;
  outlinePass.edgeGlow = 0;
  outlinePass.edgeThickness = 1;
  composer.addPass(outlinePass);
  window.outlinePass = outlinePass;

  const effectColorSpaceConversion = new AFRAME.THREE.ShaderPass(AFRAME.THREE.GammaCorrectionShader);
  composer.addPass(effectColorSpaceConversion);

  let calledByComposer = false;
  const clock = new AFRAME.THREE.Clock();
  this.composerFunc = function () {
    if (calledByComposer) {
      render.apply(renderer, arguments);
    } else {
      calledByComposer = true;
      composer.render(clock.getDelta());
      calledByComposer = false;
    }
  };
  renderer.render = this.composerFunc;

  renderer.setSize(this.el.clientWidth, this.el.clientHeight);
  composer.setSize(this.el.clientWidth, this.el.clientHeight);
  new ResizeObserver(() => {
    renderer.setSize(this.el.clientWidth, this.el.clientHeight);
    composer.setSize(this.el.clientWidth, this.el.clientHeight);
  }).observe(this.el);
};

export default { init };
