import AFRAME from 'aframe';
import { waitFor3DObject } from '@/utils/3d';

const init = function() {
  waitFor3DObject(this.el).then((object) => {
    if (!object.material) { return console.warn('sr-srgb-texture-encoding::material not found', object); }
    if (!object.material.map) { return console.warn('sr-srgb-texture-encoding::material map not found', object.material); }
    object.material.map.encoding = AFRAME.THREE.sRGBEncoding;
    object.material.needsUpdate = true;
  });
};

export default { init };
