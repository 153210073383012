
export default {
  state: () => ({
    is3DNavigationEnabled: localStorage.getItem('site_prefs_bool_3d_nav_mode')
      ? JSON.parse(localStorage.getItem('site_prefs_bool_3d_nav_mode')) === true
      : true,
    navigationModeMap: [
      { '3d': 'event-hub', '2d': 'event-conferencefloor-list' },
      { '3d': 'event-hub-item', '2d': 'event-conferencefloor-item-list' }
    ],
    default3DRouteName: 'event-hub',
    default2DRouteName: 'event-landing',
    timeLastInteraction: null,
    timeLastClickInteraction: null,
    timeLastMouseMoveInteraction: null,
    timeLastResizeInteraction: null,
    timeLastScrollInteraction: null,
    timeLastKeypressInteraction: null,
  }),
  getters: {
    is3DRoute: (state) => (routeName) => {
      return state.navigationModeMap.some((nmm) => {
        return nmm['3d'] === routeName;
      });
    },
    oppositeModeRouteName: (state) => (routeName, targetModeState) => {
      if (typeof targetModeState === 'undefined') { targetModeState = state.is3DNavigationEnabled; }
      if (targetModeState) {
        const routeName3D = state.navigationModeMap.find((nmm) => nmm['3d'] === routeName);
        return routeName3D ? routeName3D['2d'] : state.default2DRouteName;
      } else {
        const routeName2D = state.navigationModeMap.find((nmm) => nmm['2d'] === routeName);
        return routeName2D ? routeName2D['3d'] : state.default3DRouteName;
      }
    },
    isDomAutoplayPossible(state) {
      // https://developer.chrome.com/blog/autoplay/
      return state.timeLastClickInteraction !== null || state.timeLastKeypressInteraction !== null;
    }
  },
  mutations: {
    setNavigationMode(state, value) {
      if (typeof value === 'undefined') { state.is3DNavigationEnabled = !state.is3DNavigationEnabled; }
      else { state.is3DNavigationEnabled = !!value; }
      localStorage.setItem('site_prefs_bool_3d_nav_mode', state.is3DNavigationEnabled);
    },
  },
  actions: {
    navigationModeEnforcedRedirect({ commit, getters, state }, { to, next }) {
      // this redirect only applies while in-event
      if (!to.params.event) { return next(); }
      if (to.query.skipmodecheck) { return next(); }
      if (to.query.forcemode) { commit('setNavigationMode', getters.is3DRoute(to.name)); return next(); }
      // redirect to the opposite equivalent when trying to navigate to 3D page while in 2D mode (or vice versa)
      if ((state.is3DNavigationEnabled && !getters.is3DRoute(to.name)) ||
        (!state.is3DNavigationEnabled && getters.is3DRoute(to.name))) {
        const oppositeModeRouteName = getters.oppositeModeRouteName(to.name, !state.is3DNavigationEnabled);
        return next({ name: oppositeModeRouteName, params: to.params });
      }
      next();
    },
    async toggleNavigationMode({ commit, getters }, { routeName }) {
      const oppositeModeRouteName = await getters.oppositeModeRouteName(routeName);
      commit('setNavigationMode');
      if (oppositeModeRouteName === routeName) { return null; }
      return oppositeModeRouteName;
    },
    async initActivityTracker({ state }, params) {
      // build a timeout function that updates state activity dates + runs inactivity handler on threshold
      let userActivityTimeout = null;
      const INACTIVE_USER_TIME_THRESHOLD = 5 * 60 * 1000;
      const resetUserActivityTimeout = (varToReset) => {
        const now = new Date();
        state[varToReset] = now;
        state.timeLastInteraction = now;
        clearTimeout(userActivityTimeout);
        userActivityTimeout = setTimeout(() => {
          if (params && params.onInactivityHandler) { params.onInactivityHandler(); }
        }, INACTIVE_USER_TIME_THRESHOLD);
      };

      // build a timeout throttler to improve performance
      let userActivityThrottlerTimeout = null;
      const USER_ACTIVITY_THROTTLER_TIME = 500;
      const userActivityThrottler = (varToReset) => {
        if (!userActivityThrottlerTimeout) {
          userActivityThrottlerTimeout = setTimeout(() => {
            resetUserActivityTimeout(varToReset);
            clearTimeout(userActivityThrottlerTimeout);
            userActivityThrottlerTimeout = null;
          }, USER_ACTIVITY_THROTTLER_TIME);
        }
      };

      // attach event listeners
      // TODO: mousemove fires too often, throttling means other events don't get their timeout refresh triggered
      // window.addEventListener('mousemove', () => { userActivityThrottler('timeLastMouseMoveInteraction'); });
      window.addEventListener('scroll', () => { userActivityThrottler('timeLastScrollInteraction'); });
      window.addEventListener('keydown', () => { userActivityThrottler('timeLastKeypressInteraction'); });
      window.addEventListener('resize', () => { userActivityThrottler('timeLastResizeInteraction'); });
      window.addEventListener('click', () => { userActivityThrottler('timeLastClickInteraction'); });
    }
  },
};
