export default [
  {
    formFields: [
      {
        title: 'Platform Name',
        type: 'text',
        property: 'appName',
        value: '',
        rules: [
          (v) => !!v || 'Platform name is required',
          (v) => v.length >= 5 || 'Platform name must be between 5 and 30 characters',
          (v) => v.length <= 30 || 'Platform name must be between 5 and 30 characters',
        ],
        counter: 30,
        required: true
      },
      {
        title: 'Browser Tab Text',
        type: 'text',
        property: 'tabTitle',
        value: '',
        rules: [
          (v) => !!v || 'Tab title is required',
          (v) => v.length >= 3 || 'Tab title must be between 3 and 30 characters',
          (v) => v.length <= 20 || 'Tab title must be between 5 and 20 characters',
        ],
        counter: 20,
        required: true
      },
      {
        title: 'Navbar Color',
        type: 'color',
        property: 'navbarColor',
        value: '',
        caption: 'The color will be seen on the navigation bar at the top of the screen'
      },
      {
        title: 'Navbar Logo',
        type: 'image',
        property: 'logoImageLightFileId',
        value: null,
        caption: '.png or.jpg, 200px X 34px. Visible in the navbar',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 200/34,
          }
        }
      },
      {
        title: 'Choose Event Page Logo',
        type: 'image',
        property: 'logoImageDarkFileId',
        value: null,
        caption: '.png or.jpg, 1490px X 16px. Visible on the choose event page',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 149/16,
          }
        }
      },
      {
        title: 'Login Page Logo',
        type: 'image',
        property: 'logoThumbImageFileId',
        value: null,
        caption: '.png or.jpg, 200px X 34px. Visible on the login page',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 200/34,
          }
        }
      },
      {
        title: 'Favicon',
        type: 'image',
        property: 'faviconImageFileId',
        value: null,
        caption: '.png or.jpg, 100px X 100px',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 1/1,
          }
        }
      },
      {
        title: 'Login Page Background',
        type: 'image',
        property: 'loginBackgroundImageFileId',
        value: null,
        caption: '.png or.jpg, 960px X 1080px',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 960/1080,
          }
        }
      },
    ]
  }
];
