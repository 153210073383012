<template>
  <div
    v-show="visible"
    :style="`${visible ? 'position: fixed; left: 0; right: 0; height: 100vh; background: rgba(211,211,211,0.5);' : ''}`"
    class="d-flex align-center justify-center"
  >
    <flower-spinner
      v-show="visible"
      :animation-duration="2500"
      :size="70"
      color="#1976D2"
    />
  </div>
</template>

<script>
import { FlowerSpinner } from 'epic-spinners';

export default {
  name: 'Loader',
  components: {
    FlowerSpinner
  },
  data() {
    return {
      visible: false,
    };
  },
  methods:{
    hide() {
      this.visible = false;
      setTimeout(() => this.visible = false, 10000);
    },
    show() {
      this.visible = true;
      setTimeout(() => this.visible = false, 300000);
    },
  }
    
};
</script>

<style>

</style>