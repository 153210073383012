export const MixpanelEvents = {
  // event types * REQUIRED
  URL_OPENED: 'URL opened',
  PAGE: 'Page',
  ENTERED_EVENT: 'Entered event',
  ABOUT_SECTION_OPENED: 'About section opened',
  PROFILE_MENU_OPENED: 'Profile menu opened',
  SESSION_CARD_OPENED: 'Session card opened',
  AUDITORIUM_SEARCH_PERSON: 'Auditorium Search - Person selected',
  AUDITORIUM_SEARCH_SESSION: 'Auditorium Search - Session selected',
  SESSION_ADDED_TO_CALENDAR: 'Session added to calendar',
  LOGIN: 'Log in',
  LOGOUT: 'Log out',
  AUDITORIUM_FILTER_TODAY: `Auditorium Filters - "Today" selected`,
  AUDITORIUM_FILTER_PERIOD_TYPE: 'Auditorium Filters - Period type selected',
  AUDITORIUM_FILTER_MONTH_SWITCH: 'Auditorium Filters - Month switch',
  OPEN_BOOTH_THROUGH_CONF_CARD: 'Booth opened through Conference Floor popup',
  BOOTH_ENTERED: 'Booth entered from 3D room',
  OPEN_BOOTH_CARD_IN_CONFERENCE_FLOOR: 'Booth card opened in Conference Floor',
  BOOTH_NAME: 'Booth name',
  SWAG_DOWNLOADED_FROM_BOOTH: 'Swag downloaded from booth',
  SWAG_DOWNLOADED_FROM_SWAGBAG: 'Swag downloaded from Swag Bag',
  SWAG_REMOVED_FROM_SWAGBAG: 'Swag removed from Swag Bag',
  CALENDAR_EVENT_VISITED: 'Session visited',
  VIDEO_VIEWED: 'Video viewed',
  EXECUTIVE_VIDEO_PLAYED: 'Executive video viewed',
  ACTIVITIES_CLICKED: 'Activities opened',
  RESOURCES_CARD_OPENED: 'Resources card opened',
  ADDED_TO_SWAGBAG: 'Added to Swag Bag',
  // other actions descriptions passed into event
  EVENT: 'Event', // REQUIRED
  USER_NAME: 'User name', // REQUIRED
  USER_EMAIL: 'User email', // REQUIRED
  CALENDAR_EVENT_TITLE: 'Calendar event name',
  CALENDAR_EVENT_NAME_SELECTED: 'Selected event name',
  PERSON_NAME: 'Selected person name',
  PERSON_EMAIL: 'Selected person email',
  PERSON_ROLE: 'Selected person role',
  PERIOD_TYPE: 'Period type',
  VISIBLE_DATE: 'Visible date',
  FILE_URL: 'File url',
  FILE_NAME: 'File name',
  VIDEO_NAME: 'Video name',
  URL: 'URL',
  CARD_URL: 'Card URL',
};

export const MixpanelCreateDataObject = (event, userdata) => {
  if (!event || !userdata || !userdata.emailAddress || !userdata.displayName) { return null; }
  return {
    [MixpanelEvents.EVENT]: event,
    [MixpanelEvents.USER_NAME]: userdata.displayName,
    [MixpanelEvents. USER_EMAIL]: userdata.emailAddress,
  };
};
  