import AFRAME from 'aframe';

let isRunning = false;
const lastPosition = new AFRAME.THREE.Vector3();

export const schema = {
  staticClip: { type: 'string', default: 'IDLE' }
};

const init = function () {
  this.bindMethods();
};

const play = function () {
  this.addEventListeners();
};

const pause = function () {
  this.removeEventListeners();
};

const remove = function () {
  this.removeEventListeners();
};

const bindMethods = function () {
  this.onRunStart = this.onRunStart.bind(this);
  this.onRunEnd = this.onRunEnd.bind(this);
};

const addEventListeners = function () {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl.canvas;

  // Wait for canvas to load.
  if (!canvasEl) {
    sceneEl.addEventListener('render-target-loaded', Function.prototype.bind(this.addEventListeners), this);
    return;
  }

  this.el.addEventListener('keydown:ShiftLeft', this.onRunStart, false);
  this.el.addEventListener('keyup:ShiftLeft', this.onRunEnd, false);
};

const removeEventListeners = function () {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  
  this.el.removeEventListener('keydown:ShiftLeft', this.onRunStart);
  this.el.removeEventListener('keyup:ShiftLeft', this.onRunEnd);
};

const onRunStart = function (evt) {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  if (this.is) { return; }

  isRunning = true;
};

const onRunEnd = function (evt) {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }

  isRunning = false;
};

export const tick = function () {
  const staticAnimationClip = this.data.staticClip || 'IDLE';
  const currentPosition = Object.assign({}, this.el.object3D.position);
  
  setTimeout(() => {
    if (lastPosition.x === currentPosition.x && lastPosition.y === currentPosition.y && lastPosition.z === currentPosition.z) {
      if (staticAnimationClip === 'STAND_TO_SIT') {
        this.el.setAttribute('sr-rig-animation', {
          clip: staticAnimationClip,
          loop: 'once',
          crossFadeDuration: 0.4,
          clampWhenFinished: true,
        });
      } else {
        this.el.setAttribute('sr-rig-animation', {
          clip: staticAnimationClip,
          loop: 'repeat',
          crossFadeDuration: 0.4,
        });
      }
    } else {
      if ( !isRunning ) {
        const playerModelRig = document.getElementById('player-model-entity');
        if (playerModelRig) {
          playerModelRig.setAttribute('movement-controls', Object.assign(playerModelRig.getAttribute('movement-controls'), { enabled: true, speed: 0.2 }));
        }
        this.el.setAttribute('sr-movement-animation', 'staticClip: IDLE;');
        this.el.setAttribute('sr-rig-animation', {
          clip: 'WALKING',
          loop: 'repeat',
          crossFadeDuration: 0.4,
        });
      }
      else {
        const playerModelRig = document.getElementById('player-model-entity');
        if (playerModelRig) {
          playerModelRig.setAttribute('movement-controls', Object.assign(playerModelRig.getAttribute('movement-controls'), { enabled: true, speed: 0.3 }));
        }
        this.el.setAttribute('sr-movement-animation', 'staticClip: IDLE;');
        this.el.setAttribute('sr-rig-animation', {
          clip: 'RUN',
          loop: 'repeat',
          crossFadeDuration: 0.4,
        });
      }
    }
    lastPosition.copy(this.el.object3D.position);
  });
};

export default { schema, init, tick, play, pause, remove, bindMethods, addEventListeners, removeEventListeners, onRunStart, onRunEnd };