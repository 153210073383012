export default [
  {
    formFields: [
      {
        type: 'rating',
        property: 'rating',
        value: 0,
        rules: [(v) =>  v > 0 && v < 6 || 'Please provide a rating' ],
        required: true
      },
      {
        title: 'Tell us about your experience',
        type: 'textarea',
        property: 'feedback',
        value: '',
        rules: [],
        required: false,
        hint: 'All feedback is anonymous',
        noResize: true
      }
    ]
  }
];
