const schema = {
  isMicVisible: { type: 'boolean', default: true },
  isFrameVisible: { type: 'boolean', default: false },
  isUserSpeaking: { type: 'boolean', default: false},
  playerId: { type: 'string', default: '' },
  playerName: { type: 'string', default: '' },
  playerCompany: { type: 'string', default: '' },
  playerRole: { type: 'string', default: '' },
  micImageVideo: {type: 'asset'},
  likeImageVideo: {type: 'asset'},
  micImageLabel: {type: 'asset'},
  likeImageLabel: {type: 'asset'}
};

const init = function () {
  if (this.el.id === 'player-model-entity') { return; }
  const labelPlane = this.el.querySelector('.plane-nametag');
  if (!labelPlane) { return; }

  // create the nametag plane
  labelPlane.setAttribute('geometry', {
    primitive: 'plane',
    width: 1.0,
    height: 0.25
  });
  labelPlane.setAttribute('material', {
    color: this.data.isUserSpeaking ? 'green' : 'black',
    opacity: 0.6,
    transparent: true,
    side: 'front',
    shader: 'flat'
  });
  labelPlane.setAttribute('position', '0 0.1 0');

  // Name, Company, Role appended to plane
  // Name
  const userNameLabel = this.el.querySelector('.nametag-username-label');
  userNameLabel.setAttribute('text', {
    color: 'white',
    width: 1.5,
    wrapCount: 30, 
    value: this.data.playerName,
    shader: 'flat'
  });
  userNameLabel.setAttribute('position', '.3 0.06 0');

  // Company - Role
  const userCompanyRole = this.el.querySelector('.nametag-company-label');
  userCompanyRole.setAttribute('text', {
    color: 'white',
    width: 1.5,
    value: `${this.data.playerCompany} - ${this.data.playerRole}`,
    shader: 'flat'
  });
  userCompanyRole.setAttribute('position', '.3 -0.04 0');

  // Progress Planes - Experience, Network, Learn
  // Experience
  const progressPlaneExperience = this.el.querySelector('.nametag-progress-experience');
  progressPlaneExperience.setAttribute('geometry', {
    primitive: 'plane',
    width: 0.175,
    height: 0.025
  });
  progressPlaneExperience.setAttribute('material', {
    color: '#FDFC0D',
    opacity: 1,
    transparent: false,
    shader: 'flat'
  });
  progressPlaneExperience.setAttribute('position', '-0.41 -0.115 0.01');

  // Network
  const progressPlaneNetwork = this.el.querySelector('.nametag-progress-network');
  progressPlaneNetwork.setAttribute('geometry', {
    primitive: 'plane',
    width: 0.175,
    height: 0.025,
  });
  progressPlaneNetwork.setAttribute('material', {
    color: '#3FA9F5',
    opacity: 1,
    transparent: false,
    shader: 'flat'
  });
  progressPlaneNetwork.setAttribute('position', '-0.235 -0.115 0.01');

  // Learn
  const progressPlaneLearn = this.el.querySelector('.nametag-progress-learn');
  progressPlaneLearn.setAttribute('geometry', {
    primitive: 'plane',
    width: 0.175,
    height: 0.025
  });
  progressPlaneLearn.setAttribute('material', {
    color: '#ED1E79',
    opacity: 1,
    transparent: false,
    shader: 'flat'
  });
  progressPlaneLearn.setAttribute('position', '-0.06 -0.115 0.01');

  // // Like icon
  const likeIcon = this.el.querySelector('.nametag-like-icon');
  likeIcon.setAttribute('src', this.data.likeImageLabel);
  likeIcon.setAttribute('material', {
    side: 'front',
    opacity: 1,
    transparent: true,
    shader: 'flat'
  });

  // Like counter
  const likeCounter = this.el.querySelector('.nametag-like-counter');
  likeCounter.setAttribute('text', {
    color: 'white',
    width: 1.5,
    value: 22
  });
  likeCounter.setAttribute('position', '0.805 0 0.01');

  // Microphone Icon
  const micIcon = this.el.querySelector('.mic-icon-nametag');
  micIcon.setAttribute('visible', this.data.isMicVisible);
  micIcon.setAttribute('src', this.data.micImageLabel);
  micIcon.setAttribute('material', {
    side: 'front',
    opacity: 1,
    transparent: true
  });

  labelPlane.setAttribute('raycaster-interaction-hotspot', { meshLabelText: 'Profile' });
  const nametagClickedEvent = new CustomEvent('on-nametag-clicked', { detail: { playerId: this.data.playerId } });
  labelPlane.addEventListener('click', () => document.dispatchEvent(nametagClickedEvent));

  const videoFrameEl = this.el.querySelector('.networked-video-frame');
  if (!videoFrameEl) { return; }
  const planeMesh = videoFrameEl.getObject3D('mesh');
  planeMesh.traverse(node => {
    if (this.data.isFrameVisible) {
      // Microphone Icon
      const micIcon = this.el.querySelector('.mic-icon-video');
      micIcon.setAttribute('visible', this.data.isMicVisible);
      micIcon.setAttribute('src', this.data.micImageVideo);
      micIcon.setAttribute('material', {
        side: 'front',
        opacity: 1,
        transparent: true
      });

      // Hide nametag labels
      const userNameEl = this.el.querySelector('.nametag')
      userNameEl.setAttribute('visible', false);

      // Show video labels
      const videoFrameEl = this.el.querySelector('.networked-video-frame');
      videoFrameEl.setAttribute('visible', true);

      node.material.transparent = false;
      node.material.opacity = 1;
      
      const labelPlane = this.el.querySelector('.plane-nametag-video');
      labelPlane.setAttribute('geometry', {
        primitive: 'plane',
        width: 1.0,
        height: 0.5
      });
      labelPlane.setAttribute('material', {
        color: 'black',
        opacity: 0.0,
        transparent: true,
        shader: 'flat'
      });
      labelPlane.setAttribute('position', '0 0.1 0');

      // Name, Company, Role appended to plane
      // Name
      const userNameLabel = this.el.querySelector('.nametag-username-label-video');
      userNameLabel.setAttribute('text', {
        color: 'white',
        width: 1.5,
        wrapCount: 30, 
        value: this.data.playerName
      });
      userNameLabel.setAttribute('position', '.25 -0.2 0');

      // Company - Role
      const userCompanyRole = this.el.querySelector('.nametag-company-label-video');
      userCompanyRole.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: `${this.data.playerCompany} - ${this.data.playerRole}`
      });
      userCompanyRole.setAttribute('position', '.25 -0.31 0');

      // Progress Planes - Experience, Network, Learn
      // Experience
      const progressPlaneExperience = this.el.querySelector('.nametag-progress-experience-video');
      progressPlaneExperience.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneExperience.setAttribute('material', {
        color: '#FDFC0D',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneExperience.setAttribute('position', '-0.41 -0.385 0.01');
      // Network
      const progressPlaneNetwork = this.el.querySelector('.nametag-progress-network-video');
      progressPlaneNetwork.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneNetwork.setAttribute('material', {
        color: '#3FA9F5',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneNetwork.setAttribute('position', '-0.235 -0.385 0.01');
      // Learn
      const progressPlaneLearn = this.el.querySelector('.nametag-progress-learn-video');
      progressPlaneLearn.setAttribute('geometry', {
        primitive: 'plane',
        width: 0.175,
        height: 0.025
      });
      progressPlaneLearn.setAttribute('material', {
        color: '#ED1E79',
        opacity: 1,
        transparent: false,
        shader: 'flat'
      });
      progressPlaneLearn.setAttribute('position', '-0.06 -0.385 0.01');
      
      // Like icon
      const likeIcon = this.el.querySelector('.nametag-like-icon-video');
      likeIcon.setAttribute('src', this.data.likeImageVideo);
      likeIcon.setAttribute('material', {
        transparent: true,
        shader: 'flat'
      });
      // Like counter
      const likeCounter = this.el.querySelector('.nametag-like-counter-video');
      likeCounter.setAttribute('text', {
        color: 'white',
        width: 1.5,
        value: 22
      });
      likeCounter.setAttribute('position', '0.805 0 0.0');

      // TODO - Logic for opening the player's profile will go here
      labelPlane.setAttribute('raycaster-interaction-hotspot', { meshLabelText: 'Profile' });
      try {
        labelPlane.addEventListener('click', () => {
          const playerId =  JSON.parse(this.el.getAttribute('data-networked')).user.id;
          const nametagClickedEvent = new CustomEvent('on-nametag-clicked', {
            detail: { playerId } 
          });
          document.dispatchEvent(nametagClickedEvent);
        });
        
      } catch (error) {
        console.log('playerId err', error);
      }
    } else {
      node.material.transparent = true;
      node.material.opacity = 0;

      // Show nametag labels
      const userNameEl = this.el.querySelector('.nametag');
      userNameEl.setAttribute('visible', true);
      
      // Hide video labels
      const videoFrameEl = this.el.querySelector('.networked-video-frame');
      videoFrameEl.setAttribute('visible', false);
    }
  });
};

export default { schema, init, update: init };
