export default [
  {
    formFields: [
      {
        title: 'Page Name',
        type: 'text',
        property: 'name',
        value: '',
        rules: [(v) => !!v || 'Enter a page name'],
        required: true,
      },
      {
        title: 'Home page (requires description and thumbnail)',
        type: 'checkbox',
        property: 'isOnHomepage',
        value: true,
        rules: [],
        required: false,
        helptip: 'Show in the following locations:'
      },
      {
        title: 'Side menu',
        type: 'checkbox',
        property: 'isInSidemenu',
        value: true,
        rules: [],
        required: false
      },
      {
        title: 'Footer',
        type: 'checkbox',
        property: 'isInFooter',
        value: true,
        rules: [],
        required: false,
        classes: 'mb-6'
      },
      {
        title: 'Home page thumbnail (.png or .jpeg, 500px X 200px)',
        caption: 'Max file size: 100kb',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Home page Thumbnail',
            aspectRatio: 5/2,
          }
        },
        type: 'image',
        property: 'homeTileThumbnailId',
        value: null
      },
      {
        title: 'Page description for home page tile',
        type: 'textarea',
        property: 'homeTileThumbnailDescription',
        value: '',
        counter: 200,
        rules: [(v) => v.length <= 200 || 'Max 200 characters'],
        noResize: true
      },
      {
        title: 'Page Content',
        type: 'html',
        property: 'htmlContent',
        value: '',
        rules: [],
        required: false
      },
    ]
  }
];
