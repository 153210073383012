const schema = {
  target: { type: 'selector' },
  distance: { type: 'number', default: 1 },
  color1: { default: '#0000ff' },
  color2: { default: '00ff00' },
  customDataEvent: { default: '{"message": "no custom data provided"}'}
};

const tick = function() {
  const target = this.data.target;
  if (target) {
    //Calc position
    const targetPostion = target.object3D.position;
    const thisPosition = this.el.object3D.position;
    const distance = thisPosition.distanceTo(targetPostion);
    if (
      distance <= this.data.distance &&
      !this.el.classList.contains('selected')
    ) {
      this.el.emit('proximity-event', {
        type: 'selected',
        data: JSON.parse(this.data.customDataEvent)
      });
      this.el.classList.add('selected');
    } else if (
      distance >= this.data.distance &&
      this.el.classList.contains('selected')
    ) {
      this.el.classList.remove('selected');
      this.el.emit('proximity-event', {
        type: 'deselected',
        data: JSON.parse(this.data.customDataEvent)
      });
    }
  }
};

export default { schema, tick };
