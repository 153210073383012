export const isFile = (value) => {
  return 'File' in window && value instanceof File;
};

export const fileToSrcString = (file) => {
  return URL.createObjectURL(file);
};

export const getStaticAssetUrl = (fileId) => {
  if (!fileId) { return null; }
  if (process.env.VUE_APP_AZURE_STORAGE_URL) { return `${process.env.VUE_APP_AZURE_STORAGE_URL}/${fileId}`; }
  return `${process.env.VUE_APP_API_URL}/assets/${fileId}`;
};

export const getInternalAssetUrl = (internalFilePath) => {
  if (!internalFilePath) { return null; }
  return `${process.env.VUE_APP_API_URL}/internal${internalFilePath}`;
};

export const getDynamicFileSrc = (value) => {
  if (typeof value === 'string' && value.length > 0) {
    return getStaticAssetUrl(value);
  } else if (isFile(value)) {
    return fileToSrcString(value);
  }
  return '';
};

export const getUploadcareStaticAssetUrl = (fileId, transformationString = '') => {
  if (!fileId) { return null; }
  return `https://ucarecdn.com/${fileId}/${transformationString}`;
};

export const acceptedImageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];

