import Vue from 'vue';
import Vuex from 'vuex';
import store from '../store';
// import VueNativeSock from 'vue-native-websocket';



Vue.use(Vuex);

const store_ = new Vuex.Store(store);
// let websocketServerHost;
// if(location.protocol === 'https:'){
//     websocketServerHost = process.env.VUE_APP_API_URL.replace(/^https/, 'wss');
// } else {
//     websocketServerHost = process.env.VUE_APP_API_URL.replace(/^http/, 'ws');
// }
// console.log(websocketServerHost);
/*
Vue.use(VueNativeSock, websocketServerHost, { 
    store: store_,
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, 
});
*/
Vue.prototype.$store = store_;
