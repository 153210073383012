export default [
  {
    formFields: [
      {
        title: 'Theme Color',
        type: 'color',
        property: 'navbar.color',
        value: '',
        caption: 'The color will be seen on the navigation bar at the top of the screen'
      },
      {
        title: 'Navbar Logo',
        type: 'image',
        property: 'navbar.logoImageFileId',
        value: null,
        caption: '.png or.jpg, 200px X 34px. Visible in the navbar',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your navbar logo',
            aspectRatio: 200/34,
          }
        }
      },
      {
        title: 'Landing Page Banner Image',
        type: 'image',
        property: 'landing.bannerImageFileId',
        value: null,
        caption: '.png or.jpg, 2000px X 500px. Visible from the choose event landing page',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Landing Page Banner Image',
            aspectRatio: 5/2,
          }
        }
      },
      {
        title: 'Event Homepage Banner Image',
        type: 'image',
        property: 'home.bannerImageFileId',
        value: null,
        caption: '.png or.jpg, 2000px X 500px. Visible from the event homepage',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Event Homepage Banner Image',
            aspectRatio: 5/2,
          }
        }
      },
    ]
  }
];
