export default [
  {
    formFields: [
      {
        title: 'Event name',
        type: 'text',
        property: 'title',
        value: '',
        counter: 30,
        rules: [(v) => !!v || 'Event name is required', (v) => v.length <= 30 || 'Name must be 30 characters or less'],
        required: true
      },
      {
        title: 'Description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      },
      {
        title: 'Organiser',
        type: 'text',
        property: 'organiser',
        value: '',
        counter: 90,
        rules: [(v) => v.length <= 90 || 'Description must be 90 characters or less']
      },
      {
        title: 'Tags',
        type: 'combo',
        property: 'tags',
        value: []
      },
    ]
  },
  {
    classes: ['flex-row'],
    formFields: [
      {
        title: 'Link domain',
        type: 'text',
        property: 'domain',
        value: `${process.env.VUE_APP_API_URL}/`,
        disabled: true,
        skip: true
      },
      {
        title: 'Custom link path',
        type: 'text',
        property: 'slug',
        value: '',
        counter: 30,
        rules: [(v) => !!v || 'Event link is required', (v) => v.length <= 30 || 'Event link must be 30 characters or less', (v) => /^[0-9a-z-]+$/.test(v) || 'Event link must be lowercase and alphanumeric'],
        required: true,
        classes: ['ml-6']
      },
    ]
  },
  {
    classes: ['flex-row'],
    formFields: [
      {
        title: 'Start date',
        type: 'datetime',
        property: 'startDate',
        value: new Date()
      },
      {
        title: 'End date',
        type: 'datetime',
        property: 'endDate',
        value: new Date(),
        classes: ['ml-6']
      }
    ]
  },
  {
    formFields: [
      {
        title: 'Tile Image',
        type: 'image',
        property: 'imageFileId',
        value: null,
        caption: '500px X 200px',
        options: {
          aspectRatio: 1/1,
          width: 100,
          placeholderColor: '#e5e5e5',
          placeholderText: '',
          cropperSettings: {
            title: 'Crop your Tile Image',
            aspectRatio: 5/2,
          }
        }
      }
    ]
  }
];
