export const GET_BOOTH = 'GET_BOOTH';
export const GET_BOOTHS = 'GET_BOOTHS';
export const SET_BOOTHS = 'SET_BOOTHS';
export const SET_BOOTH = 'SET_BOOTH';
export const SET_SELECTED_BOOTH = 'SET_SELECTED_BOOTH';
export const SET_BOOTHS_BY_KEY = 'SET_BOOTHS_BY_KEY';
export const SET_BOOTHS_BY_ID = 'SET_BOOTHS_BY_ID';
export const ADD_BOOTH = 'ADD_BOOTH';
export const EDIT_BOOTH = 'EDIT_BOOTH';
export const DELETE_BOOTH = 'DELETE_BOOTH';
export const SET_BOOTH_TEAM= 'SET_BOOTH_TEAM';
export const GET_BOOTH_TEAM= 'GET_BOOTH_TEAM';
export const GET_BOOTH_TEAM_NEW= 'GET_BOOTH_TEAM_NEW';
export const ASSIGN_PERSON_TO_BOOTH= 'ASSIGN_PERSON_TO_BOOTH';
export const UNASSIGN_FROM_BOOTH_AND_DELETE_PERSON= 'UNASSIGN_FROM_BOOTH_AND_DELETE_PERSON';
export const GET_BOOTH_CONTENT= 'GET_BOOTH_CONTENT';
export const GET_BOOTH_CONTENT_BY_ID= 'GET_BOOTH_CONTENT_BY_ID';
export const CREATE_BOOTH_CONTENT= 'CREATE_BOOTH_CONTENT';
export const UPDATE_BOOTH_CONTENT= 'UPDATE_BOOTH_CONTENT';
export const DELETE_BOOTH_CONTENT= 'DELETE_BOOTH_CONTENT';
export const UPDATE_BOOTH_MODEL_REFERENCE= 'UPDATE_BOOTH_MODEL_REFERENCE';
export const GET_BOOTH_STATUS= 'GET_BOOTH_STATUS';
export const SET_BOOTH_STATUS= 'SET_BOOTH_STATUS';
export const UPDATE_BOOTH_STATUS= 'UPDATE_BOOTH_STATUS';
export const SET_BOOTH_MODELS = 'SET_BOOTH_MODELS';
export const GET_BOOTH_MODELS= 'GET_BOOTH_MODELS';
export const GET_BOOTH_MODEL_BY_ID= 'GET_BOOTH_MODEL_BY_ID';
export const SET_BOOTH_MODEL_BY_ID= 'SET_BOOTH_MODEL_BY_ID';
export const GET_FLOOR_BOOTHS= 'GET_FLOOR_BOOTHS';
export const SET_FLOOR_BOOTHS= 'SET_FLOOR_BOOTHS';
