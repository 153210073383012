import AFRAME from 'aframe';

function disposeNode(node) {
  if (node instanceof AFRAME.THREE.Mesh) {
    if (node.geometry) {
      node.geometry.dispose();
      setTimeout(() => node.geometry = undefined);
    }

    if (node.material) {
      if (node.material instanceof AFRAME.THREE.MeshFaceMaterial || node.material instanceof AFRAME.THREE.MultiMaterial) {
        node.material.materials.forEach(function (mtrl, idx) {
          if (mtrl.map) { mtrl.map.dispose(); }
          if (mtrl.lightMap) { mtrl.lightMap.dispose(); }
          if (mtrl.bumpMap) { mtrl.bumpMap.dispose(); }
          if (mtrl.normalMap) { mtrl.normalMap.dispose(); }
          if (mtrl.specularMap) { mtrl.specularMap.dispose(); }
          if (mtrl.envMap) { mtrl.envMap.dispose(); }

          mtrl.dispose();
          mtrl = undefined;
        });
      } else if (node.material instanceof AFRAME.THREE.Material) {
        if (node.material.map) { node.material.map.dispose(); }
        if (node.material.lightMap) { node.material.lightMap.dispose(); }
        if (node.material.bumpMap) { node.material.bumpMap.dispose(); }
        if (node.material.normalMap) { node.material.normalMap.dispose(); }
        if (node.material.specularMap) { node.material.specularMap.dispose(); }
        if (node.material.envMap) { node.material.envMap.dispose(); }

        node.material.dispose();
        node.material = undefined;
      }
    }
  }
  if (scene) { scene.remove(node); }
}

function disposeHierarchy(node, callback) {
  for (var i = node.children.length - 1; i >= 0; i--) {
    var child = node.children[i];

    disposeHierarchy(child, callback);
    callback(child);
  }
}

let scene = null;

const schema = {
  cacheMaxLimit: { type: 'number', default: 500 }
};

const init = function () {
  scene = this.el.sceneEl.object3D;
  disposeHierarchy(scene, disposeNode);

  const renderer = this.el.sceneEl.renderer;
  renderer.dispose();
  renderer.renderLists.dispose();

  // TODO: there may be a better way to improve caching
  const cacheAssetKeys = Object.keys(AFRAME.THREE.Cache.files);
  if (cacheAssetKeys.length > this.data.cacheMaxLimit) {
    let index = 0;
    cacheAssetKeys.forEach((assetKey) => {
      if (index >= this.data.cacheMaxLimit) {
        AFRAME.THREE.Cache.remove(assetKey);
      }
      index++;
    });
  }
  // dispose of entire cache, memory issues are less desirable than load time increase
  AFRAME.THREE.Cache.clear();
};

export default { schema, init };
