import Vue from 'vue';
import { GET_EVENTS, GET_EVENT_BY_SLUG, GET_EVENT_BY_ID, SET_ACTIVE_EVENT, SET_EVENTS, CREATE_EVENT, UPDATE_EVENT } from './types';
import { getStaticAssetUrl } from '@/utils/files';

const backwardsCompatibilityMap = (event) => {
  return {
    ...event,
    startDate: new Date(event.startDate),
    endDate: new Date(event.endDate),
    // TODO: path root should be configurable (aws, azure)
    imageSrc: getStaticAssetUrl(event.imageFileId),
    // TODO: remove below backwards compatibility
    name: event.title,
    eventName: event.title,
    imgTitle: event.title,
    permalink: event.slug,
    date: `${new Date(event.startDate).toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' })} - ${new Date(event.endDate).toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' })}`,
    type: 'Hybrid',
    cost: 'Free',
    start: new Date(event.startDate),
    end: new Date(event.endDate)
  };
};

export default {
  state: () => ({
    events: [],
    activeEvent: null
  }),
  mutations: {
    [SET_EVENTS](state, value) {
      state.events = [...value].map(backwardsCompatibilityMap).sort((a, b) => b.startDate - a.startDate).filter((event) => !event.isDeleted);
    },
    [SET_ACTIVE_EVENT](state, value) {
      state.activeEvent = backwardsCompatibilityMap(value);
    }
  },
  actions: {
    [GET_EVENTS]({ commit }) {
      return Vue.axios.get(`/api/events`).then((response) => {
        if (!response || !response.data || !Array.isArray(response.data)) {
          commit(SET_EVENTS, []);
          return [];
        } else {
          commit(SET_EVENTS, response.data);
          return response.data;
        }
      });
    },
    [GET_EVENT_BY_SLUG]({ commit }, eventSlug) {
      return Vue.axios.get(`/api/events/${eventSlug}`).then((response) => {
        if (!response || !response.data) {
          commit(SET_ACTIVE_EVENT, null);
          return null;
        } else {
          commit(SET_ACTIVE_EVENT, response.data);
          return response.data;
        }
      });
    },
    [GET_EVENT_BY_ID]({ commit }, { id }) {
      return Vue.axios.get(`/api/events/id/${id}`).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [CREATE_EVENT](_context, { event }) {
      return Vue.axios.post(`/api/events`, event).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [UPDATE_EVENT](_context, { eventId, event }) {
      return Vue.axios.put(`/api/events/${eventId}`, event).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
  },
};
