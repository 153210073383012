import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
});

// https://theme-generator.vuetifyjs.com/ generates a theme based on one color
// const customTheme = {
//     primary: '#3f51b5',
//     secondary: '#ff9800',
//     accent: '#ffeb3b',
//     error: '#e91e63',
//     warning: '#f44336',
//     info: '#cddc39',
//     success: '#ff9800',
// }

const defaultTheme = {
  primary: '#1976D2', // main blue 
  secondary: '#424242', // black
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  light_grey: '#e5e5e5',
  grey: '#c4c4c4',
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...defaultTheme
      },
    },
  },
});
