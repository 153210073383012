import Vue from 'vue';
import { TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE, TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE } from '@/store/game-settings/types';
import { muteMicrophone, muteVideo } from '@/utils/networking';


export const toggleVideo = async () => {
  const store = Vue.prototype.$store;
  // video cannot be toggled while screensharing
  if (!store.state.gameSettings.isScreenShareMuted) { return; }

  // update state
  store.commit(TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE);

  try {
    // update adapter
    const mediaStream = await muteVideo(store.state.gameSettings.isVideoOutputMuted);
    // update self-view
    const videoEl = document.getElementById('self-view-video');
    if (!videoEl) { return; }
    if (!store.state.gameSettings.isVideoOutputMuted) {
      videoEl.style.display = 'flex';
      videoEl.srcObject = mediaStream;
      videoEl.play();
    } else {
      videoEl.style.display = 'none';
      videoEl.pause();
    }
  } catch (e) {
    // mute failed, revert the toggle
    store.commit(TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE);
  }
};


export const toggleAudio = async () => {
  const store = Vue.prototype.$store;
  // update state
  store.commit(TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE);
  
  try {
    // update adapter
    await muteMicrophone(store.state.gameSettings.isAudioOutputMuted);
  } catch (e) {
    // mute failed, revert the toggle
    store.commit(TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE);
  }
};
