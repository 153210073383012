const init = function () {
  this.isRotating = false;
  this.bindMethods();
};

const play = function () {
  this.addEventListeners();
};

const pause = function () {
  this.removeEventListeners();
};

const remove = function () {
  this.removeEventListeners();
};

const bindMethods = function () {
  this.onRotateStartLeft = this.onRotateStartLeft.bind(this);
  this.onRotateStartRight = this.onRotateStartRight.bind(this);
  this.onRotateEnd = this.onRotateEnd.bind(this);
};

const addEventListeners = function () {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl.canvas;

  // Wait for canvas to load.
  if (!canvasEl) {
    sceneEl.addEventListener('render-target-loaded', Function.prototype.bind(this.addEventListeners), this);
    return;
  }

  this.el.addEventListener('keydown:KeyQ', this.onRotateStartLeft, false);
  this.el.addEventListener('keydown:ArrowLeft', this.onRotateStartLeft, false);
  this.el.addEventListener('keydown:KeyE', this.onRotateStartRight, false);
  this.el.addEventListener('keydown:ArrowRight', this.onRotateStartRight, false);
  this.el.addEventListener('keyup:KeyQ', this.onRotateEnd, false);
  this.el.addEventListener('keyup:ArrowLeft', this.onRotateEnd, false);
  this.el.addEventListener('keyup:KeyE', this.onRotateEnd, false);
  this.el.addEventListener('keyup:ArrowRight', this.onRotateEnd, false);
};

const removeEventListeners = function () {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  
  this.el.removeEventListener('keydown:KeyQ', this.onRotateStartLeft);
  this.el.removeEventListener('keydown:ArrowLeft', this.onRotateStartLeft);
  this.el.removeEventListener('keydown:KeyE', this.onRotateStartRight);
  this.el.removeEventListener('keydown:ArrowRight', this.onRotateStartRight);
  this.el.removeEventListener('keyup:KeyQ', this.onRotateEnd);
  this.el.removeEventListener('keyup:ArrowLeft', this.onRotateEnd);
  this.el.removeEventListener('keyup:KeyE', this.onRotateEnd);
  this.el.removeEventListener('keyup:ArrowRight', this.onRotateEnd);
};

const triggerMouseEvent = function (node, eventType) {
  var clickEvent = document.createEvent('MouseEvents');
  clickEvent.initEvent(eventType, true, true);
  node.dispatchEvent(clickEvent);
};

const onRotateStartLeft = function (evt) {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  if (this.isRotating) { return; }
  if (((sceneEl.is('vr-mode') || sceneEl.is('ar-mode')))) { return; }

  this.isRotating = true;
  this.setMovementControlState(false);
  this.animateRotationHandler(canvasEl, -150, -15, 10);
};

const onRotateStartRight = function (evt) {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  if (this.isRotating) { return; }
  if (((sceneEl.is('vr-mode') || sceneEl.is('ar-mode')))) { return; }

  this.isRotating = true;
  this.setMovementControlState(false);
  this.animateRotationHandler(canvasEl, 150, 15, 10);
};

const animateRotationHandler = function(el, target, step, interval) {
  // TODO: prevent physical mouse from affecting scene
  this.triggerMouseEvent(el, 'mousedown');

  let rotationXCounter = 0;
  const intervalFn = setInterval(() => {
    el.dispatchEvent(new MouseEvent('mousemove', {
      screenX: rotationXCounter,
      bubbles: true,
      cancelable: true,
      view: window
    }));
    rotationXCounter += step;
    if (rotationXCounter === target) {
      clearInterval(intervalFn);
      this.isRotating = false;
      this.triggerMouseEvent(el, 'mouseup');
    }
  }, interval);
};

const onRotateEnd = function (evt) {
  const sceneEl = this.el.sceneEl;
  const canvasEl = sceneEl && sceneEl.canvas;

  if (!canvasEl) { return; }
  if (((sceneEl.is('vr-mode') || sceneEl.is('ar-mode')))) { return; }

  this.isRotating = false;
  this.setMovementControlState(true);
  this.triggerMouseEvent(canvasEl, 'mouseup');
};

const setMovementControlState = function (state) {
  // TODO: pass rig/cursor in as component data

  // cannot move while rotating camera, movement controls need to be disabled when a bound key is overwritten (ArrowLeft, ArrowRight)
  const playerModelRig = document.getElementById('player-model-entity');
  if (playerModelRig) {
    playerModelRig.setAttribute('movement-controls', Object.assign(playerModelRig.getAttribute('movement-controls'), { enabled: state }));
  }

  // prevent raycaster click into 3D content
  const playerCursor = document.getElementById('player-cursor-entity');
  if (playerCursor) {
    playerCursor.setAttribute('raycaster', Object.assign(playerCursor.getAttribute('raycaster'), { far: state ? 20 : 0 }));
  }
};

export default { init, play, pause, remove, bindMethods, addEventListeners, removeEventListeners, triggerMouseEvent, animateRotationHandler, onRotateStartLeft, onRotateStartRight, onRotateEnd, setMovementControlState };
