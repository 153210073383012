import Vue from 'vue';
import { GET_BOOTHS, 
  GET_BOOTH, SET_BOOTHS, SET_BOOTH, 
  SET_SELECTED_BOOTH, SET_BOOTHS_BY_KEY, SET_BOOTHS_BY_ID, ADD_BOOTH, UNASSIGN_FROM_BOOTH_AND_DELETE_PERSON,
  EDIT_BOOTH, DELETE_BOOTH, GET_BOOTH_TEAM, SET_BOOTH_TEAM, ASSIGN_PERSON_TO_BOOTH, GET_BOOTH_CONTENT, 
  GET_BOOTH_CONTENT_BY_ID, CREATE_BOOTH_CONTENT, UPDATE_BOOTH_CONTENT, DELETE_BOOTH_CONTENT,
  UPDATE_BOOTH_MODEL_REFERENCE, GET_BOOTH_TEAM_NEW, GET_BOOTH_STATUS, SET_BOOTH_STATUS, UPDATE_BOOTH_STATUS, 
  SET_BOOTH_MODELS, GET_BOOTH_MODELS, GET_BOOTH_MODEL_BY_ID, SET_BOOTH_MODEL_BY_ID, GET_FLOOR_BOOTHS, SET_FLOOR_BOOTHS } from './types.js';
import { spreadJson, unSpreadJson, cleanJson, addDataToBoothObject, addBoothData, backwardsCompatibilityMap } from '../../utils/boothHelpers';
import { CREATE_FLOORMAP_LAYOUT_GRID_CELL } from '../floormaps/types.js';
import { DELETE_PERSON } from '../people/types.js';

export default {
  state: () => ({
    booth: {},
    booths: [],
    boothTeam: [],
    boothModel: null,
    boothModels: [],
    boothsByKey: {},
    boothsById: {},
    selectedBooth: null,
    boothStatus: 0,
    floorBooths: [],
  }),
  mutations: {
    [SET_BOOTH](state, value ) {
      const spreadedJson = spreadJson(value);
      const booth = addDataToBoothObject(spreadedJson);
      state.booth = backwardsCompatibilityMap(booth);
    },
    [SET_BOOTHS](state, value) {
      state.booths = value;
    },
    [SET_BOOTH_TEAM](state, value) {
      state.boothTeam = value;
    },
    [SET_BOOTH_MODELS](state, value) {
      state.boothModels = value;
    },
    [SET_BOOTH_MODEL_BY_ID](state, value) {
      state.boothModel = value;
    },
    [SET_BOOTHS_BY_KEY](state, value) {
      const objectResult = {};
      for (let index = 0; index < value.length; index++) {
        const booth = value[index];
        objectResult[booth.title] = booth;
      }
      state.boothsByKey = objectResult;
    },
    [SET_BOOTHS_BY_ID](state, value) {
      const objectResult = {};
      for (let index = 0; index < value.length; index++) {
        const booth = value[index];
        objectResult[booth.id] = booth;
      }
      state.boothsById = objectResult;
    },
    [SET_SELECTED_BOOTH](state, value) {
      if (!value) { return; }
      const objectResult = value;
      state.selectedBooth = objectResult;
    },
    [SET_BOOTH_STATUS](state, value) {
      state.boothStatus = value;
    }, 
    [SET_FLOOR_BOOTHS](state, value) {
      state.floorBooths = value;
    }, 
  },
  actions: {
    [GET_BOOTHS]({ commit }, {eventSlug, metadataFlag, statusFlag}) {
      return Vue.axios.get(`/api/${eventSlug}/booths${metadataFlag ? '?include=metadata' : ''}${statusFlag ? ',status' : ''}`).then((response) => {     
        if (!response || !response.data || !Array.isArray(response.data)) {
          commit(SET_BOOTHS, []);
          return [];
        } else {
          // const formattedBoothData = addBoothData(response.data); 
          const formattedBoothsConferenceFloor = addBoothData(response.data.map((item) => backwardsCompatibilityMap(item))).filter((booth) => !booth.isDeleted);
          commit(SET_BOOTHS, formattedBoothsConferenceFloor);
          commit(SET_BOOTHS_BY_KEY, formattedBoothsConferenceFloor);
          commit(SET_BOOTHS_BY_ID, formattedBoothsConferenceFloor);
          return formattedBoothsConferenceFloor;
        }
      });
    },
    [GET_BOOTH]({ commit }, { id, eventSlug, metadataFlag }) {
      return Vue.axios.get(`/api/${eventSlug}/booths/${id}${metadataFlag ? '?include=metadata' : ''}`).then((response) => {       
        if (!response || !response.data) {
          commit(SET_BOOTH, null);
          return null;
        } else {
          commit(SET_BOOTH, backwardsCompatibilityMap(response.data));
          return backwardsCompatibilityMap(response.data);
        }
      });
    },
    [GET_BOOTH_MODELS]({ commit }, { eventSlug }) {
      return Vue.axios.get(`/api/${eventSlug}/booths/models`).then((response) => {       
        if (!response || !response.data) {
          commit(SET_BOOTH_MODELS, null);
          return null;
        } else {
          commit(SET_BOOTH_MODELS, response.data );
          return response.data;
        }
      });
    },
    [GET_BOOTH_MODEL_BY_ID]({ commit }, { eventSlug, id }) {
      return Vue.axios.get(`/api/${eventSlug}/booths/models/${id}`).then((response) => {       
        if (!response || !response.data) {
          commit(SET_BOOTH_MODEL_BY_ID, null);
          return null;
        } else {
          commit(SET_BOOTH_MODEL_BY_ID, response.data );
          return response.data;
        }
      });
    },
    [ADD_BOOTH]({ commit, dispatch }, { eventSlug, data }) {
      let formattedData = cleanJson(unSpreadJson(data));
      return Vue.axios.post(`/api/${eventSlug}/booths`, formattedData).then((response) => {
        if (response.data.id) {
          return response.data.id;
        }   
      });
    },
    async [EDIT_BOOTH]({ commit, dispatch }, { eventSlug, boothId, data,  }) {
      return Vue.axios.put(`/api/${eventSlug}/booths/${boothId}`, data).then((response) => {
        return response;
      });
    },
    async [DELETE_BOOTH]({ commit, dispatch }, { eventSlug, boothId,  }) {
      try { 
        await Vue.axios.delete(`/api/${eventSlug}/booths/${boothId}`);
      } catch (error) {
        console.log(error);
      }
    },
    [GET_BOOTH_TEAM]({ commit }, { context }) {
      return Vue.axios.get(`/api/${context.$route.params.event}/booths/${context.$route.params.id}/team`).then((response) => {       
        if (!response || !response.data) {
          commit(SET_BOOTH_TEAM, null);
          return null;
        } else {
          commit(SET_BOOTH_TEAM, response.data );
          return response.data;
        }
      });
    },
    [GET_BOOTH_TEAM_NEW]({ commit }, { eventSlug, boothId }) {
      return Vue.axios.get(`/api/${eventSlug}/booths/${boothId}/team`).then((response) => {       
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [ASSIGN_PERSON_TO_BOOTH]({ commit }, { context, personId }) {
      if (!context || !personId) { return console.log('missing data in assign'); }
      return Vue.axios.put(`/api/${context.$route.params.event}/booths/${context.$route.params.id}/team/${personId}`).then((response) => {    
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [UNASSIGN_FROM_BOOTH_AND_DELETE_PERSON]({ commit, dispatch }, { context, personId }) {
      if (!context || !personId) { return console.log('missing data in unassign'); }
      return Vue.axios.delete(`/api/${context.$route.params.event}/booths/${context.$route.params.id}/team/${personId}`).then((response) => {    
        if (!response || !response.data) {
          return null;
        } else {
          if (response.data.personId){
            dispatch(DELETE_PERSON, { eventSlug: context.$route.params.event, id: response.data.personId})
              .then((response2) => response2)
              .catch((e) => console.log(e));
          }
          return response.data;
        }
      });
    },
    async [GET_BOOTH_CONTENT]({ commit }, { eventSlug, boothId }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/booths/${boothId}/content`);
        if (!response || !response.data || !Array.isArray(response.data)) {
          return [];
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return [];
      }
    },
    async [GET_BOOTH_CONTENT_BY_ID]({ commit }, { eventSlug, boothId, contentId }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/booths/${boothId}/content/${contentId}`);
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [CREATE_BOOTH_CONTENT]({ commit }, { eventSlug, boothId, content }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/booths/${boothId}/content`, content);
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [UPDATE_BOOTH_CONTENT](_context, { eventSlug, boothId, contentId, content }) {
      try {
        const response = await Vue.axios.put(`/api/${eventSlug}/booths/${boothId}/content/${contentId}`, content);
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [DELETE_BOOTH_CONTENT](_context, { eventSlug, boothId, contentId }) {
      try {
        const response = await Vue.axios.delete(`/api/${eventSlug}/booths/${boothId}/content/${contentId}`);
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [UPDATE_BOOTH_MODEL_REFERENCE](_context, { eventSlug, boothId, modelReferenceId }) {
      try {
        const response = await Vue.axios.put(`/api/${eventSlug}/booths/${boothId}/modelReference/${modelReferenceId}`);
        if (!response || !response.data) {
          return null;
        }
        return response.data;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [GET_BOOTH_STATUS]({ commit }, { eventSlug, boothId }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/booths/${boothId}/status`);
        if (!response) {
          return null;
        }
        commit(SET_BOOTH_STATUS, response.data.status);
        return response.data.status;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
    async [SET_BOOTH_STATUS]({ commit }, { eventSlug, boothId, state }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/booths/${boothId}/status`, { status: state });
        if (!response || !response.data) { return false; }
        commit(SET_BOOTH_STATUS, response.data.status);
        return response.data.status;
      } catch (e) {
        return null;
      }
    },
    async [GET_FLOOR_BOOTHS]({ commit }, { eventSlug, boothsGrid, locationLayoutId, metadataFlag = true, statusFlag }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/booths${metadataFlag ? '?include=metadata' : ''}${statusFlag ? ',status' : ''}`);    
        const floorBooths = response.data.map( (booth) => {
          booth = backwardsCompatibilityMap(booth);
          const boothExistsOnFloor = boothsGrid.find( grid => grid.boothId === booth.id );
          return {
            ...booth, 
            boothExistsOnFloor: !!boothExistsOnFloor
          };
        });
        commit(SET_FLOOR_BOOTHS, floorBooths);
      } catch (e) {
        return null;
      }
    },
  }
};
