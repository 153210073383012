import Vue from 'vue';
import { TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE, TOGGLE_GAME_SETTING_CAMERA, TOGGLE_GAME_SETTING_FLY, TOGGLE_GAME_SETTING_HELP, TOGGLE_GAME_SETTING_DARK_MODE, TRIGGER_GAME_SETTING_EVENT, TOGGLE_GAME_SETTING_IS_USER_SPEAKING, ADD_GAME_SETTING_EVENT_LISTENER, TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE, RESET_GAME_SETTING_STATE } from './types.js';

export default {
  state: () => ({
    isFlyEnabled: false,
    isHelpVisible: false,
    isFirstPerson: false,
    isUserSpeaking: false,
    isAudioOutputMuted: true,
    isVideoOutputMuted: true,
    isScreenShareMuted: true,
    isVideoShareMuted: true,
    isDarkMode: true,
    // TODO: make const map for event names
    eventBus: new Vue()
  }),
  mutations: {
    [TOGGLE_GAME_SETTING_FLY](state) {
      state.isFlyEnabled = !state.isFlyEnabled;
    },
    [TOGGLE_GAME_SETTING_HELP](state) {
      state.isHelpVisible = !state.isHelpVisible;
    },
    [TOGGLE_GAME_SETTING_CAMERA](state, value) {
      if (typeof value === 'undefined') { state.isFirstPerson = !state.isFirstPerson; }
      else { state.isFirstPerson = !!value; }
    },
    [TOGGLE_GAME_SETTING_AUDIO_OUTPUT_MUTE](state, value) {
      if (typeof value === 'undefined') { state.isAudioOutputMuted = !state.isAudioOutputMuted; }
      else { state.isAudioOutputMuted = !!value; }
      state.eventBus.$emit('on-audio-output-mute', state.isAudioOutputMuted);
    },
    [TOGGLE_GAME_SETTING_VIDEO_OUTPUT_MUTE](state, value) {
      if (typeof value === 'undefined') { state.isVideoOutputMuted = !state.isVideoOutputMuted; }
      else { state.isVideoOutputMuted = !!value; }
      state.eventBus.$emit('on-video-output-mute', state.isVideoOutputMuted);
    },
    [TOGGLE_GAME_SETTING_DARK_MODE](state, value) {
      if (typeof value === 'undefined') { state.isDarkMode = !state.isDarkMode; }
      else { state.isDarkMode = !!value; }
      state.eventBus.$emit('on-dark-mode-toggle', state.isDarkMode);
    },
    [TOGGLE_GAME_SETTING_IS_USER_SPEAKING](state, value) {
      if (typeof value === 'undefined') { state.isUserSpeaking = !state.isUserSpeaking; }
      else { state.isUserSpeaking = !!value; }
      state.eventBus.$emit('on-speech-start', state.isUserSpeaking);
    },
    [RESET_GAME_SETTING_STATE](state) {
      state.isFlyEnabled = false;
      state.isFirstPerson = false;
      state.isHelpVisible = false;
      state.isUserSpeaking = false;
      state.isAudioOutputMuted = true;
      state.isVideoOutputMuted = true;
    }
  },
  actions: {
    [TRIGGER_GAME_SETTING_EVENT]({ state }, { name, data }) {
      state.eventBus.$emit(name, data);
    },
    [ADD_GAME_SETTING_EVENT_LISTENER]({ state }, { name, listener }) {
      state.eventBus.$off(name);
      state.eventBus.$on(name, listener);
    }
  }
};
