<template>
  <v-breadcrumbs
    :items="formattedBreadcrumbs"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item :disabled="item.disabled">
        <router-link
          class="text-decoration-none"
          style="color: inherit"
          :to="item.href"
        >
          <span class="text-body-2">{{ item.text }}</span>
        </router-link>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>

export default {
  computed: {
    formattedBreadcrumbs() {
      if (!this.$breadcrumbs || this.$breadcrumbs.length === 0) {
        return [];
      }
      const lastCrumb = this.$breadcrumbs[this.$breadcrumbs.length - 1];
      return [{
        text: this.getBreadcrumb(lastCrumb.meta.breadcrumb),
        href: '#'
      }];
      // TODO: full breadcrumbs
      return this.$breadcrumbs.map((crumb) => {
        return {
          text: this.getBreadcrumb(crumb.meta.breadcrumb),
          href: this.getPath(crumb),
          disabled: false
        };
      });
    }
  },
  methods: {
    getPath(crumb) {
      let { path } = crumb;
      for (const [key, value] of Object.entries(this.$route.params)) {
        path = path.replace(`:${key}`, value);
      }
      return path;
    },
    getBreadcrumb(bc) {
      let name = bc;
      if (typeof name === 'function') {
        name = name.call(this, this.$route.params);
      }
      if (typeof name === 'object') {
        name = name.label;
      }
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }
};
</script>
