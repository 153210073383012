import Vue from 'vue';
import {
  GET_SESSION_BY_BOOTH,
  SET_SESSION_BY_BOOTH,
  SET_SESSION_LOADING,
  SET_BOOTH_CURRENT_SESSION,
} from './types.js';

export default {
  state: () => ({
    boothSession: null,
    isSessionLoading: false,
    boothCurrentSession: null,
  }),
  mutations: {
    [SET_SESSION_BY_BOOTH](state, value) {
      state.boothSession = value;
    },
    [SET_SESSION_LOADING](state, value) {
      state.isSessionLoading = value;
    },
    [SET_BOOTH_CURRENT_SESSION](state, value) {
      state.boothCurrentSession = value;
    },
  },
  actions: {
    [GET_SESSION_BY_BOOTH]({ state, commit }, { booth }) {
      commit(SET_SESSION_LOADING, true);
      return Vue.axios
        .get(`${process.env.VUE_APP_FORD_CENTER_URL}/api/votingsessions/upcoming`)
        .then((sessions) => {
          if (sessions && sessions.data) {
            const upcomingSessions = sessions.data
              .filter((session) => {
                return session.epochEndTime > Date.now();
              })
              .sort((a, b) => {
                return a.epochEndTime - b.epochEndTime;
              });
            const currentSessions = sessions.data
              .filter((session) => {
                return (
                  session.epochEndTime > Date.now() &&
                  session.epochStartTime < Date.now()
                );
              })
              .sort((a, b) => {
                return a.epochEndTime - b.epochEndTime;
              });

            let boothSession = upcomingSessions.find((session) => {
              const sessionInfo = JSON.parse(session.data_json);
              return sessionInfo.davra && sessionInfo.davra.booth === booth;
            });
            let boothSessionCurrent = currentSessions.find((session) => {
              const sessionInfo = JSON.parse(session.data_json);
              return sessionInfo.davra && sessionInfo.davra.booth === booth;
            });
            if (boothSession) {
              boothSession.data_json = JSON.parse(boothSession.data_json);
            }
            commit(SET_BOOTH_CURRENT_SESSION, boothSessionCurrent);
            commit(SET_SESSION_BY_BOOTH, boothSession);
            commit(SET_SESSION_LOADING, false);
            return boothSession;
          } else {
            commit(SET_BOOTH_CURRENT_SESSION, null);
            commit(SET_SESSION_BY_BOOTH, null);
            commit(SET_SESSION_LOADING, false);
          }
        })
        .catch(() => {
          commit(SET_SESSION_LOADING, false);
        });
    },
  },
};
