import Vue from 'vue';
import {
  GET_ALL_ACHIEVEMENTS,
  SET_ALL_ACHIEVEMENTS,
  GET_USER_ACHIEVEMENT_PROGRESS,
  SET_USER_ACHIEVEMENT_PROGRESS,
  GET_USER_ACHIEVEMENT_TASK_PROGRESS,
  SET_USER_ACHIEVEMENT_TASK_PROGRESS,
  GET_ACHIEVEMENT_GROUPS,
  SET_ACHIEVEMENT_GROUPS,
  GET_ACHIEVEMENT_LEADERBOARD,
  SET_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME,
  SET_ACHIEVEMENT_LEADERBOARD,
  REFRESH_ACHIEVEMENT_LEADERBOARD,
  GET_ACHIEVEMENT_LEADERBOARD_POSITION,
  SET_ACHIEVEMENT_LEADERBOARD_POSITION_LAST_UPDATE_TIME,
  SET_ACHIEVEMENT_LEADERBOARD_POSITION,
  GET_MINI_ACHIEVEMENT_LEADERBOARD,
  SET_MINI_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME,
  SET_MINI_ACHIEVEMENT_LEADERBOARD
} from './types.js';

export default {
  state: () => ({
    allAchievements: [],
    userAchievementProgress: [],
    userAchievementTaskProgress: [],
    achievementGroups: [],
    achievementLeaderboardLastUpdateTime: null,
    achievementLeaderboard: [],
    achievementLeaderboardPosition: '',
    achievementLeaderboardPositionLastUpdateTime: null,
    miniAchievementLeaderboard: null,
    miniAchievementLeaderboardLastUpdateTime: null,
  }),
  mutations: {
    [SET_ALL_ACHIEVEMENTS](state, value) {
      state.allAchievements = value;
    },
    [SET_USER_ACHIEVEMENT_PROGRESS](state, value) {
      state.userAchievementProgress = value;
    },
    [SET_USER_ACHIEVEMENT_TASK_PROGRESS](state, value) {
      state.userAchievementTaskProgress = value;
    },
    [SET_ACHIEVEMENT_GROUPS](state, value) {
      state.achievementGroups = value;
    },
    [SET_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME](state, value) {
      state.achievementLeaderboardLastUpdateTime = value;
    },
    [SET_ACHIEVEMENT_LEADERBOARD](state, value) {
      state.achievementLeaderboard = value;
    },
    [SET_ACHIEVEMENT_LEADERBOARD_POSITION](state, value) {
      state.achievementLeaderboardPosition = value;
    },
    [SET_ACHIEVEMENT_LEADERBOARD_POSITION_LAST_UPDATE_TIME](state, value) {
      state.achievementLeaderboardPositionLastUpdateTime = value;
    },
    [SET_MINI_ACHIEVEMENT_LEADERBOARD](state, value) {
      state.miniAchievementLeaderboard = value;
    },
    [SET_MINI_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME](state, value) {
      state.miniAchievementLeaderboardLastUpdateTime = value;
    },
  },
  actions: {
    [GET_ALL_ACHIEVEMENTS]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements`).then((data) => {
        if (data && data.data) {
          commit(SET_ALL_ACHIEVEMENTS, data.data);
          return data.data;
        }
      });
    },
    [GET_USER_ACHIEVEMENT_PROGRESS]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/progress`).then((data) => {
        if (data && data.data) {
          commit(SET_USER_ACHIEVEMENT_PROGRESS, data.data);
          return data.data;
        }
      });
    },
    [GET_USER_ACHIEVEMENT_TASK_PROGRESS]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/tasks/progress`).then((data) => {
        if (data && data.data) {
          commit(SET_USER_ACHIEVEMENT_TASK_PROGRESS, data.data);
          return data.data;
        }
      });
    },
    [GET_ACHIEVEMENT_GROUPS]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/groups`).then((data) => {
        if (data && data.data) {
          commit(SET_ACHIEVEMENT_GROUPS, data.data);
          return data.data;
        }
      });
    },
    [GET_ACHIEVEMENT_LEADERBOARD]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/leaderboard`).then((data) => {
        if (data && data.data && data.data.data) {
          commit(SET_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME, new Date(data.data.lastUpdated));
          commit(SET_ACHIEVEMENT_LEADERBOARD, data.data.data);
          return data.data;
        }
      });
    },
    [GET_MINI_ACHIEVEMENT_LEADERBOARD]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/leaderboard?mini=true`).then((data) => {
        if (data && data.data && data.data.data) {
          commit(SET_MINI_ACHIEVEMENT_LEADERBOARD_LAST_UPDATE_TIME, new Date(data.data.lastUpdated));
          commit(SET_MINI_ACHIEVEMENT_LEADERBOARD, data.data.data);
          return data.data;
        }
      });
    },
    [GET_ACHIEVEMENT_LEADERBOARD_POSITION]({ state, commit },  { eventSlug, userId }) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/leaderboard/${ userId }`).then((data) => {
        if (data && data.data) {
          commit(SET_ACHIEVEMENT_LEADERBOARD_POSITION_LAST_UPDATE_TIME, new Date(data.data.lastUpdated));
          commit(SET_ACHIEVEMENT_LEADERBOARD_POSITION, data.data.position);
          return data.data;
        }
      }).catch((_) => {
        commit(SET_ACHIEVEMENT_LEADERBOARD_POSITION, 'None');
      });
    },
    [REFRESH_ACHIEVEMENT_LEADERBOARD]({ state, commit }, eventSlug) {
      return Vue.axios.get(`/api/${eventSlug}/achievements/leaderboard/refresh`).then((data) => {
        if (data && data.data) {
          return data.data;
        }
      });
    },
  }
};
