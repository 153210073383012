import Vue from 'vue';
import {
  GET_CONTENT_BY_SESSION,
  SET_CONTENT_BY_SESSION,
  SET_CONTENT_LOADING,
} from './types.js';
import { ADD_SWAGBAG_ITEM } from '@/store/swagbag/types';
import { getStaticAssetUrl } from '@/utils/files';
import { MixpanelEvents, MixpanelCreateDataObject } from '@/utils/mixpanel';

const backwardsCompatibilityMap = (content, context, dispatch) => {
  let actionFn = () => {};
  let items = null;
  switch (content.type) {
  case 'link':
    actionFn = () => { window.open(content.content.url, '__blank'); };
    // TODO: also handle "Add to Swagbag + Download"
    items = [
      { title: 'View', actionFn }
    ];
    break;
  case 'pdf':
    actionFn = () => {
      context.currentPdf = {
        title: content.content.title,
        url: getStaticAssetUrl(content.content.fileId),
        image: content.content.thumbnailFileId ? getStaticAssetUrl(content.content.thumbnailFileId) : null,
      };
      context.dialogOpen = 'takeaways-view';
    };
    // TODO: also handle "Add to Swagbag + Download"
    items = [
      { title: 'View', actionFn },
      { title: 'Add to swagbag', actionFn: () => {
        const swagObject = {
          filePath: getStaticAssetUrl(content.content.fileId),
          fileName: content.content.title,
          fileType: content.type,
          fileUrl:  getStaticAssetUrl(content.content.fileId),
          fileCategory:  '',
          fileDescription:  '',
          boothName: context.booth.title
        };

        dispatch(ADD_SWAGBAG_ITEM, { data: swagObject, eventSlug: context.selectedEvent.slug})
          .then((res) => {
            try {
              let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
              if (!MixpanelEvents.ADDED_TO_SWAGBAG || !mixPanelDataObject) { console.log('mixpanel event error'); }
              else {
                mixPanelDataObject = {
                  ...mixPanelDataObject,
                  [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
                  [MixpanelEvents.FILE_URL]: getStaticAssetUrl(content.content.fileId),
                  [MixpanelEvents.FILE_NAME]: content.content.title,
                };
                Vue.prototype._mixpanel.track(MixpanelEvents.ADDED_TO_SWAGBAG, mixPanelDataObject);
                context.$root.Toast.show({ text: `Swag added to your bag`, timeout: 5000 });
              }
            } catch (error) {
              console.log('mixpanel event err', error);
              context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
            }     
          })
          .catch((err) => {
            context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
          });
      }},
      { title: 'Download', actionFn: () => {
        try {
          let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
          if (!MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH || !mixPanelDataObject) { return console.warn('Mixpanel event error'); }
          mixPanelDataObject = {
            ...mixPanelDataObject,
            [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
            [MixpanelEvents.FILE_URL]: getStaticAssetUrl(content.content.fileId),
            [MixpanelEvents.FILE_NAME]: content.content.title,
          };
          Vue.prototype._mixpanel.track(MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH, mixPanelDataObject);
        } catch (err) {
          return console.warn('Mixpanel event error', err);
        }
        window.open(getStaticAssetUrl(content.content.fileId));
      }},
    ];
    break;
  case 'video':
    actionFn = () => {
      context.currentVideo = {
        title: content.content.title,
        subtitle: content.content.description,
        url: getStaticAssetUrl(content.content.fileId),
        image: content.content.thumbnailFileId ? getStaticAssetUrl(content.content.thumbnailFileId) : null,
      };
      context.dialogOpen = 'videos-video';
      let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
      if (!MixpanelEvents.VIDEO_VIEWED || !mixPanelDataObject) { return console.warn('Mixpanel event error'); }
      else {
        mixPanelDataObject = {
          ...mixPanelDataObject,
          [MixpanelEvents.BOOTH_NAME]: context.booth.title,
          [MixpanelEvents.FILE_URL]: getStaticAssetUrl(content.content.fileId),
          [MixpanelEvents.VIDEO_NAME]: content.content.title,
        };
        Vue.prototype._mixpanel.track(MixpanelEvents.VIDEO_VIEWED, mixPanelDataObject);
      }
    };
    items = [
      { title: 'View', actionFn },
      { title: 'Add to swagbag', actionFn: () => {
        const swagObject = {
          filePath: getStaticAssetUrl(content.content.fileId),
          fileName: content.content.title,
          fileType: content.type,
          fileUrl:  getStaticAssetUrl(content.content.fileId),
          fileCategory:  '',
          fileDescription:  '',
          boothName: context.booth.title
        };

        dispatch(ADD_SWAGBAG_ITEM, { data: swagObject, eventSlug: context.selectedEvent.slug})
          .then((res) => {
            try {
              let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
              if (!MixpanelEvents.ADDED_TO_SWAGBAG || !mixPanelDataObject) { console.log('mixpanel event error'); }
              else {
                mixPanelDataObject = {
                  ...mixPanelDataObject,
                  [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
                  [MixpanelEvents.FILE_URL]: getStaticAssetUrl(content.content.fileId),
                  [MixpanelEvents.FILE_NAME]: content.content.title,
                };
                Vue.prototype._mixpanel.track(MixpanelEvents.ADDED_TO_SWAGBAG, mixPanelDataObject);
                context.$root.Toast.show({ text: `Swag added to your bag`, timeout: 5000 });
              }
            } catch (error) {
              console.log('mixpanel event err', error);
              context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
            }     
          })
          .catch((err) => {
            context.$root.Toast.show({ text: `Try adding Swag later`, timeout: 5000 });
          });
      
      }},
      { title: 'Download', actionFn: () => {
        try {
          let mixPanelDataObject = MixpanelCreateDataObject(context.selectedEvent.slug, context.userData);
          if (!MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH || !mixPanelDataObject) { return console.warn('Mixpanel event error'); }
          mixPanelDataObject = {
            ...mixPanelDataObject,
            [MixpanelEvents.BOOTH_NAME]: context && context.booth.title,
            [MixpanelEvents.FILE_URL]: getStaticAssetUrl(content.content.fileId),
            [MixpanelEvents.VIDEO_NAME]: content.content.title,
          };
          Vue.prototype._mixpanel.track(MixpanelEvents.SWAG_DOWNLOADED_FROM_BOOTH, mixPanelDataObject);
        } catch (err) {
          return console.warn('Mixpanel event error', err);
        }
        window.open(getStaticAssetUrl(content.content.fileId));
      }}
    ];
    break;
  }
  return {
    ...content, ...{
      contentType: content.type,
      title: content.content.title,
      subtitle: content.content.description,
      actionFn,
      items
    }
  };
};

export default {
  state: () => ({
    templateContent: null,
    isContentLoading: false,
  }),
  mutations: {
    [SET_CONTENT_BY_SESSION](state, value) {
      state.templateContent = value;
    },
    [SET_CONTENT_LOADING](state, value) {
      state.isContentLoading = value;
    },
  },
  actions: {
    async [GET_CONTENT_BY_SESSION]({ commit, dispatch }, { eventSlug, boothId, context }) {
      try {
        commit(SET_CONTENT_LOADING, true);
        const response = await Vue.axios.get(`/api/${eventSlug}/booths/${boothId}/content`);
        if (!response || !response.data || !Array.isArray(response.data)) {
          commit(SET_CONTENT_BY_SESSION, []);
          commit(SET_CONTENT_LOADING, false);
          return [];
        }
        const boothContent = response.data.filter((content) => { return content.isInBooth; }).map((content) => backwardsCompatibilityMap(content, context, dispatch));
        commit(SET_CONTENT_BY_SESSION, boothContent);
        commit(SET_CONTENT_LOADING, false);
        return boothContent;
      } catch (e) {
        console.warn(e);
        commit(SET_CONTENT_BY_SESSION, []);
        commit(SET_CONTENT_LOADING, false);
        return [];
      }
    },
  },
};
