import AFRAME from 'aframe';
import { attachSmartContentProximityEvents, drawSmartContent, getFixedMeshDimensions } from '@/utils/3d';

const schema = {
  smartContent: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  meta: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  target: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    },
    default: false
  }
};

const init = async function () {
  if (!this.data.target) {
    // TODO: without target + with coords, place into scene at specified position/rotation
    // TODO: without target + without coords, place into scene at 0 0 0 and apply auto-scaling
    return;
  }

  const castablePlaneId = `smart-content-cast-panel-${this.data.meta.name}`;

  // create a bounding box from the fixed mesh, used to determine its dimensions + world position
  const fixedMesh = this.data.target;
  const meshBoundingBox = new AFRAME.THREE.Box3().setFromObject(fixedMesh);
  const smartContentDimensions = getFixedMeshDimensions(meshBoundingBox);

  // get the center point of the fixed mesh, so that the plane can be repositioned to it
  const meshCenter = new AFRAME.THREE.Vector3();
  meshBoundingBox.getCenter(meshCenter);

  // build the smart content panels and remove the original mesh
  const scContainerEl = drawSmartContent(meshCenter, this.data.meta, this.data.smartContent, smartContentDimensions, { id: castablePlaneId, dimensions: smartContentDimensions, rotation: `0 ${this.data.meta.rotY || 0} 0`, center: meshCenter });
  fixedMesh.removeFromParent();
  
  this.el.append(scContainerEl);
  this.smartContentPlaneEl = scContainerEl;

  // attach any proximity events once the element has been added to the DOM
  attachSmartContentProximityEvents(scContainerEl);
};

const remove = function () {
  if (this.smartContentPlaneEl) { this.el.remove(this.smartContentPlaneEl); }
};


export default { schema, init, remove };