import axios from 'axios';

export const getAppVersion = () => {
  // Read version from webpack variable, which read from package.json: https://stackoverflow.com/a/53316440
  // eslint-disable-next-line no-undef
  return APP_VERSION ? `v${APP_VERSION}` : 'v0.0.0';
};

export const setFavicon = (url) => {
  let link = document.querySelector('link[rel~="icon"]');
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = url;
};

export const utils = {
  isObject(data) {
    return typeof data === 'object' && data !== null;
  },
  scrollUp(selector) {
    setTimeout(() => {
      const container = window.document.querySelector(selector);
      container.scrollTop = 0;
    }, 200);
  },
  hexToRgbA(hex, arrayForm = true, opacity = 168) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      if (arrayForm) {
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255, opacity];
      }
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        `,${opacity})`
      );
    }
    throw new Error('Bad Hex');
  },
};

export const getLobbyContentFromTreeData = (contentTemplateItems, type) => {
  if (contentTemplateItems) {
    let contentIdsForLobby = [];
    contentTemplateItems.forEach((item) => {
      if (item.items) {
        const templateTreeData = JSON.parse(item.items);

        /* eslint no-inner-declarations: "off" */
        function checkTreeChildren(children) {
          if (children) {
            children.forEach((child) => {
              if (
                !child.folder &&
                child.data &&
                child.data.lobby &&
                child.data.contentType === type &&
                !contentIdsForLobby.includes(child.data.id)
              ) {
                contentIdsForLobby.push(child.data.id);
              }
              if (child.children) {
                checkTreeChildren(child.children);
              }
            });
          }
        }
        checkTreeChildren(templateTreeData);
      }
    });
    return contentIdsForLobby;
  } else {
    return null;
  }
};

export const getFormattedPosterData = (booth, id) => {
  return {
    img: booth[`${id}image`] ?  booth[`${id}image`] : '',
    link: booth[`${id}imageLink`] ?  booth[`${id}imageLink`] : '',
  };
};

export const capitalizeFirstLetter = (string) => {
  if (!string) { return ''; }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBoothSizeFromModelRefId = (num) => {
  if (!num) { return ''; }
  if (num == '00000000-0000-3100-0000-000000000000') { return 'Small'; }
  if (num == '00000000-0000-3200-0000-000000000000') { return 'Medium'; }
  if (num == '00000000-0000-3300-0000-000000000000') { return 'Large'; }
};

export const getAvatarInitials = (name) => {
  if (!name || name.length === 0) { return '?'; }
  if (name.length === 1) { return name; }
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const initials = [...name.matchAll(rgx)] || [];

  const firstMatch = initials.shift();
  let firstInitial = '';
  if (firstMatch && firstMatch.length > 1) { firstInitial = firstMatch[1]; }

  const lastMatch = initials.pop();
  let lastInitial = '';
  if (lastMatch && lastMatch.length > 1) { lastInitial = lastMatch[1]; }

  return (firstInitial + lastInitial).toUpperCase();
};

// checks if a val is falsy but also if it is a falsey string "null" / "undefined"
export const isNullPlusFalseyString = (val = '') => {
  if (!val || val === 'null' || val === 'undefined') {return true;}
  return false;
};

export const uploadFile = (foreignId, fileName, fileBlob, fileId) => {
  return new Promise((resolve, reject) => {
    if (!foreignId || !fileName) {return reject('missing reqiured params');}
    const url = !fileId ? `${process.env.VUE_APP_API_URL}/api/files/${foreignId}/${fileName}` :  `${process.env.VUE_APP_API_URL}/api/files/${foreignId}/${fileName}/${fileId}`;
    var fd = new FormData();
    fd.append('file', fileBlob, fileName);
    axios.post(url, fd, { onUploadProgress: uploadEvent => {
      console.log('upload progress: ', Math.round(uploadEvent.loaded / uploadEvent.total) * 100, '%');
    }})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  }); // Promise end
};

// https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
export const uploadCroppedFile = (foreignId, fileName, cropResult, fileId) => {
  return new Promise((resolve, reject) => {
    if (!foreignId || !fileName || !cropResult) {return reject('missing params');}
    console.log('cropResult', cropResult);
    var dataURL = cropResult.canvas.toDataURL('image/jpeg', 0.5);
    var blob = dataURItoBlob(dataURL);
    var fd = new FormData();
    fd.append('file', blob, fileName);
    console.log('fileId', fileId);
    const url = !fileId ? `${process.env.VUE_APP_API_URL}/api/files/${foreignId}/${fileName}` :  `${process.env.VUE_APP_API_URL}/api/files/${foreignId}/${fileName}/${fileId}`;
    console.log('url', url);
    axios.post(url, fd, { onUploadProgress: uploadEvent => {
      console.log('upload progress: ', Math.round(uploadEvent.loaded / uploadEvent.total) * 100, '%');
    }})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  }); // Promise end
};

export const cropResultToBlob = (cropResult) => {
  return new Promise((resolve, reject) => {
    if (!cropResult) {return reject('missing params');}
    const dataURL = cropResult.canvas.toDataURL();
    const blob = dataURItoBlob(dataURL);
    resolve(blob);
  }); // Promise end
};

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
  {byteString = atob(dataURI.split(',')[1]);}
  else
  {byteString = unescape(dataURI.split(',')[1]);}

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const graphicsHelpers = {
  allImageNames: ['logo', 'screenshot', 'landscape1', 'landscape2', 'landscape3', 'landscape4', 'landscape5', 'portrait1', 'portrait2'],
  makeArray() {
    return this.allImageNames.map((it) => {
      let x = {name: it};
      if (it.includes('landscape') || it.includes('screenshot')) {x.orientation = 'landscape';}
      else if (it.includes('portrait1')) {x.orientation = 'portrait';}
      else {
        x.orientation = 'any';
      }
      return x;
    });
  }
};

export const imageCropperRules = {
  landscape : {
    maxWidth: 300,
    maxHeight: 150,
  },
  portrait : {
    maxWidth: 150,
    maxHeight: 300,
  },
  square : {
    maxWidth: 200,
    maxHeight: 200,
  }
};

export const getImageDimension = (file) => {
  return new Promise(function(resolve, reject) {
    // "Producing Code" (May take some time)
    var _URL = window.URL || window.webkitURL;
    var  img;
    img = new Image();
    var objectUrl = _URL.createObjectURL(file);
    img.onload = function () {
      _URL.revokeObjectURL(objectUrl);
      if (this.width > this.height){
        resolve('landscape');
      } else if (this.width < this.height){
        resolve('portrait');
      } else {
        resolve('square');
      }
    };
    img.src = objectUrl;
  });

};
export const countHashesInString = (str) => {
  return (str.match(/#/g) || []).length;
};
export const copyToClipBoard = (url) => {
  var dummy = document.createElement('input'),
    text = url;

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);

};

export const isEmptyObject = (obj) => {
  return obj // 👈 null and undefined check
&& Object.keys(obj).length === 0
&& Object.getPrototypeOf(obj) === Object.prototype;
};

export const openLinkInNewTab = (href) => {
  if (!href) {return null;}
  const withHttp = (href) => href.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) => schemma ? match : `http://${nonSchemmaUrl}`);
  return window.open(withHttp(href), '_blank', 'noopener,noreferrer');
};

export const observeElementExist = (selector, target = document) => {
  return new Promise(resolve => {
    if (target.querySelector(selector)) {
      return resolve(target.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (target.querySelector(selector)) {
        resolve(target.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(target.body, {
      childList: true,
      subtree: true
    });
  });
};

export const formatSeconds = (returnFormat, totalSeconds) => {
  if (!returnFormat || !totalSeconds) {
    return 'N/A';
  }
  if (returnFormat === 'minutes+seconds') {
    // 👇️ get number of full minutes
    const minutes = Math.floor(totalSeconds / 60);
    // 👇️ get remainder of seconds
    const seconds = totalSeconds % 60;
    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
    // ✅ format as MM:SS
    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    return result;
  } else {
    return 'N/A';
  }
};

export const hexToRgb = (hex, transparency = 1) => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) {
    const arr = normal.slice(1).map(e => parseInt(e, 16));
    return `rgb(${arr[0]}, ${arr[1]}, ${arr[2]}, ${transparency})`;
  }
  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) {
    const arr = shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));
    return `rgb(${arr[0]}, ${arr[1]}, ${arr[2]}, ${transparency})`;
  }
  return null;
};

export const setEventBrandingColours = (theme, root, colour) => {

  theme.themes.light = {
    ...theme.themes.light,
    primaryCustom: colour,
  };
  theme.themes.dark = {
    ...theme.themes.dark,
    primaryCustom: colour,
  };

  root.style.setProperty('--primary-custom', hexToRgb(colour, 1));
  root.style.setProperty('--primary-custom-lighten-1', hexToRgb(colour, 0.01));
  root.style.setProperty('--primary-custom-lighten-2', hexToRgb(colour, 0.02));
  root.style.setProperty('--primary-custom-lighten-3', hexToRgb(colour, 0.03));
  root.style.setProperty('--primary-custom-lighten-4', hexToRgb(colour, 0.04));
  root.style.setProperty('--primary-custom-lighten-5', hexToRgb(colour, 0.05));
  root.style.setProperty('--primary-custom-lighten-6', hexToRgb(colour, 0.06));
  root.style.setProperty('--primary-custom-lighten-7', hexToRgb(colour, 0.07));
  root.style.setProperty('--primary-custom-lighten-8', hexToRgb(colour, 0.08));
  root.style.setProperty('--primary-custom-lighten-9', hexToRgb(colour, 0.09));
  root.style.setProperty('--primary-custom-lighten-10', hexToRgb(colour, 0.10));
  root.style.setProperty('--primary-custom-lighten-11', hexToRgb(colour, 0.11));
  root.style.setProperty('--primary-custom-lighten-12', hexToRgb(colour, 0.12));
  root.style.setProperty('--primary-custom-lighten-24', hexToRgb(colour, 0.24));
};

export const addDebouncedEventListener = (name, fn, delay = 250) => {
  let timeout = false;
  const listener = () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, delay);
  };

  window.addEventListener(name, listener);
  return listener;
};

export const truncateString = (inputString, maxLength) => {
  const truncationString = inputString.length > maxLength ? '...' : '';
  return inputString.substring(0, maxLength) + truncationString;
};
