import AFRAME from 'aframe';

const schema = {
  playerName: { type: 'string', default: '' },
  playerCompany: { type: 'string', default: '' },
  playerRole: { type: 'string', default: '' },
  micImage: {type: 'asset'},
  likeImage: {type: 'asset'},
};

const init = function () {

  const nametagEl = this.el.querySelector('.nametag');
  if (nametagEl && this.el.id !== 'player-model-entity') {
    // Create the plane for the name tag
    var labelPlane = document.createElement('a-entity');
    labelPlane.setAttribute('geometry', {
      primitive: 'plane',
      width: 1.0,
      height: 0.25
    });
    labelPlane.setAttribute('material', {
      color: 'black',
      opacity: 0.38,
      transparent: true
    });
    labelPlane.setAttribute('position', '0 0.1 0');

    // Name, Company, Role appended to plane
    // Name
    var userNameLabel = document.createElement('a-troika-text');
    userNameLabel.setAttribute('color', 'white');
    userNameLabel.setAttribute('scale', '0.4 0.4 0.4');
    userNameLabel.setAttribute('value', this.data.playerName);
    userNameLabel.setAttribute('outline-color', 'white');
    userNameLabel.setAttribute('outline-width', 0.002);
    userNameLabel.setAttribute('position', '-.205 0.05 0.1');
    labelPlane.appendChild(userNameLabel);
    // Company - Role
    var userCompanyRole = document.createElement('a-troika-text');
    userCompanyRole.setAttribute('text', {
      color: 'white',
      width: 1.5,
      value: `${this.data.playerCompany} - ${this.data.playerRole}`
    });
    userCompanyRole.setAttribute('position', '.3 -0.04 0');
    labelPlane.appendChild(userCompanyRole);

    // Progress Planes - Experience, Network, Learn
    // Experience
    var progressPlaneExperience = document.createElement('a-entity');
    progressPlaneExperience.setAttribute('geometry', {
      primitive: 'plane',
      width: 0.175,
      height: 0.025
    });
    progressPlaneExperience.setAttribute('material', {
      color: '#FDFC0D',
      opacity: 1,
      transparent: false
    });
    progressPlaneExperience.setAttribute('position', '-0.41 -0.115 0.01');
    labelPlane.appendChild(progressPlaneExperience);
    // Network
    var progressPlaneNetwork = document.createElement('a-entity');
    progressPlaneNetwork.setAttribute('geometry', {
      primitive: 'plane',
      width: 0.175,
      height: 0.025
    });
    progressPlaneNetwork.setAttribute('material', {
      color: '#3FA9F5',
      opacity: 1,
      transparent: false
    });
    progressPlaneNetwork.setAttribute('position', '-0.235 -0.115 0.01');
    labelPlane.appendChild(progressPlaneNetwork);
    // Learn
    var progressPlaneLearn = document.createElement('a-entity');
    progressPlaneLearn.setAttribute('geometry', {
      primitive: 'plane',
      width: 0.175,
      height: 0.025
    });
    progressPlaneLearn.setAttribute('material', {
      color: '#ED1E79',
      opacity: 1,
      transparent: false
    });
    progressPlaneLearn.setAttribute('position', '-0.06 -0.115 0.01');
    labelPlane.appendChild(progressPlaneLearn);

    // Mic icon and like counter
    // Mic icon
    var micIcon = document.createElement('a-image');
    micIcon.setAttribute('src', this.data.micImage);
    micIcon.setAttribute('width', 0.1,);
    micIcon.setAttribute('height', 0.1);
    micIcon.setAttribute('position', '0.425 -0.055 0.01');
    labelPlane.appendChild(micIcon);
    // Like icon
    var likeIcon = document.createElement('a-image');
    likeIcon.setAttribute('src', this.data.likeImage);
    likeIcon.setAttribute('width', 0.1,);
    likeIcon.setAttribute('height', 0.1);
    likeIcon.setAttribute('position', '0.325 0.055 0.01');
    labelPlane.appendChild(likeIcon);
    // Like counter
    var likeCounter = document.createElement('a-troika-text');
    likeCounter.setAttribute('text', {
      color: 'white',
      width: 1.5,
      value: 22
    });
    likeCounter.setAttribute('position', '0.805 0 0.01');
    likeIcon.appendChild(likeCounter);

    // Append the created custom nametag banner to the nametag element
    nametagEl.appendChild(labelPlane);

    // TODO - Logic for opening the player's profile will go here
    labelPlane.setAttribute('raycaster-interaction-hotspot', { meshLabelText: 'Profile' });
  }
};

export default { schema, init, update: init };
