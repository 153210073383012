import AFRAME from 'aframe';

/**
 * Modified from https://github.com/SeregPie/aframe-grid-helper.
 * Usage: <a-entity id="grid" grid="size: 60; divisions: 3; colorGrid: #00FF00; colorCenterLine: #FF0000" position="0 1 0" />
 * 5x5 Cell Scaling: size = divisions * 20
 */

const componentName = 'grid';

const schema = {
  colorCenterLine: { type: 'color', default: '#444' },
  colorGrid: { type: 'color', default: '#888' },
  divisions: { type: 'number', default: 10 },
  fog: { type: 'boolean', default: true },
  opacity: { type: 'number', default: 1 },
  size: { type: 'number', default: 10 },
  transparent: { type: 'boolean', default: false },
};

const init = function () {
  let {
    data,
    el,
  } = this;
  let {
    colorCenterLine,
    colorGrid,
    divisions,
    size,
  } = data;
  let object = new AFRAME.THREE.GridHelper(
    size,
    divisions,
    colorCenterLine,
    colorGrid,
  );
  el.setObject3D(componentName, object);
};

const update = function (oldData) {
  let {
    data,
    el,
  } = this;
  let {
    fog,
    opacity,
    transparent,
  } = data;
  if (Object.keys(oldData).length) {
    let pick = (({
      colorCenterLine,
      colorGrid,
      divisions,
      size,
    }) => ({
      colorCenterLine,
      colorGrid,
      divisions,
      size,
    }));
    if (!AFRAME.utils.deepEqual(pick(data), pick(oldData))) {
      remove.call(this);
      init.call(this);
    }
  }
  let object = el.getObject3D(componentName);
  if (object) {
    let { material } = object;
    Object.assign(material, {
      fog,
      opacity,
      transparent,
    });
  }
};

const remove = function () {
  let { el } = this;
  let object = el.getObject3D(componentName);
  if (object) {
    let {
      geometry,
      material,
    } = object;
    geometry.dispose();
    material.dispose();
    el.removeObject3D(componentName);
  }
};


export default { schema, init, update, remove };
