export default [
  {
    formFields: [
      {
        title: 'Enter exhibit name',
        type: 'text',
        property: 'title',
        value: '',
        rules: [(v) => !!v || 'Enter a booth name', (v) => v.length <= 40 || ' Max size 40 characters'],
        required: true,
        counter: 40,
      },
      {
        title: 'Enter exhibit description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 1000,
        rules: [(v) => !!v || 'Enter a booth description', (v) => v.length <= 1000 || 'Max size ≈ characters']
      },
    ]
  },
  {
    headerText: 'Opening hours',
    subheaderText: 'When will the exhibit team staff the exhibit',
    formFields: [
      {
        title: 'Opening Hours',
        type: 'textarea',
        property: 'openingHours',
        value: '',
        counter: 400,
        rules: [(v) => !!v || 'Opening hours is required', (v) => v.length <= 400 || `Opening hours must be ${400} characters or less`]
      },
    ]
  },
  {
    headerText: 'Social media links',
    subheaderText: 'Your social media links will be visible in the event. Leave fields blank if you do not want to include links.',
    formFields: [
      {
        title: 'Twitter',
        type: 'externalLinkData',
        property: 'twitter',
        value: '',
        rules: [ value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(value)) || `Must be a valid Twitter url` ]
      },
      {
        title: 'Youtube',
        type: 'externalLinkData',
        property: 'youtube',
        value: '',
        rules: [ value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.youtube\.com|youtu\.be)\/.+$/.test(value)) || `Must be a valid Youtube url`]
      },
      {
        title: 'Linkedin',
        type: 'externalLinkData',
        property: 'linkedin',
        value: '',
        rules: [ value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www\.linkedin\.com|linkedin\.be)\/.+$/.test(value)) || `Must be a valid Linkedin url` ]
      },
      {
        title: 'Facebook',
        type: 'externalLinkData',
        property: 'facebook',
        value: '',
        rules: [ value => (value.length === 0 || /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/.test(value)) || `Must be a valid Facebook url` ]
      },
    ]
  }
];