import { GET_LOCATIONS, SET_LOCATIONS, DELETE_LOCATION, SET_LOCATION_TYPES, GET_LOCATION_TYPES, GET_LOCATION_LAYOUTS, SET_LOCATION_LAYOUTS,
  CREATE_LOCATION_LAYOUT, GET_LOCATION_LAYOUT_BY_ID, SET_LOCATION_LAYOUT, DELETE_LOCATION_LAYOUT, UPDATE_LOCATION_LAYOUT, GET_LOCATION_BY_ID, GET_DEFAULT_LOCATION_LAYOUT, GET_LOCATION_TYPE_DATA, CREATE_LOCATION_LAYOUT_FROM_LOCATION_ID, GET_LOCATION_MESH_BY_KIND } from './types.js';
import { DELETE_FLOORMAP_LAYOUT } from '@/store/floormaps/types';
import { getInternalAssetUrl } from '@/utils/files.js';
import Vue from 'vue';

export const locationData = {
  floormap: {
    name: 'exhibition',
    title: 'Exhibition',
    description: 'Exhibitions can house vendors exhibits. Various sizes are available and multiple exhibitions can be added.',  
    icon: 'mdi-floor-plan',
    route: 'event-admin-floormap-item',
    src: getInternalAssetUrl(`/img/locations/floormap.jpg`)
  },
  lobby: {
    name: 'lobby',
    title: 'Lobby',
    description: 'The lobby is the starting point for attendees. All locations can be accessed from this area.',
    icon: 'mdi-sofa-outline',
    route: 'event-admin-lobby-item',
    src: getInternalAssetUrl(`/img/locations/lobby.jpg`)
  },
  auditorium: {
    name: 'auditorium',
    description: 'Auditioriums are used to house speaking sessions.', 
    title: 'Auditorium',
    icon: 'mdi-theater',
    route: 'event-admin-auditorium-item',
    src: getInternalAssetUrl(`/img/locations/auditorium.jpg`)
  },
  lounge: {
    name: 'lounge',
    title: 'Networking Area',
    description: 'For open networking. Includes conversation pieces and interesting architecture.',
    icon: 'mdi-coffee-outline',
    route: 'event-admin-lounge-item',
    src: getInternalAssetUrl(`/img/locations/lounge.jpg`)
  },
  office: {
    name: 'office',
    title: 'Meeting Room',
    description: 'A meeting room for impromptu or scheduled gatherings and conferences',
    icon: 'mdi-seat-outline',
    route: 'event-admin-personal_room-item',
    src: getInternalAssetUrl(`/img/locations/personal_room.jpg`)
  },
  atrium: {
    name: 'atrium',
    title: 'Atrium',
    description: 'An Atrium',
    icon: 'mdi-door-sliding',
    route: 'event-admin-personal_room-item',
    src: getInternalAssetUrl(`/img/locations/`)
  },
  recording: {
    name: 'recording',
    title: 'Recording Room',
    description: 'A Recording Room',
    icon: 'mdi-movie-open-outline',
    route: 'event-admin-personal_room-item',
    src: getInternalAssetUrl(`/img/locations/`)
  }
};

function addMeshTypeArrays(item, meshArrayKey = 'meshes') {
  let keys = [];
  item[meshArrayKey].forEach((item) => {
    if (item.type && !keys.includes(item.type)){
      keys.push(item.type);
    } 
  });
  let objectOfMeshArrays = {};
  keys.forEach((key) => {
    const gatherObjectsOfSameType = item[meshArrayKey].filter( mesh => mesh.type === key);
    objectOfMeshArrays[key] = gatherObjectsOfSameType;
  });
  if (keys.includes('logo') || keys.includes('poster')){
    let gatherTextures = item[meshArrayKey].filter( mesh => mesh.type === 'logo' || mesh.type === 'poster' );
    objectOfMeshArrays.textures = gatherTextures;
  }
  if (keys.includes('primary') || keys.includes('secondary')){
    const gatherBrandColours = item[meshArrayKey].filter( mesh => mesh.type === 'primary' || mesh.type === 'secondary' );
    objectOfMeshArrays.brandColours = gatherBrandColours;
  }
  return {
    ...item,
    meshesByType: {...objectOfMeshArrays}
  };
}

export const getPlaceholderPath = (type) => {
  if (type === 'poster'){
    return '/img/floor-config-placeholders/Smart_content_portrait.jpg';
  }
  if (type === 'logo'){
    return '/img/floor-config-placeholders/logo.png';
  }
  return '';
};

const formatLocationLayout = ((item) => {
  if (item.location) {
    const locationMeshesByType = [addMeshTypeArrays( {...item.location[0] })];
    item = {...item, location: locationMeshesByType };
  }
  if (item.savedMeshes) {
    item = addMeshTypeArrays( item, 'savedMeshes' );
  }
  return {
    ...item,
    configuredSmartContent: item.savedMeshes && item.location ? `${item.savedMeshes.length}/${item.location[0].meshes.length}` : `N/A`,
    icon: item.location && locationData[item.location[0].type] ? locationData[item.location[0].type].icon : '',
    route: item.location && locationData[item.location[0].type] ? locationData[item.location[0].type].route : '',
    placeholderPath: item.location ? getPlaceholderPath(item.location[0].type) : ''
  };
});

const formatLocation = ((item) => {
  return addMeshTypeArrays(item);
});

export default {
  state: () => ({
    locations: [],
    locationData: locationData,
    locationLayouts: [],
    locationLayout: [],
    types: null
  }),
  getters: {
    [GET_LOCATION_TYPE_DATA]: (state) => (typeName, property) => {
      if (!state.locationData[typeName] || !state.locationData[typeName][property]) { return null; }
      return state.locationData[typeName][property];
    },
    [GET_LOCATION_MESH_BY_KIND]: (state) => (location, meshKind) => {
      return location.meshes.filter((mesh) => mesh.kind === meshKind);
    }
  },
  smartContentListByType: (state) => (typeName) => {
    return state.smartContentList.filter((sc) => { return typeName.indexOf(sc.type) > -1; });
  },
  mutations: {
    [SET_LOCATIONS](state, value) {
      state.locations = value;
    },
    [SET_LOCATION_LAYOUTS](state, value) {
      state.locationLayouts = value;
    },
    [SET_LOCATION_LAYOUT](state, value) {
      state.locationLayout = value;
    },
    [SET_LOCATION_TYPES](state, value) {
      state.types = value;
    },
  },
  actions: {
    async [GET_LOCATIONS]({ commit }, parameters) {
      try {
        const url = parameters && parameters.type ? `/api/locations?type=${parameters.type}` : `/api/locations`;
        const response = await Vue.axios.get(url);
        if (!response || !response.data) { return []; }
        const formattedData = response.data.map((location) => formatLocation(location));
        commit(SET_LOCATIONS, formattedData);
        return formattedData;
      } catch (e) {
        return [];
      }
    },
    async [GET_LOCATION_BY_ID](_context, { id }) {
      try {
        const response = await Vue.axios.get(`/api/locations/${id}`);
        if (!response || !response.data) { return null; }
        return formatLocation(response.data);
      } catch (e) {
        return null;
      }
    },
    [GET_LOCATION_TYPES]({ commit }) {
      return Vue.axios.get(`/api/locations/types`).then((data) => {
        commit(SET_LOCATION_TYPES, data.data);
        return data.data;
      });
    }, 
    [DELETE_LOCATION]({ dispatch }, { eventSlug, id, type } ) {
      if (type === locationData.floormap){
        return dispatch(DELETE_FLOORMAP_LAYOUT, { eventSlug, id });
      }
    },
    async [GET_DEFAULT_LOCATION_LAYOUT](_context, { eventSlug, metadata }) {
      try {
        const response = await Vue.axios.get(`/api/${eventSlug}/location_layouts?include=grid&isDefault=true`);
        if (!response || !response.data) { return null; }
        if (response.data.length === 0) { return null; }
        return formatLocationLayout(response.data[0]);
      } catch (e) {
        return null;
      }
    },
    [GET_LOCATION_LAYOUTS]({ commit, state }, { eventSlug, metadata } ) {
      return Vue.axios.get(!metadata ? `/api/${eventSlug}/location_layouts` : `/api/${eventSlug}/location_layouts?include=${metadata}`).then((data) => {
        if (data && data.data) { 
          const formatedLocationLayouts = data.data.map( locationLayout => formatLocationLayout(locationLayout) );
          commit(SET_LOCATION_LAYOUTS, formatedLocationLayouts);
          return formatedLocationLayouts;
        } else {
          commit(SET_LOCATION_LAYOUTS, null);
          return null;
        }
      });
    }, 
    [GET_LOCATION_LAYOUT_BY_ID]({ commit }, { eventSlug, id, metadata } ) {
      return Vue.axios.get(!metadata ? `/api/${eventSlug}/location_layouts/${id}` : `/api/${eventSlug}/location_layouts/${id}?include=${metadata}`).then((data) => {
        if (data && data.data) {
          const formatedLocationLayout = formatLocationLayout(data.data);
          commit(SET_LOCATION_LAYOUT, formatedLocationLayout);
          return formatedLocationLayout;
        } else {
          commit(SET_LOCATION_LAYOUT, null);
          return null;
        }
      });
    }, 
    [CREATE_LOCATION_LAYOUT]({ commit, state }, { eventSlug, data } ) {
      return Vue.axios.post(`/api/${eventSlug}/location_layouts`, data).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },
    async [CREATE_LOCATION_LAYOUT_FROM_LOCATION_ID](_context, { eventSlug, locationId }) {
      try {
        const response = await Vue.axios.post(`/api/${eventSlug}/location_layouts/${locationId}`);
        if (!response || !response.data) { return null; }
        return formatLocationLayout(response.data);
      } catch (e) {
        return null;
      }
    },
    [UPDATE_LOCATION_LAYOUT]({ commit, state }, { eventSlug, data, id } ) {
      return Vue.axios.put(`/api/${eventSlug}/location_layouts/${id}`, data).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },
    [DELETE_LOCATION_LAYOUT]({ commit, state }, { eventSlug, id } ) {
      return Vue.axios.delete(`/api/${eventSlug}/location_layouts/${id}`).then((data) => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
    },
  },
};