import Vue from 'vue';
import router from '../../router';
import {
  GET_USER,
  SET_USER,
  SIGN_OUT,
  SET_ACHIEVEMENT_PROGRESS_VALUES,
  PATCH_USER,
  MERGE_USER_PATCH,
  TRIGGER_WEBEX_PREDICT_MESSAGE,
  LOGIN_AS_GUEST,
  GET_RPM_AVATAR_GENDER
} from './types.js';
import { getInternalAssetUrl } from '@/utils/files';

export default {
  state: () => ({
    userData: null,
    activeUserProfile: null,
    allUserAchievementProgressData: null,
    achievementProgressValues: { basic: 0, nonBasic: 0 },
    isBoothOnly: false,
    whiteListedUsers: [
      {
        name: 'Patrick Mulligan',
        email: 'PMILLIGA@ford.com'
      },
      {
        name: 'Ronan Greally',
        email: 'ronan.greally@davra.io'
      }
    ]
  }),
  mutations: {
    [SET_USER](state, value) {
      state.userData = value;
    },
    [MERGE_USER_PATCH](state, value) {
      state.userData = Object.assign(state.userData, value);
    },
    [SET_ACHIEVEMENT_PROGRESS_VALUES](state, value) {
      state.allUserAchievementProgressData = value;
    },
    setActiveUserProfile(state, value) {
      state.activeUserProfile = value;
    },
    resetActiveUserProfile(state) {
      state.activeUserProfile = null;
    }
  },
  getters: {
    userAchievementProgressByEventSlug: (state) => (eventSlug) => {
      if (!state.allUserAchievementProgressData) { return { basic: 0, nonBasic: 0 }; }
      if (!state.allUserAchievementProgressData[eventSlug]) { return { basic: 0, nonBasic: 0 }; }
      if (!state.allUserAchievementProgressData[eventSlug].achievementProgressValues) { return { basic: 0, nonBasic: 0 }; }
      return state.allUserAchievementProgressData[eventSlug].achievementProgressValues;
    },
    isAllBasicAchievementsComplete: (state) => (eventSlug) => {
      if (!state.allUserAchievementProgressData) { return false; }
      if (!state.allUserAchievementProgressData[eventSlug]) { return false; }
      return !!state.allUserAchievementProgressData[eventSlug].isAllBasicAchievementsComplete;
    },
    rpmAvatarFullUrl: (state) => {
      if (state.userData && state.userData.rpmAvatarUrl) {
        if (state.userData.rpmAvatarUrl.indexOf('https://') === -1) { return getInternalAssetUrl(state.userData.rpmAvatarUrl); }
        return state.userData.rpmAvatarUrl;
      }
      return getInternalAssetUrl('/assets/models/rpm-avatars/davra-casual-m.glb');
    }
  },
  actions: {
    async [GET_RPM_AVATAR_GENDER]({ getters }) {
      if (getters.rpmAvatarFullUrl.includes('-f.glb')) { return 'f'; }
      if (getters.rpmAvatarFullUrl.includes('-m.glb')) { return 'm'; }
      try {
        // parse the avatar id from the RPM GLB url (does not work on all avatars, investigate how this works on older avatars that had a different URL format)
        const parsedRpmAvatarId = getters.rpmAvatarFullUrl.substring(
          getters.rpmAvatarFullUrl.lastIndexOf('/') + 1, 
          getters.rpmAvatarFullUrl.lastIndexOf('.glb')
        );
        const rpmResponse = await Vue.axios.get(`https://models.readyplayer.me/${parsedRpmAvatarId}.json`);
        if (!rpmResponse.data) { return 'm'; }
        if (rpmResponse.data.outfitGender === 'feminine') { return 'f'; }
        return 'm';
      } catch (e) {
        return 'm';
      }
    },
    [GET_USER]({ commit }) {
      return Vue.axios.get(`/api/auth/sessioninfo`).then((response) => {
        if (!response.data || !response.data.persona) { return null; }
        commit(SET_USER, response.data.persona);
        if (!response.data.userAchievements) {
          commit(SET_ACHIEVEMENT_PROGRESS_VALUES, null);
        } else {
          commit(SET_ACHIEVEMENT_PROGRESS_VALUES, response.data.userAchievements);
        }
        return response.data.persona;
      });
    },
    [PATCH_USER]({ commit }, { payload }) {
      return Vue.axios.patch(`/api/users`, payload).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          commit(MERGE_USER_PATCH, response.data);
          return response.data;
        }
      }).catch((_err) => {
        return null;
      });
    },
    [LOGIN_AS_GUEST](_, { emailAddress, displayName, redirect, origin }) {
      const payload = { displayName, redirect, emailAddress, destination: { emailAddress, origin } };
      return Vue.axios.post(`/api/auth/magiclogin`, payload).then((response) => {
        if (!response || !response.data) {
          throw new Error(response);
        } else {
          return response.data;
        }
      });
    },
    [SIGN_OUT]({ state, commit }) {
      return Vue.axios.get(`/api/auth/logout`).then(() => {
        commit(SET_USER, null);
        router.push({ name: 'site-login' });
      });
    },
    [TRIGGER_WEBEX_PREDICT_MESSAGE]({ state }) {
      if (!window.location.origin.includes('fordcenter.ford')) { return; }
      const userEmail = state.userData.emailAddress.toLowerCase().trim();
      const userName = state.userData.displayName;
      const csamAtendeesRoom = 'Y2lzY29zcGFyazovL3VzL1JPT00vYjRjMzY0NjAtNTA2MC0xMWVkLTg0YjMtYzE5MzA4N2RjN2Rj';
      const csam22DavraInternalRoom = 'Y2lzY29zcGFyazovL3VzL1JPT00vNzEzMGZhYzAtMmRkNi0xMWVkLTg1YzQtNDExM2JlNTExNDNm';
      const webexPredictUrl = 'https://gps4teamsbot-fordlab.apps.davra.com/showroom-notification';
      
      if (!userEmail || !userEmail) { return; }
      Vue.axios.post(webexPredictUrl, {
        message: `${userName} (${userEmail}), has joined the conference floor at ${new Date().toUTCString()}`,
        roomId: csamAtendeesRoom
      });

      const whitelistedUser = state.whiteListedUsers.find((user)  => { return user.email.toLowerCase().trim() === userEmail; });
      if (!whitelistedUser) { return; }
      Vue.axios.post(webexPredictUrl, {
        message: `${whitelistedUser.name} (${userEmail}), has joined the conference floor at ${new Date().toUTCString()}`,
        roomId: csam22DavraInternalRoom
      });

    },
    async getActiveUserProfile({ state, commit }, { userId }) {
      try {
        if (!userId) { return;}
        const response = await Vue.axios.get(`/api/users/${userId}`);
        if (!response || !response.data) { return null; }
        commit('setActiveUserProfile', response.data);
        return response.data;
      } catch (e) {
        return null;
      }
    },
  },
};
