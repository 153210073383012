export const ADD_TO_CHAT_STREAM = 'ADD_TO_CHAT_STREAM';
export const ADD_TO_PARTICIPANT_LIST = 'ADD_TO_PARTICIPANT_LIST';
export const REMOVE_FROM_PARTICIPANT_LIST = 'REMOVE_FROM_PARTICIPANT_LIST';
export const RESET_PARTICIPANT_LIST = 'RESET_PARTICIPANT_LIST';
export const SET_SEEN_CHAT_MESSAGE_COUNT = 'SET_SEEN_CHAT_MESSAGE_COUNT';
export const RESET_CHAT_ALL_INPUT_VALUE = 'RESET_CHAT_ALL_INPUT_VALUE';
export const GET_UNREAD_CHAT_MESSAGE_COUNT = 'GET_UNREAD_CHAT_MESSAGE_COUNT';
export const GET_PARTCIPANT_BY_ID = 'GET_PARTCIPANT_BY_ID';
export const INIT_NETWORKING = 'INIT_NETWORKING';
export const INIT_CHAT_ALL = 'INIT_CHAT_ALL';
export const SEND_CHAT_ALL = 'SEND_CHAT_ALL';
export const INIT_PARTICIPANT_LIST = 'INIT_PARTICIPANT_LIST';
export const CLEAR_RECONNECT_HANDLER_INTERVAL = 'CLEAR_RECONNECT_HANDLER_INTERVAL';
export const RESET_RECONNECT_ATTEMPT_COUNT = 'RESET_RECONNECT_ATTEMPT_COUNT';
export const INCREMENT_RECONNECT_ATTEMPT_COUNT = 'INCREMENT_RECONNECT_ATTEMPT_COUNT';
export const IS_RECONNECT_MAXED = 'IS_RECONNECT_MAXED';
export const SET_NETWORK_CONNECTION_STATUS = 'SET_NETWORK_CONNECTION_STATUS';
export const TRIGGER_NETWORKING_EVENT = 'TRIGGER_NETWORKING_EVENT';
export const ADD_NETWORKING_EVENT_LISTENER = 'ADD_NETWORKING_EVENT_LISTENER';
