import Vue from 'vue';
import { GET_PAGES, GET_PAGE, SET_PAGES, CREATE_PAGE, DELETE_PAGE, EDIT_PAGE } from './types.js';

function formatPage(data) {
  return {
    ...data,
    isOnHomepage: data.location.isOnHomepage,
    isInFooter: data.location.isInFooter,
    isInSidemenu: data.location.isInSidemenu,
  };
}

export default {
  state: () => ({
    pages: []
  }),
  mutations: {
    [SET_PAGES](state, value) {
      state.pages = value;
    },
  },
  actions: {
    [GET_PAGES]({ commit, dispatch }, { eventSlug }) {
      return Vue.axios
        .get(`/api/${eventSlug}/pages`).then((data) => { 
          let pages = data && data.data;
          pages = pages.map((page) => formatPage(page));
          commit(SET_PAGES, pages);
          return pages;
        });
    },
    [GET_PAGE]({ commit, dispatch }, { eventSlug, id }) {
      return Vue.axios
        .get(`/api/${eventSlug}/pages/${id}`).then((data) => { 
          let page = data && formatPage(data.data);
          return page;
        });
    },
    [CREATE_PAGE]({ commit, dispatch }, { eventSlug, data }) {
      return Vue.axios
        .post(`/api/${eventSlug}/pages`, data).then((response) => { 
          let page = response && response.data;
          return page;
        });
    },
    [EDIT_PAGE]({ commit, dispatch }, { data, id, eventSlug }) {
      return Vue.axios.put(`/api/${eventSlug}/pages/${id}`, data).then((response) => {
        if (!response || !response.data) {
          return null;
        } else {
          return response.data;
        }
      });
    },
    [DELETE_PAGE]({ commit, dispatch }, { eventSlug, id }) {
      return Vue.axios
        .delete(`/api/${eventSlug}/pages/${id}`).then((data) => { 
          let page = data && data.data;
          return page;
        });
    },
  },
};