export default [
  {
    formFields: [
      {
        title: 'Poster File',
        type: 'uploadcare',
        property: 'file_id',
        value: '',
        options: {
          ucClasses: 'sr-uc-restrict-image'
        }
      },
      {
        title: 'Poster name',
        type: 'text',
        property: 'title',
        value: '',
        counter: 150,
        rules: [(v) => !!v || 'Poster name is required', (v) => v.length <= 150 || 'Poster name must be 150 characters or less'],
        required: true
      },
      {
        title: 'Poster description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      }
    ]
  }
];
