import Vue from 'vue';
import { GET_SWAGBAG, SET_SWAGBAG, REMOVE_SWAGBAG_ITEM, REMOVE_FROM_SWAGBAG, ADD_SWAGBAG_ITEM } from './types.js';
import { getStaticAssetUrl } from '@/utils/files';
const backwardsCompatibilityMap = (swagbagItem) => {
  const newSwagbagItem = {
    ...swagbagItem,
    description: swagbagItem.boothContent.description,
    fileTitle: swagbagItem.boothContent.content.title,
    fileType: swagbagItem.boothContent.type,
    name: swagbagItem.boothContent.content.title,
    path: swagbagItem.filePath,
    url: getStaticAssetUrl(swagbagItem.boothContent.content.fileId),
    booth: swagbagItem.booth.title
  };
  return newSwagbagItem;
};

export default {
  state: () => ({
    swagbag: [],
  }),
  mutations: {
    [SET_SWAGBAG](state, value) {
      state.swagbag = value;
    },
    [REMOVE_FROM_SWAGBAG](state, itemId) {
      state.swagbag = state.swagbag.filter((s) => s.id !== itemId);
    }
  },
  actions: {
    [GET_SWAGBAG]({ commit }, { eventSlug, includeMetadata = true }) {
      return Vue.axios.get(`/api/${eventSlug}/swagbag${includeMetadata ? '?include=metadata' : ''}`).then((response) => {
        if (!response || !response.data || !Array.isArray(response.data)) {
          commit(SET_SWAGBAG, []);
          return [];
        } else {
          commit(SET_SWAGBAG, response.data.map(backwardsCompatibilityMap));
          return response.data;
        }
      });
    },
    [ADD_SWAGBAG_ITEM]({ commit }, { data, eventSlug }) {
      return Vue.axios.post(`/api/${eventSlug}/swagbag`, data).then((response) => {
        if (response.status === 200) {
          return response.status;
        }
        return 400;
        // if (swagbag) {
        //     commit(SET_SWAGBAG, swagbag);
        // } else {
        //   commit(SET_SWAGBAG, []);
        //   return null;
        // }
      });
    },
    [REMOVE_SWAGBAG_ITEM]({ commit }, { id, eventSlug }) {
      return Vue.axios.delete(`/api/${eventSlug}/swagbag/${id}`).then((data) => {
        commit(REMOVE_FROM_SWAGBAG, id);
      });
    },

  },
};
