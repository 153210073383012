const schema = {
  smartContent: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    }
  },
  meta: {
    parse: function (value) {
      return value;
    },
    stringify: function (value) {
      return JSON.stringify(value);
    },
    required: true
  },
  isTargetMeshExpected: { type: 'boolean', default: true }
};

const init = function () {
  const smartContent = this.data.smartContent;
  
  if (this.data.isTargetMeshExpected) {
    // the smart content will be applied to an existing mesh in the scene
    this.el.addEventListener('gltf-part-loaded', async (e) => {
      if (!e.detail || !e.detail.mesh) { return console.warn('A-FRAME:smart-content', 'Failed to load mesh'); }
      this.mesh = e.detail.mesh;
      this.el.setAttribute(`sr-smart-content-${smartContent.type}`, { smartContent, target: this.mesh, meta: this.data.meta });
    }, { once: true });
  } else {
    // TODO: the smart content will be applied to a newly created plane in the scene
    // this.el.setAttribute(`sr-smart-content-${smartContent.type}`, { smartContent });
  }
};

const update = function () {
  if (!this.mesh) { return; }
  const smartContent = this.data.smartContent;
  if (!smartContent) {
    this.el.getAttributeNames().forEach((attr) => {
      if (attr.indexOf('sr-smart-content-') === 0) {
        this.el.removeAttribute(attr);
      }
    });
  } else {
    this.el.setAttribute(`sr-smart-content-${smartContent.type}`, { smartContent, target: this.mesh, meta: this.data.meta });
  }
};

export default { schema, init, update };
