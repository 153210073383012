import AFRAME from 'aframe';

export const schema = {
  target: { type: 'selector' }
};

export const tick = function() {
  const tmpV = new AFRAME.THREE.Vector3();
  if (!this.data.target) { return; }
  const target = this.data.target.object3D;
  target.getWorldPosition(tmpV);
  this.el.object3D.position.lerp(tmpV, 0.5);
};

export default { schema, tick };