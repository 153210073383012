import AFRAME from 'aframe';
import { RGBELoader } from '../threejs/RGBELoader';

const schema = {
  path: { type: 'string' },
  isSetBackground: { type: 'boolean', default: false },
};

const init = function () {
  new RGBELoader().load(this.data.path, (texture) => {
    texture.mapping = AFRAME.THREE.EquirectangularReflectionMapping;
    if (this.data.isSetBackground) {
      this.el.sceneEl.object3D.background = texture;
    }
    this.el.sceneEl.object3D.environment = texture;
  });
};

export default { schema, init };
