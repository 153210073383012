export default [
  {
    formFields: [
      {
        title: 'Name',
        type: 'text',
        property: 'title',
        value: '',
        counter: 150,
        rules: [(v) => !!v || 'Name is required', (v) => v.length <= 150 || 'Name must be 150 characters or less'],
        required: true
      },
      {
        title: 'Description',
        type: 'textarea',
        property: 'description',
        value: '',
        counter: 400,
        rules: [(v) => v.length <= 400 || 'Description must be 400 characters or less']
      }
    ]
  }
];
