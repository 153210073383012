const schema = {
  meshDictList: { type: 'array', default: [] }
};

const init = function () {
  const meshDictList = this.data.meshDictList;
  if (!meshDictList || meshDictList.length === 0) { return; }
  this.el.addEventListener('model-loaded', () => {
    // this timeout solves the problem of booth not always loading branding colours, temporary solution
    setTimeout(() => paintFromMeshDictList(meshDictList, this.el), 2000);
  }, { once: true });
};

const update = function (oldData) {
  if (!oldData || Object.keys(oldData).length === 0) { return; }
  const meshDictList = this.data.meshDictList;
  if (!meshDictList || meshDictList.length === 0) { return; }
  paintFromMeshDictList(meshDictList, this.el);
};

const paintFromMeshDictList = function (meshDictList, el) {
  meshDictList.forEach((meshDict) => {
    if (!meshDict.name || (!meshDict.color && !meshDict.value)) { return console.warn('A-FRAME:paint-meshes', 'meshDict malformed, skipping', meshDict); }
    const mesh = el.object3D.getObjectByName(meshDict.name);
    const colorValue = meshDict.color ? meshDict.color.replaceAll('#', '') : meshDict.value.replaceAll('#', '');
    if (!mesh) { return console.warn('A-FRAME:paint-meshes', 'Mesh not found', meshDict.name); }
    mesh.material.color.setHex(Number(`0x${colorValue}`)).convertSRGBToLinear();
  });
};

export default { schema, init, update };
